import React, { PureComponent } from "react";
import cx from "classnames";
import ResultsWithScore from "../components/ResultsWithScore";

export default class QuizResults extends PureComponent {
  render() {
    const { isLoading, question, userId, strings } = this.props;
    const { scores, showWinnerOnly } = question;
    const userInfo = scores[userId];
    return (
      <div
        className={cx("quiz__answers", { showWinnerOnly })}
        style={{ height: "100vh", display: "flex", justifyContent: "center", alignItems: "center" }}
      >
        {userInfo && (
          <ResultsWithScore
            question={question}
            isLoading={isLoading}
            userId={userId}
            strings={strings}
            showWinnerOnly={showWinnerOnly}
          />
        )}
      </div>
    );
  }
}
