import React, { PureComponent } from "react";

export default class ExtraQuestion extends PureComponent {
  render() {
    if (!this.props.question.extraQuestion) return null;
    const { onChange, state, answers: questionAnswers } = this.props;
    const { question, answers, type } = this.props.question.extraQuestion;
    const { others = {} } = questionAnswers;
    return (
      <div className="extra__question--container">
        <h4 className="extra__question--question">{question}</h4>
        {type === "radio" && (
          <div className="extra__question--answers">
            {answers.map((a, index) => {
              const { key, text, type = "radio" } = a;
              const isChecked = key === others;
              return (
                <label key={index} className="extra__question--label">
                  <p>{text}</p>
                  <input
                    disabled={state !== "ANSWERING"}
                    name="extra-radio"
                    type={type}
                    value={key}
                    checked={isChecked}
                    className={`extra__question--input__${type}`}
                    onChange={() => onChange(type, key)}
                  />
                </label>
              );
            })}
          </div>
        )}
      </div>
    );
  }
}
