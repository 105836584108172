/* eslint-disable operator-linebreak */
import startsWith from "lodash/startsWith";
import encode from "querystring/encode";

const cloudImageUrl = "https://img.appcraft.events";

const OBJECT_STORAGE_STATIC =
  "https://storage.gra.cloud.ovh.net/v1/AUTH_1aa44ccb48c74c7f956a0ce09a3527ea/static001/events";

const aliases = {
  "https://app.appcraft.events/images": "_appcraft_images_",
  "https://app.sandbox.appcraft.events/images": "_sandbox_images_",
  [OBJECT_STORAGE_STATIC]: "evt",
  "https://storage.gra.cloud.ovh.net/v1/AUTH_1aa44ccb48c74c7f956a0ce09a3527ea/static1": "_storage_static1_",
  "https://storage.gra.cloud.ovh.net/v1/AUTH_1aa44ccb48c74c7f956a0ce09a3527ea/static001": "_storage_static001_",
  "https://pes2021-cn.events.3ds.com/static001": "_storage_static001_",
  "https://storage.gra.cloud.ovh.net/v1/AUTH_1aa44ccb48c74c7f956a0ce09a3527ea/sandbox001": "_storage_sandbox001_",
  "https://img.appcraft.events/cdn/n/n/": "",
  "https://avfuefcxen.cloudimg.io/cdn/n/n/": "",
};

const aliasUrls = Object.keys(aliases);

function shorthand(url) {
  const aliasUrl = aliasUrls.find((prefix) => startsWith(url, prefix));

  if (startsWith(url, "https://avfuefcxen.cloudimg.io/v7/")) {
    // Already shorthanded, extract relevant part
    return url.replace(/^https:\/\/avfuefcxen\.cloudimg\.io\/v7\/([^?]+)\?.*/, (_all, group1) => group1);
  }

  if (startsWith(url, "https://img.appcraft.events/v7/")) {
    // Already shorthanded, extract relevant part
    return url.replace(/^https:\/\/img\.appcraft\.events\/v7\/([^?]+)\?.*/, (_all, group1) => group1);
  }

  return url.replace(aliasUrl, aliases[aliasUrl]);
}

export function getUrl(uri) {
  return uri && (typeof uri === "string" ? uri : uri.uri);
}

export function cloudimg(uri, options) {
  const url = getUrl(uri);
  if (!url) return url;
  return `${cloudImageUrl}/v7/${shorthand(url)}?${encode(options)}`;
}

function cdn(uri) {
  const url = getUrl(uri);
  if (!url) return url;

  // Already on the cdn ?
  if (startsWith(url, "https://avfuefcxen.cloudimg.io") || startsWith(url, cloudImageUrl)) {
    return url;
  }

  return `${cloudImageUrl}/cdn/n/n/${shorthand(url)}`;
}

export function extractFormat(type) {
  if (type === "auto") return "";
  return type || "original";
}

function aspectRatio(image) {
  if (!image || typeof image !== "object") return undefined;

  const { width, height } = image;
  if (!width || !height) return undefined;
  return width / height;
}

export default {
  shorthand,
  cdn,
  aspectRatio,
};
