import React, { Component } from "react";
import Chart from "chart.js";

const Result = ({ label, count, percentage, color, icon, style = {} }) => {
  return (
    <div
      className="quiz__answers__text"
      style={{
        fontWeight: "bold",
        fontFamily: "Montserrat",
        fontSize: "1.75vw",
        alignItems: "center",
        color: color,
        display: "flex",
        textAlign: "left",
        ...style,
      }}
    >
      <div
        style={{
          height: 40,
          width: 40,
          backgroundColor: color,
          borderRadius: 20,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginRight: 10,
        }}
      >
        <i className={"fa fa-" + icon} style={{ color: "white" }} />
      </div>
      {label} : {count} {`(${percentage} %)`}
    </div>
  );
};

export default class Stats extends Component {
  static defaultProps = {
    type: "multiple_choice",
  };
  componentDidMount() {
    const { totalAnswers = 0, type, answers, chartType = "pie" } = this.props;
    if (totalAnswers === 0) return;
    if (type === "input" || type === "word_cloud" || "multiple_texts") return;

    const ctx = this._canvas && this._canvas.getContext("2d");
    const labels = answers.map((ans, index) => (ans.text.length > 20 ? index + 1 : ans.text));
    const percents = answers.map((ans) => ans.percent.replace("%", ""));
    const counts = answers.map((ans) => ans.count);
    const data = {
      labels: labels,
      datasets: [
        {
          label: "#",
          data: chartType === "pie" ? percents : counts,
          backgroundColor: ["#685C6B", "#CBA17E", "#ff8519", "#bcbd22", "#FEC007", "#2ca02c", "#618685"],
        },
      ],
    };

    this.chart =
      this._canvas &&
      new Chart(ctx, {
        type: chartType,
        data,
        options: {
          responsive: true,
          legend: {
            display: true,
            fullWidth: true,
            labels: {
              fontSize: 18,
              fontFamily: "Montserrat",
            },
          },
        },
      });
  }

  render() {
    const {
      i18n,
      correct = 0,
      wrong = 0,
      totalAnswers = 0,
      answers = [],
      type,
      isInline = true,
      resultStyle = {},
      leaderboard = [],
      showChart = true,
      showFastest = false,
      question = {},
    } = this.props;
    const translationModuleActivated = i18n ? true : false;
    const correctAnswers = question.answers.filter((a) => a.checked);

    console.log("showFastest", showFastest);
    if (showFastest === "true") alert("yes");

    if (type === "input" || type === "word_cloud") {
      let data = [];
      for (let i = 0; i < answers.length; i++) {
        data.push({ text: answers[i].text, value: answers[i].count });
      }
      const showResults = totalAnswers > 0;
      return (
        <div>
          <table className="table">
            <tbody>
              {answers.map((ans, idx) => (
                <tr key={idx}>
                  <td style={{ width: 64, padding: 0, verticalAlign: "middle", fontSize: "3vw" }}>
                    <div className={"master-answer-label " + (showResults && ans.checked ? "active" : "")}>
                      {idx + 1 /*letters[idx]*/}
                    </div>
                  </td>
                  <td style={{ position: "relative", paddingBottom: 22, textAlign: "left" }}>
                    <div style={{ float: "right" }}>{ans.count}</div>
                    {ans.text}
                    <div
                      className={"answer answer-" + (idx + 1)}
                      style={{ minWidth: showResults ? (ans.count * 100) / totalAnswers + "%" : 0 }}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      );
    }

    const correctPercent = totalAnswers === 0 ? 0 : Math.round((correct * 10000) / totalAnswers) / 100;
    const wrongPercent = totalAnswers === 0 ? 0 : Math.round((wrong * 10000) / totalAnswers) / 100;
    // console.log("answers", answers)
    return (
      <div>
        <div
          style={{
            display: type === "image_quiz" ? "flex" : "block",
            alignItems: type === "image_quiz" ? "flex-start" : "",
          }}
        >
          {showChart && type !== "vote" && (
            <div style={{ display: "flex", width: "100%", flexDirection: isInline ? "row" : "column" }}>
              <Result
                style={resultStyle}
                label={translationModuleActivated ? i18n.t("good-answers") : "Bonnes réponses"} //TODO: Activer le module partout pour éviter ce genre de condition
                count={correct}
                percentage={correctPercent}
                icon="check"
                color="#2CA02C"
              />
              <Result
                style={resultStyle}
                label={translationModuleActivated ? i18n.t("wrong-answers") : "Mauvaises réponses"} //TODO: Activer le module partout pour éviter ce genre de condition
                count={wrong}
                percentage={wrongPercent}
                icon="times"
                color="#F44336"
              />
            </div>
          )}
          {totalAnswers > 0 && showChart && (
            <div style={{ width: 270, margin: "0 auto", margintop: 15 }}>
              <canvas width={200} height={200} ref={(el) => (this._canvas = el)} />
            </div>
          )}

          <div>
            {correctAnswers.map((a, index) => (
              <h3
                style={{
                  display: "flex",
                  alignItems: "center",
                  fontWeight: "bold",
                  fontSize: "4.5vh",
                  color: "#16ab39",
                }}
              >
                <div
                  style={{
                    height: 30,
                    width: 30,
                    backgroundColor: "#16ab39",
                    borderRadius: 30,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginRight: 10,
                  }}
                >
                  <i className={"fa fa-check"} style={{ fontSize: 20, color: "white" }} />
                </div>
                {a.text}
              </h3>
            ))}
          </div>

          {showFastest === "true" && (
            <div style={{ display: "flex", flexDirection: "row", marginTop: "10vh" }}>
              <div className="c-histogram" style={{ width: 800 }}>
                {answers.map((a, index) => {
                  const { count, percent } = a;
                  const answer = question.answers && question.answers[index];
                  const isCorrect = answer && !!answer.checked; //maxCount === count;
                  const green = "#16ab39";
                  const red = "#ff2b44";
                  return (
                    <div className="c-histogram__line" style={{ margin: 10, display: "flex", alignItems: "center" }}>
                      <h3 style={{ color: isCorrect ? green : "black", width: 50, marginRight: 20 }}>{index + 1}.</h3>
                      <div style={{ flex: 1 }}>
                        <div style={{ backgroundColor: isCorrect ? green : red, height: "5vh", width: percent }} />
                      </div>
                      <h3 style={{ color: isCorrect ? green : "black", width: 50, marginLeft: 8 }}>{count} </h3>
                    </div>
                  );
                })}
              </div>
              <div className="c-leaderboard">
                <h3>{translationModuleActivated ? i18n.t("the-fastest") : "Les plus rapides"}</h3>{" "}
                {/* //TODO: Activer le module partout pour éviter ce genre de condition */}
                <ol>
                  {leaderboard.slice(0, 3).map(({ userId, user = {} }) => {
                    return <li key={userId}>{user.firstName || user.Prenom || user.Prénom || userId}</li>;
                  })}
                </ol>
              </div>
            </div>
          )}

          {/* <table border="1" cellPadding="6" style={{ margin: "10px auto" }}>
            <thead>
              <tr>
                <th>#</th>
                <th>Nombre de personnes </th>
                <th>Pourcentage</th>
              </tr>
            </thead>
            <tbody>
              {answers.map((a, index) => (
                <tr>
                  <td>{index + 1}</td>
                  <td style={{ fontWeight: "bold", color: "#2CA02C" }}>{a.count}</td>
                  <td>{a.percent}</td>
                </tr>
              ))}
            </tbody>
          </table> */}
        </div>
      </div>
    );
  }
}
