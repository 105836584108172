import values from "lodash/values";
import io from "socket.io-client";

// import store from '../../shared/Store';
const endpoint = "wss://api.appcraft.events/ws";
// const endpoint = "ws://localhost:3345/ws";

const valueupEventId = "gWbneuSF7xw7GE";

class PubSubSubscription {
  hasLoaded = false;

  data = null;

  callbacks = [];

  isCollection = false;

  constructor(topic, options = {}) {
    const { isCollection = true } = options;
    this.topic = topic;
    this.isCollection = isCollection;
    this.callbacks = [];
  }

  register(callback) {
    // console.log('PubSubSubscription.register', callback);
    this.callbacks.push(callback);
    if (this.data) {
      callback(this.data); // Fire now
    }
  }

  unregister(callback) {
    // console.log('PubSubSubscription.unregister', callback);
    this.callbacks = this.callbacks.filter((cb) => cb !== callback);
  }

  isEmpty() {
    return this.callbacks.length === 0;
  }

  onMessage = ({ payload }) => {
    this.data = payload;
    this.notifyCallbacks();
  };

  notifyCallbacks() {
    // console.log('PubSubSubscription.notifyCallbacks', this.callbacks.length);
    this.callbacks.forEach((cb) => cb(this.data));
  }
}

class PubSubService {
  socket = null;

  subscriptions = {};

  ensureSocket() {
    if (!this.socket) {
      // Remove path from url, else fails...
      this.socket = io(endpoint.replace("/ws", ""), {
        path: "/ws",
        auth: {
          jwt: "",
          // jwt: store.token,
        },
        query: { eventId: window.__DATA__?.eventId || valueupEventId, mode: "quiz" },
      });
      this.socket.on("connect", this.subscribeToSubscriptions);
      this.socket.on("reconnect", this.onReconnect);
    }
    return this.socket;
  }

  onReconnect = () => {
    // console.log('onReconnect');
    values(this.subscriptions).forEach((subscription) => {
      subscription.refresh();
      this.socket.emit("subscribe", { topic: subscription.topic });
    });
  };

  subscribeToSubscriptions = () => {
    // console.log('subscribeToSubscriptions');
    values(this.subscriptions).forEach((subscription) => {
      this.socket.emit("subscribe", {
        topic: subscription.topic,
        isCollection: subscription.isCollection,
      });
    });
  };

  /**
   * Unregister a callback from a subscription
   * @param {*} topic
   * @param {*} callback
   */
  unregister(topic, callback) {
    const subscription = this.subscriptions[topic];
    if (!subscription) {
      console.warn("Missing subscription for PubSubService.unregister", topic);
      return;
    }
    subscription.unregister(callback);
    if (subscription.isEmpty()) {
      // Delay unsubscription in case other components are mounted in the meantime
      setTimeout(() => this.cancelSubscriptionIfNeeded(topic), 500);
    }
  }

  /**
   * Check if the subscription is still used. If not, remove
   * @param {*} topic
   */
  cancelSubscriptionIfNeeded(topic) {
    const subscription = this.subscriptions[topic];
    if (!subscription) {
      // Already flushed
      return;
    }
    if (subscription.isEmpty()) {
      // console.log('PubSubService.cancelSubscription', topic);
      // Time to flush
      this.socket.emit("unsubscribe", { topic });
      this.socket.off(topic);
      delete this.subscriptions[topic];

      // TODO: flush websockets if empty ?
    }
  }

  /**
   * Register to user/data collection updates
   * @param {string} topic
   * @param {function} callback
   */
  onSnapshot(topic, callback, options) {
    let subscription = this.subscriptions[topic];
    if (!subscription) {
      subscription = new PubSubSubscription(topic, options);
      this.subscriptions[topic] = subscription;

      const socket = this.ensureSocket();
      if (socket.connected) {
        socket.emit("subscribe", { topic, isCollection: options.isCollection });
      }
      socket.on(topic, subscription.onMessage);
    }

    subscription.register(callback);
    return () => {
      this.unregister(topic, callback);
    };
  }

  onDocument(topic, callback) {
    return this.onSnapshot(topic, callback, { isCollection: false });
  }

  onCollection(topic, callback) {
    return this.onSnapshot(topic, callback, { isCollection: true });
  }

  update(topic, patch, options) {
    const socket = this.ensureSocket();
    if (socket.connected) {
      socket.emit("update", { topic, patch, options });
    }
  }

  set(topic, patch, options) {
    const socket = this.ensureSocket();
    if (socket.connected) {
      socket.emit("set", { topic, patch, options });
    }
  }

  add(topic, document) {
    const socket = this.ensureSocket();
    if (socket.connected) {
      socket.emit("add", { topic, document });
    }
  }
}

export default new PubSubService();
