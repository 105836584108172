import cx from "classnames";
import fb from "firebase/app";
import keyBy from "lodash/keyBy";
import pickBy from "lodash/pickBy";
import React, { Component } from "react";
import firebase, { eventId } from "./Store";
import Font from "./components/Font/Font";
import Strings, { forcedLanguage, mergeStrings } from "./data/Strings";
import { computeOneUserResults } from "./master/utils";
import {
  ImageQuiz,
  ImageVote,
  InputQuiz,
  MultipleChoiceVoteQuiz,
  MultipleTexts,
  QuizQuestion,
  QuizResponse,
  QuizResults,
  ScaleQuestion,
  WordCloudQuiz,
} from "./mobile";
import DragAndDropQuestion from "./mobile/DragAndDropQuestion";
import ExtraQuestion from "./mobile/ExtraQuestion";
import { checkOrange, getParameterByName, uuid } from "./utils";
import { translateValue } from "./utils/translationUtils";

const AUTO_CONNECT = getParameterByName("autoConnect") === "true" || !!getParameterByName("autoPin");
const windowWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;

function slowClick(setLoading) {
  setLoading(true);
  setTimeout(() => setLoading(false), 1000);
}

class ClientStyles extends React.PureComponent {
  render() {
    const { primaryColor, customCSS = "", fonts } = this.props;

    return (
      <>
        {fonts && <Font type="body" font={fonts.body} />}

        <style
          dangerouslySetInnerHTML={{
            __html: `
/* Color styles */
.quiz__question.quiz__question--word_cloud {
  background-color: ${primaryColor || ""};
}
.quiz__answers__button {
  background-color: ${primaryColor || ""};
}
/* Custom CSS */
${customCSS || ""}
`,
          }}
        />
      </>
    );
  }
}

const headless = getParameterByName("headless");

let deviceId = 0;
try {
  if (window.localStorage) {
    deviceId = localStorage.getItem("vote-uuid");
    if (!deviceId) {
      deviceId = uuid();
      localStorage.setItem("vote-uuid", deviceId);
    }
  }
} catch (e) {
  console.warn("Cannot use localStorage");
}

// Just in case localStorage failed
if (!deviceId) {
  deviceId = uuid();
}

// Try to use current user's id ?
const userId = getParameterByName("userId") || getParameterByName("_id") || deviceId;
const firstName = getParameterByName("firstName") || getParameterByName("fn");
const lastName = getParameterByName("lastName") || getParameterByName("ln");
const email = getParameterByName("email");
const teamId = getParameterByName("teamId");
const user = { userId, firstName, lastName, email, teamId };

// const Logo = () => (
//   <div style={{ padding: 8, textAlign: "center" }}>
//     <img src="https://app.appcraft.events/data/NBJFHDU8/assets/logo.png" style={{ maxWidth: "100%", maxHeight: 32 }} />
//   </div>
// );

const Container = ({ quiz = {}, children, style }) => (
  <div className="sidebar-content-list" style={style}>
    <ClientStyles primaryColor={quiz.primaryColor} customCSS={quiz.customCSS} fonts={quiz.fonts} />
    {/* <Logo /> */}
    {children}
  </div>
);

export const TextContainer = ({ children, style, fontSize }) => (
  <div
    className="text--container"
    style={{
      margin: 10,
      display: "table",
      height: "100%",
      position: "absolute",
      ...style,
    }}
  >
    <div
      style={{
        display: "table-cell",
        verticalAlign: "middle",
        textAlign: "center",
      }}
    >
      <h3
        className="text"
        style={{
          textTransform: "uppercase",
          fontWeight: "bold",
          color: "black",
          fontSize,
        }}
      >
        {children}
      </h3>
    </div>
  </div>
);

export default class Client extends Component {
  constructor(props) {
    super(props);

    this.state = {
      state: "DISCONNECTED",
      answers: {},
      fields: { id: userId, userId: userId },
      userId,
      stats: null,
      question: null,
      quizAnswers: {},
      quiz: {},
      sessionId: "",
      isLoading: false,
      otherInfo: {},
      pin: getParameterByName("pin") || getParameterByName("autoPin") || "",
      eventId: getParameterByName("eventId") || eventId,
    };
  }
  disconnect = (e, message) => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }

    console.warn("TODO: disconnect");
    if (this.unsubscribe) {
      this.unsubscribe();
      this.unsubscribe = null;
    }
    if (!AUTO_CONNECT && this.unsubscribePin) {
      this.unsubscribePin();
      this.unsubscribePin = null;
    }
    this.setState({
      state: "DISCONNECTED",
      sessionId: "",
      answers: {},
      disconnectMessage: message,
    });
  };

  connect = (e) => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }

    // Fetch info from pin
    const { pin } = this.state;
    if (this.unsubscribePin) this.unsubscribePin();
    // save connected users number
    // this.database
    //   .collection("quiz-pin")
    //   .doc(pin)
    //   .get()
    //   .then(doc => {
    //     const { connected = 0 } = doc.data();
    //     this.database
    //       .collection("quiz-pin")
    //       .doc(pin)
    //       .set({ ...doc.data(), connected: connected + 1 });
    //   });
    this.unsubscribePin = this.database
      .collection("quiz-pin")
      .doc(pin)
      .onSnapshot((doc) => {
        if (doc.exists) {
          const { sessionId, eventId } = translateValue(doc.data(), forcedLanguage);
          const quiz = eventId === "NzxuJYEJLyxXFB" ? { primaryColor: "#E4002B" } : {};
          this.setState({ sessionId, eventId, quiz, quizAnswers: {} });
          this.connectTo(eventId, sessionId);
        } else {
          console.warn("show error...");
          // Disconnect...
          this.disconnect(null, "Aucune session en cours pour ce quiz");
        }
      });
  };

  connectTo = (eventId, sessionId) => {
    if (this.unsubscribe) this.unsubscribe();

    const db = this.database.collection("quiz").doc(eventId).collection("sessions").doc(`${sessionId}`);

    // Register user for this quiz session
    // Check if already exists

    const userRef = db.collection("users").doc(userId);
    const statsRef = db.collection("users").doc("__stats");

    // Add if not created
    userRef.get().then((data) => {
      if (!data.exists) {
        const increment = fb.firestore.FieldValue.increment(1);
        userRef.set(user);
        statsRef.set({ count: increment }, { merge: true }); // Move to server ?
      }
    });

    this.unsubscribe = db.onSnapshot((currentSession) => {
      if (currentSession.exists) {
        // console.log(currentSession);
        // console.log("quiz", currentSession.data());
        const { currentQuestion, quiz } = translateValue(currentSession.data(), forcedLanguage);
        this.setState({ quiz });
        if (currentQuestion) {
          const { state, stats, results } = currentQuestion;
          if (currentQuestion.type === "results") slowClick(this.setLoading);
          // console.log("currentQuestion", currentQuestion, this.state.question);
          // console.log("state", state, this.state.state);
          if (state === "ANSWERING") {
            // TODO : check if already answered
            if (
              currentQuestion &&
              currentQuestion.type === "word_cloud" &&
              this.state.question &&
              this.state.question.uuid === currentQuestion.uuid &&
              this.state.state === "WAITING_RESULTS"
            ) {
              // No need to update...
              console.log("word_cloud fix, skip update");
            } else {
              let answers = {};
              if (currentQuestion.type === "drag_and_drop") {
                const { items = [], columns = [] } = currentQuestion;
                answers = {
                  items: keyBy(items, "id"),
                  columns: keyBy(columns, "id"),
                };
              }

              this.setState({
                state: "ANSWERING",
                question: currentQuestion,
                results,
                stats,
                answers,
                answer: "",
                fields: { id: userId },
                startTime: Date.now(),
              });
            }
          } else if (state === "SHOW_RESULTS") {
            this.setState({
              state: "SHOW_RESULTS",
              question: currentQuestion,
              stats,
              results,
            });
          } else {
            this.setState({
              state: "WAITING_NEXT",
              question: currentQuestion,
              stats,
              results,
            });
          }
        } else {
          this.setState({ state: "WAITING" });
        }
      } else {
        this.setState({ state: "UNAVAILABLE", question: null });
      }
    });
  };

  dbSession = () => {
    const { sessionId, eventId } = this.state;
    return this.database.collection("quiz").doc(eventId).collection("sessions").doc(sessionId);
  };

  validateAnswer = () => {
    const { quiz, question, userId, answer, answers, fields, state, startTime } = this.state;
    if (state !== "ANSWERING") return;
    const final = [];
    let others = "";
    if (
      question.type === "input" ||
      (question.type === "word_cloud" && question.answerType !== "whitelist" && question.answerType !== "multiple")
    ) {
      final.push((answer || "").trim());
    } else if (question.type === "word_cloud" && question.answerType === "multiple") {
      if (Object.keys(question.extraQuestion).length > 0) {
        for (var ans in answers) {
          if (ans === "others") {
            others = answers[ans];
          } else final.push((answers[ans] || "").trim());
        }
      }
    } else if (question.type === "multiple_texts") {
      for (const a of Object.keys(answers)) {
        const { textFormat = "none" } = question;
        let answer = (answers[a] || "").trim();
        if (textFormat === "uppercase") {
          answer = answer.toUpperCase();
        }
        final.push(answer);
      }
    } else if (question.type === "form") {
      final.push(fields);
    } else if (question.type === "brick_wall") {
      this.setState({ launchedBall: true });
    } else if (question.type === "scale") {
      if (question.answerType === "multiple") {
        for (let i = 0; i < question.answers.length; i++) {
          const { key, text } = question.answers[i];
          if (answers[i]) final.push({ index: key || text, value: answers[i] });
          else final.push({ index: key || text, value: question.min });
        }
      } else {
        if (answers[0]) final.push({ index: 0, value: answers[0] });
        else final.push({ index: 0, value: question.min });
      }
      if (answers["others"]) {
        others = answers["others"];
      }
    } else if (question.type === "drag_and_drop") {
      const { columns } = answers;
      for (const column of (question.columns || []).filter((q) => !q.isItemsColumn)) {
        const { id, value } = column;
        const columnResults = columns[id] || {};
        final.push({ columnId: id, value, answers: columnResults.itemsIds || [] });
      }
      if (answers["others"]) {
        others = answers["others"];
      }
    } else {
      for (const ans in answers) {
        if (ans === "others") {
          // if there is a textarea and the checkbox is selected
          if (answers[answers[ans].index]) {
            others = answers[ans].value || "";
          }
        } else {
          if (answers[ans]) final.push(ans);
        }
      }
    }
    final.sort();
    const answerTime = Date.now() - startTime;
    const quizAnswers = {
      ...this.state.quizAnswers,
      [question.uuid]: { answers: final, answerTime },
    };
    this.setState({ quizAnswers });

    if (getParameterByName("stressTest")) {
      for (let i = 1; i <= getParameterByName("stressTest"); i++) {
        const tmpUserId = userId + "-" + i;
        this.dbSession()
          .collection("answers")
          .doc(question.uuid)
          .collection("users")
          .doc(tmpUserId)
          .set({
            userId: tmpUserId,
            user,
            answer:
              question.type === "word_cloud" ? [final[0] + "-" + window.Math.floor(window.Math.random() * 200)] : final,
          });
      }
    } else {
      if (question.type === "form") {
        this.dbSession().collection("users").doc(userId).set({
          userId,
          answer: final,
        });
        this.setState({ otherInfo: { ...final[0] } });
      }
      // save user's answer(s)
      this.dbSession().collection("answers").doc(question.uuid).collection("users").doc(userId).set({
        userId,
        answer: final,
        others,
        answerTime,
      });

      // save user's score
      const score = computeOneUserResults(quiz, userId, quizAnswers);
      this.dbSession()
        .collection("users")
        .doc(userId)
        .set({
          userId,
          firstName,
          lastName,
          email,
          teamId,
          score,
          otherInfo: { email, ...this.state.otherInfo },
        });
    }

    this.setState({
      state: "WAITING_RESULTS",
    });
  };

  toggleAnswer = (answer) => {
    const { answers } = this.state;
    this.setState({
      answers: {
        ...answers,
        [answer]: !answers[answer],
      },
    });
  };

  clickAnswer = (index) => {
    return (e) => {
      const { question, answers, state } = this.state;
      const { answerType = "single" } = question;
      if (state !== "ANSWERING") return;
      e.preventDefault();
      e.stopPropagation();
      if (answerType === "single" || answerType === "multiple_good") {
        // Toggle selected index and flush others
        this.setState({ answers: { [index]: !answers[index] } });
      } else {
        // Has max and current isn't checked ?
        if (question.maxAnswerCount && !answers[index]) {
          // Pick true answers
          const checkedAnswers = pickBy(answers, (v) => v);
          if (Object.keys(checkedAnswers).filter((s) => s !== "others").length >= question.maxAnswerCount) {
            return; // Can't do it !
          }
        } // TODO : add to autonomous quiz

        // Toggle selected index only
        this.setState({
          answers: {
            ...answers,
            [index]: !answers[index],
          },
        });
      }
    };
  };

  checkAnswer = (index, checked) => {
    const { question, answers, state } = this.state;
    const { answerType = "single" } = question;
    if (state !== "ANSWERING") return;
    if (answerType === "single" || answerType === "multiple_good") {
      // Toggle selected index and flush others
      this.setState({ answers: { [index]: !answers[index] } });
    } else {
      // Has max and current isn't checked ?
      if (question.maxAnswerCount && !answers[index]) {
        // Pick true answers
        const checkedAnswers = pickBy(answers, (v) => v);
        if (Object.keys(checkedAnswers).filter((s) => s !== "others").length >= question.maxAnswerCount) {
          return; // Can't do it !
        }
      } // TODO : add to autonomous quiz
      // Toggle selected index only
      this.setState({
        answers: {
          ...answers,
          [index]: !answers[index],
        },
      });
    }
  };

  typeTextarea = (index, value) => {
    const { answers, state } = this.state;
    if (state !== "ANSWERING") return;
    this.setState({ answers: { ...answers, others: { index, value } } });
  };

  changeSlide = (index, value) => {
    const { question, state, answers } = this.state;
    const { answerType = "single" } = question;
    if (state !== "ANSWERING") return;
    if (answerType === "single") {
      // Toggle selected index and flush others
      this.setState({ answers: { [index]: value } });
    } else if (answerType === "multiple") {
      this.setState({ answers: { ...answers, [index]: value } });
    }
  };

  updateAnswer = (answer) => this.setState({ answer });

  updateMultipleAnswer = (index, answer) => {
    this.setState({ answers: { ...this.state.answers, [index]: answer } });
  };

  updateField = (field, value) =>
    this.setState({
      fields: {
        ...this.state.fields,
        [field]: value,
      },
    });

  handleChangeExtra = (type, value) => {
    const { state, answers, question } = this.state;
    if (state !== "ANSWERING") return;
    const { extraQuestion } = question;
    const { answerType = "single" } = extraQuestion;

    if (answerType === "single") {
      const extraValues = value;
      this.setState({ answers: { ...answers, others: extraValues } });
    } else if (answerType === "multiple") {
      const ancientValues = (answers.extraValues || []).filter((a) => a.type !== type);

      const extraValues = [
        ...ancientValues,
        {
          type,
          checked: type === "radio" ? true : !((answers.extraValues || []).find((r) => r.type === type) || {}).checked,
          value,
        },
      ];
      const others = extraValues.filter((v) => v.checked).map((v) => v.value);
      this.setState({ answers: { ...answers, extraValues, others } });
    }
  };

  onDragEnd = (result) => {
    const { destination, source, draggableId } = result;
    const { answers } = this.state;
    const { columns } = answers;

    if (!destination) {
      return;
    }

    if (destination.droppableId === source.droppableId && destination.index === source.index) {
      return;
    }

    const start = columns[source.droppableId];
    const finish = columns[destination.droppableId];

    if (start === finish) {
      // change just the order of items inside the same list
      const newitemsIds = Array.from(start.itemsIds);
      newitemsIds.splice(source.index, 1);
      newitemsIds.splice(destination.index, 0, draggableId);

      const newColumn = {
        ...start,
        itemsIds: newitemsIds,
      };

      const newState = {
        ...answers,
        columns: {
          ...columns,
          [newColumn.id]: newColumn,
        },
      };

      this.setState({ answers: newState });
      return;
    }

    // Moving from one list to another
    const startitemsIds = Array.from(start.itemsIds);
    startitemsIds.splice(source.index, 1);
    const newStart = {
      ...start,
      itemsIds: startitemsIds,
    };

    const finishitemsIds = Array.from(finish.itemsIds);
    finishitemsIds.splice(destination.index, 0, draggableId);
    const newFinish = {
      ...finish,
      itemsIds: finishitemsIds,
    };

    const newState = {
      ...answers,
      columns: {
        ...answers.columns,
        [newStart.id]: newStart,
        [newFinish.id]: newFinish,
      },
    };
    this.setState({ answers: newState });
  };

  componentDidMount() {
    this._mounted = true;

    this.database = firebase.firestore();
    if (this.state.pin && AUTO_CONNECT) {
      this.connect();
    }
  }

  componentWillUnmount() {
    this._mounted = false;
    if (this.unsubscribe) {
      this.unsubscribe();
      this.unsubscribe = null;
    }
    if (this.unsubscribePin) {
      this.unsubscribePin();
      this.unsubscribePin = null;
    }
  }

  getStrings() {
    const { quiz } = this.state;
    return mergeStrings(Strings, (quiz || {}).strings);
  }

  render() {
    const { state, question, quiz, answers } = this.state;
    const { primaryColor = "#000" } = quiz;

    if (!checkOrange(quiz)) {
      return <div>Invalid user</div>;
    }

    if (state === "DISCONNECTED" || state === "UNAVAILABLE") {
      return this.renderDisconnected();
    }

    const strings = this.getStrings();

    if (state === "WAITING" || !question) {
      return (
        <Container quiz={quiz}>
          <TextContainer style={{ height: "80vh" }}>{strings.waitingQuestion}</TextContainer>
          {/* {this.renderDisconnectButton()} */}
        </Container>
      );
    }

    if (question.type === "brick_wall") {
      const background = question.clientBackground && question.clientBackground.uri;
      const ball = question.ball && question.ball.uri;
      const { launchedBall } = this.state;
      return (
        <Container quiz={quiz}>
          {this.renderDisconnectButton()}
          <div
            className="brick_client__background"
            style={{
              backgroundImage: 'url("' + background + '")',
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          >
            <div
              className="brick_client__ball"
              onClick={() => {
                if (state === "ANSWERING") this.validateAnswer();
              }}
              onTouchMove={() => {
                if (state === "ANSWERING") this.validateAnswer();
              }}
            >
              <img className={"ball " + (launchedBall ? "launched" : "")} alt="ball" src={ball} />
            </div>
          </div>
        </Container>
      );
    }

    //TODO: remove after Orano's event
    const extraValues = answers.others || [];
    let quizQuestion = { ...question };
    if (question.questionsByProfile) {
      const isOrano = extraValues.includes("orano");
      if (isOrano) {
        quizQuestion = { ...quizQuestion, question: question.profiles.orano.question };
      }
    }
    if (state === "ANSWERING" || state === "WAITING_RESULTS" || state === "SHOW_RESULTS") {
      return (
        <Container quiz={quiz}>
          <div className="quiz" style={{ top: headless ? 0 : undefined }}>
            <div id={question.uuid} className="quiz__container">
              {quiz.clientHeader && (
                <img
                  className="quiz__client__header"
                  alt="logo"
                  src={quiz.logo.uri}
                  style={{ width: windowWidth, height: "auto" }}
                />
              )}
              <ExtraQuestion question={question} onChange={this.handleChangeExtra} state={state} />
              {question.type !== "results" && question.type !== "pin" && (
                <QuizQuestion question={quizQuestion} primaryColor={primaryColor} />
              )}
              {this.renderAnswerSlot(question)}
            </div>
          </div>
          {/* {this.renderDisconnectButton()} */}
        </Container>
      );
    }

    return (
      <Container quiz={quiz}>
        <TextContainer>{strings.thanksForAnswer}</TextContainer>
        {/* {this.renderDisconnectButton()} */}
      </Container>
    );
  }

  renderValidateButton() {
    const { question, state, quiz, answers } = this.state;
    const hasExtraQuestion = Object.keys(question.extraQuestion || {}).length > 0;
    const answeredExtra = Object.keys(answers.others || {}).length > 0;
    const disabled = hasExtraQuestion && !answeredExtra;
    if (state === "ANSWERING")
      return (
        <div
          style={{
            backgroundColor: quiz.primaryColor || "#000",
            fontWeight: "bold",
          }}
          className={cx("quiz__answers__button", { disabled })}
          onClick={disabled ? null : this.validateAnswer}
        >
          {this.getStrings().submit}
        </div>
      );
    else return <div />;
  }

  setLoading = (value) => {
    this.setState({ isLoading: value });
  };

  renderWaitingResults() {
    const { state } = this.state;
    if (state === "WAITING_RESULTS") {
      return (
        <TextContainer
          style={{
            marginTop: 20,
            display: "flex",
            justifyContent: "center",
            position: "relative",
          }}
          fontSize={14}
        >
          {this.getStrings().waitingResults}
        </TextContainer>
      );
    } else return <div />;
  }

  renderAnswerSlot(question) {
    const { state, answers, answer, quiz } = this.state;
    const strings = this.getStrings();
    if (
      !question.type ||
      question.type === "multiple_choice" ||
      question.type === "vote" ||
      question.type === "image_quiz" ||
      question.type === "input" ||
      question.type === "image_vote" ||
      question.type === "multiple_texts" ||
      question.type === "scale" ||
      question.type === "drag_and_drop"
    ) {
      return (
        <div className={`quiz__answers  quiz__answers--${question.type}`}>
          <div className="answers__container">
            {(question.type === "multiple_choice" || question.type === "vote") && (
              <MultipleChoiceVoteQuiz
                question={question}
                answers={answers}
                state={state}
                onClickAnswer={this.clickAnswer}
                onCheckAnswer={this.checkAnswer}
                onTextareaChange={this.typeTextarea}
              />
            )}
            {question.type === "image_vote" && (
              <ImageVote
                primaryColor={quiz.primaryColor}
                question={question}
                answers={answers}
                state={state}
                onClickAnswer={this.clickAnswer}
              />
            )}
            {question.type === "multiple_texts" && (
              <MultipleTexts
                question={question}
                answers={answers}
                state={state}
                onUpdateAnswer={this.updateMultipleAnswer}
              />
            )}
            {question.type === "scale" && (
              <ScaleQuestion question={question} answers={answers} state={state} onSlideChange={this.changeSlide} />
            )}
            {question.type === "image_quiz" && (
              <ImageQuiz question={question} answers={answers} state={state} onClickAnswer={this.clickAnswer} />
            )}
            {question.type === "drag_and_drop" && (
              <DragAndDropQuestion question={question} answers={answers} state={state} onDragEnd={this.onDragEnd} />
            )}
            {question.type === "input" && (
              <InputQuiz question={question} state={state} answer={answer} onUpdateAnswer={this.updateAnswer} />
            )}
          </div>
          {this.renderValidateButton()}
          {this.renderWaitingResults()}
          <QuizResponse {...this.state} question={question} />
        </div>
      );
    } else if (question.type === "word_cloud") {
      return (
        <div className="quiz__answers">
          <WordCloudQuiz
            question={question}
            state={state}
            answers={answers}
            answer={answer}
            onUpdateAnswer={this.updateAnswer}
            onUpdateMultipleAnswers={this.updateMultipleAnswer}
            onToggleAnswer={this.toggleAnswer}
          />
          {this.renderValidateButton()}
        </div>
      );
    } else if (question.type === "form") {
      const { fields = [] } = question;
      const inputType = question.inputType || "text";
      return (
        <div className="quiz__answers">
          {fields.map((field) => (
            <label key={field.text} className="quiz__answer__input">
              {field.text}
              <input
                key={field.text}
                type={inputType}
                value={this.state.fields[field.text] || ""}
                disabled={state !== "ANSWERING"}
                onChange={(e) => this.updateField(field.text, e.target.value)}
              />
            </label>
          ))}
          {this.renderValidateButton()}
          {this.renderWaitingResults()}
        </div>
      );
    } else if (question.type === "results") {
      return <QuizResults question={question} userId={userId} isLoading={this.state.isLoading} strings={strings} />;
    } else if (question.type === "informations") {
      const { slideText } = question;
      return (
        <div className="quiz__answers">
          <div>{slideText}</div>
        </div>
      );
    } else if (question.type === "pin") {
      return (
        <Container quiz={quiz}>
          <TextContainer>{this.getStrings().waitingQuestion}</TextContainer>
          {/* {this.renderDisconnectButton()} */}
        </Container>
      );
    }
  }

  renderDisconnectButton() {
    if (headless) return null;
    return (
      <button
        className="btn btn-danger btn-sm"
        style={{ display: "block", position: "fixed", right: 8, top: 8 }}
        onClick={this.disconnect}
      >
        X
      </button>
    );
  }

  renderDisconnected() {
    const { pin, state } = this.state;
    const strings = this.getStrings();
    const states = {
      UNAVAILABLE: {
        msg: strings.invalidPin,
        color: "#FF5A40",
      },
      DISCONNECTED: {
        msg: strings.pinWillBeGiven,
        // msg: "Entrez le code pin indiqué par les organisateurs",
        color: undefined,
      },
    };

    if (AUTO_CONNECT) {
      return (
        <Container style={{ padding: 8 }}>
          <TextContainer style={{ height: "80vh" }}>{strings.waitingForQuiz}</TextContainer>
        </Container>
      );
    }
    return (
      <Container style={{ padding: 16 }}>
        <h5
          style={{
            textAlign: "center",
            fontSize: 16,
            color: states[state].color,
          }}
        >
          {states[state].msg}
        </h5>
        <input
          style={{
            display: "block",
            margin: "0 auto",
            padding: 12,
            fontSize: 16,
            border: "1px solid #DDD",
            marginBottom: 8,
          }}
          type="number"
          // pattern="[0-9]*"
          onChange={(e) => this.setState({ pin: e.target.value })}
          placeholder="pin"
          value={pin || ""}
        />
        <button
          className="btn btn-primary"
          style={{
            display: "block",
            margin: "0 auto",
            textTransform: "uppercase",
          }}
          disabled={!pin}
          onClick={this.connect}
        >
          {strings.submit}
        </button>
      </Container>
    );
  }
}
