import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
// import App from "./App";
// import registerServiceWorker from './registerServiceWorker';
import { unregister } from "./registerServiceWorker";
unregister();

// console.log("process.env.REACT_APP_VERSION", process.env.REACT_APP_VERSION);

if (process.env.REACT_APP_VERSION === "client") {
  const App = require("./AppClient.js").default;
  ReactDOM.render(<App />, document.getElementById("root"));
} else {
  const App = require("./App.js").default;
  ReactDOM.render(<App />, document.getElementById("root"));
}

// registerServiceWorker();
