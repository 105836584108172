import React from "react";
import Congratulations from "./Congratulations";
import { replaceValues } from "../utils/stringUtils";

export const Rank = ({ rank, strings }) => {
  const text = replaceValues(strings.rankSuffix[rank] || strings.rankSuffix.others, { count: rank });

  // Split number and sup
  const [, num, , sup] = text.match(/([^<]*)(<sup>(.*)<\/sup>)?/);

  return (
    <>
      {num}
      <sup style={{ fontSize: "60%", fontWeight: "bold" }}>{sup}</sup>
    </>
  );
};

const ResultsWithScore = (props) => {
  const { question, isLoading, userId, strings, showWinnerOnly } = props;
  const { scores } = question;
  const userInfo = scores[userId];
  return (
    <div style={{ textTransform: "uppercase", fontSize: "1.5em" }}>
      {showWinnerOnly && <>{strings.yourScore}</>}
      <div
        style={{
          width: "max-content",
          borderRadius: 16,
          margin: "16px auto 24px",
          padding: 10,
          border: "2px solid #16ab39",
          textAlign: "center",
          fontWeight: "bold",
          color: "#16ab39",
          boxShadow:
            "0 6px 10px 0 rgba(0, 0, 0, .14), 0 1px 18px 0 rgba(0, 0, 0, .12), 0 3px 5px -1px rgba(0, 0, 0, .2)",
        }}
      >
        <h5 style={{ margin: 0, fontWeight: "bold" }}>
          <i className="fa fa-gamepad" style={{ marginRight: 5 }} />
          {strings.score} : {userInfo.score}
        </h5>
      </div>
      {!showWinnerOnly && (
        <>
          <hr />
          <div>
            <span role="img" aria-label="Trophee">
              🏆
            </span>{" "}
            {strings.yourRanking}
          </div>
          <div style={{ fontSize: "2em" }}>
            <Rank rank={userInfo.rank} strings={strings} />
            {userInfo.rank <= 3 && (
              <div style={{ fontSize: "0.8em" }}>
                {strings.congrats}
                <br />
                <span role="img" aria-label="Congratulations">
                  🎊🎉
                </span>
                <Congratulations isLoading={isLoading} />
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default ResultsWithScore;
