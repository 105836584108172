import React, { Component } from 'react';
import { groupByCount } from './utils';
import CdnImage from '../components/CdnImage';
import cx from 'classnames';

export default class ImageQuiz extends Component {
  render() {
    const { question, answers, state, onClickAnswer } = this.props;
    const { maxWidth } = question;
    const answers_group = groupByCount(question.answers, 2);
    return (
      <div className="quiz__answers__container">
        {answers_group.map((group, id) => (
          <div key={id} style={{ display: 'flex', flexDirection: 'row' }}>
            {group.map((ans, idx) => {
              const isActive = answers[id * 2 + idx];
              let active = !isActive ? 'inset 0 0 0 1px #DDD' : 'inset 0 0 0 3px #000';
              let response = '';
              let answerClassName = '';
              if (state === 'SHOW_RESULTS') {
                if (ans.checked) {
                  response = 'inset 0 0 0 3px #16ab39';
                  answerClassName = 'correct';
                } else if (!ans.checked && isActive) {
                  response = 'inset 0 0 0 3px red';
                  answerClassName = 'wrong';
                } else response = active;
              }
              const boxShadow = state === 'SHOW_RESULTS' ? response : active;
              return (
                <div
                  className={cx('quiz__answer', { active: isActive }, answerClassName)}
                  key={idx}
                  style={{ flex: 1, boxShadow, margin: 15, textAlign: 'center' }}
                  onClick={onClickAnswer(id * 2 + idx)}
                >
                  {ans.image && ans.image.uri && (
                    <CdnImage
                      className="quiz__answer__image"
                      alt={`answer ${ans.text}`}
                      src={ans.image.uri}
                      width="100%"
                      maxWidth={maxWidth || 300}
                    />
                  )}
                  {ans.text && <p style={{ marginBottom: 5, marginTop: 5 }}>{ans.text}</p>}
                </div>
              );
            })}
          </div>
        ))}
      </div>
    );
  }
}
