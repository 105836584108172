import React, { Component } from "react";
import { Cell, Legend, Pie, PieChart } from "recharts";
import "./PieChart.scss";

// const defaultColors = ["#685C6B", "#CBA17E", "#ff8519", "#bcbd22", "#FEC007", "#2ca02c", "#618685"];
const defaultColors = ["#003f5c", "#2f4b7c", "#665191", "#a05195", "#d45087", "#f95d6a", "#ff7c43", "#ffa600"];

// Bleu : rgb(26, 58, 139)
// Vert : rgb(112, 197, 87)
// Rose : rgb(253, 70, 148)
// Jaune : rgb(, 255, 230, 97)

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent }) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);
  if (percent === "0%") return null;
  return (
    <text x={x} y={y} fill="white" textAnchor="middle" dominantBaseline="central">
      {percent}
    </text>
  );
};
class SlavePieChart extends Component {
  // componentDidMount() {
  //   const { stats, question, primaryColor, fontSize = 22, colors, labelPadding = 8 } = this.props;
  //   if (stats.totalAnswers === 0) return;
  //   if (question.type === "input" || question.type === "word_cloud") return;
  //   console.log("stats", stats);

  //   const { answers } = stats;

  //   const ctx = this._canvas && this._canvas.getContext("2d");
  //   const labels = answers.map((ans, index) => `${(ans.text.length > 20 ? index + 1 : ans.text)} (${ans.percent})`);
  //   const percents = answers.map(ans => ans.percent.replace("%", ""));
  //   // const counts = answers.map(ans => ans.count);
  //   const data = {
  //     labels: labels,
  //     datasets: [
  //       {
  //         label: "#",
  //         data: percents, //chartType === "pie" ? percents : counts,
  //         backgroundColor: colors || ["#685C6B", "#CBA17E", "#ff8519", "#bcbd22", "#FEC007", "#2ca02c", "#618685"]
  //       }
  //     ]
  //   };

  //   this.chart =
  //     this._canvas &&
  //     new Chart(ctx, {
  //       type: "pie",
  //       data,
  //       options: {
  //         responsive: true,
  //         legend: {
  //           display: true,
  //           position: "right",
  //           align: "center",
  //           fullWidth: true,
  //           labels: {
  //             fontSize,
  //             padding: labelPadding,
  //             // fontFamily: "Montserrat"
  //           }
  //         }
  //       }
  //     });
  // }

  render() {
    const {
      stats,
      width = 600,
      height = 400,
      colors = defaultColors,
      outerBorderSize = 0,
      title,
      showLegend = true,
      showPercentsOnChart = true,
    } = this.props;
    if (!stats) return null;
    const data = stats.answers.map((a) => ({ ...a, name: a.text, value: a.count || 0 }));
    // const data = [{name: 'Group A', value: 400}, {name: 'Group B', value: 300},
    //               {name: 'Group C', value: 300}, {name: 'Group D', value: 200}];
    return (
      <div>
        {title && <div className="pie-chart__title">{title}</div>}
        <div style={{ width, margin: "0 auto", marginTop: 15, position: "relative" }}>
          {/* <canvas width={width} height={height} ref={el => (this._canvas = el)} /> */}

          <PieChart width={width} height={height} onMouseEnter={this.onPieEnter}>
            <Pie
              data={data.map((d) => ({ ...d, ignore: true }))}
              cx={width / 4}
              cy={height / 2}
              outerRadius={height / 3 + outerBorderSize}
              // fill="#8884d8"
              stroke="none"
              startAngle={0}
              paddingAngle={0}
            >
              {data.map((entry, index) => (
                <Cell fill="#fff" />
              ))}
            </Pie>
            <Pie
              data={data}
              cx={width / 4}
              cy={height / 2}
              outerRadius={height / 3}
              // fill="#8884d8"
              stroke="none"
              startAngle={0}
              paddingAngle={0}
              label={showPercentsOnChart ? renderCustomizedLabel : null}
            >
              {data.map((entry, index) => (
                <Cell fill={colors[index % colors.length]} />
              ))}
            </Pie>

            {showLegend && (
              <Legend layout="vertical" verticalAlign="middle" align="right" content={this.renderLegend} />
            )}
          </PieChart>
        </div>
      </div>
    );
  }

  renderLegend = (props) => {
    const { fontSize, showPercentsOnChart = true } = this.props;
    const { payload } = props;
    const toDisplay = payload.filter((p) => !p.payload.ignore);
    return (
      <div className="PieChart__legend customized-legend">
        {toDisplay.map((entry, index) => (
          <div style={{ display: "flex", fontSize, marginBottom: 4 }}>
            <span
              className="PieChart__legend__rectangle"
              style={{ backgroundColor: entry.color, height: fontSize }}
            ></span>
            {entry.value}{" "}
            {!showPercentsOnChart && (
              <>
                &nbsp;&nbsp;
                <span className="PieChart__legend__percent" style={{ color: entry.color }}>
                  ({entry.payload.percent})
                </span>
              </>
            )}
          </div>
        ))}
      </div>
    );
  };
}

export default SlavePieChart;
