/* eslint-disable no-restricted-syntax */
/* eslint-disable class-methods-use-this */
import isEmpty from "lodash/isEmpty";
import isEqual from "lodash/isEqual";
import isUndefined from "lodash/isUndefined";
import keyBy from "lodash/keyBy";
import omitBy from "lodash/omitBy";

import pubsubService from "../pubsub.service";
import WsFirestoreDocument from "./WsFirestoreDocument";

const noop = () => {};

function computeChanges(prevDocs, newDocs) {
  const prevById = keyBy(prevDocs, "id");
  const newById = keyBy(newDocs, "id");
  const changes = [];

  // Handle removed
  for (const prev of prevDocs) {
    if (!(prev.id in newById)) {
      changes.push({ type: "removed", doc: prev });
    }
  }

  // Handle added
  for (const next of newDocs) {
    if (!(next.id in prevById)) {
      changes.push({ type: "added", doc: next });
    } else {
      const prev = prevById[next.id];
      if (!isEqual(prev.data(), next.data())) {
        changes.push({ type: "modified", doc: next });
      }
    }
  }

  return changes;
}

export default class WsFirestoreCollection {
  // eslint-disable-next-line no-useless-constructor
  constructor(path, options = {}) {
    this.path = path;
    this.options = options;
  }

  doc(docId) {
    return new WsFirestoreDocument(`${this.path}/${docId}`);
  }

  topic() {
    if (isEmpty(this.options)) return this.path;
    return `${this.path}?options=${encodeURIComponent(JSON.stringify(this.options))}`;
  }

  cloneWithOptions(newOptions) {
    const options = omitBy({ ...this.options, ...newOptions }, isUndefined);
    return new WsFirestoreCollection(this.path, options);
  }

  where(key, op, value) {
    return this.cloneWithOptions({ where: { key, op, value } });
  }

  orderBy(key, order) {
    return this.cloneWithOptions({ order: { key, order } });
  }

  startAfter(doc) {
    return this.cloneWithOptions({ startAfter: doc.id });
  }

  limit(limit) {
    return this.cloneWithOptions({ limit });
  }

  onSnapshot(callback) {
    if (!callback) return noop;

    const fun = (typeof callback === "object" && callback.next) || (typeof callback === "function" && callback);

    if (!fun) {
      console.error("Invalid callback function", callback);
      return noop;
    }

    let latestDocs = [];

    // Subscribe :)
    return pubsubService.onCollection(this.topic(), (res) => {
      // Wrap like it would be in firebase
      const prevDocs = latestDocs;
      const newDocs = res.map((d) => ({ id: d.id, data: () => d }));
      latestDocs = newDocs;

      const snapshot = {
        docs: latestDocs,
        docChanges: () => {
          // compute changes from new vs prev...
          return computeChanges(prevDocs, newDocs);
        },
      };
      fun(snapshot);
    });
  }

  get() {
    return new Promise((resolve) => {
      // Build snapshot and exit
      const exit = this.onSnapshot((res) => {
        exit();
        resolve(res);
      });
    });
  }

  add(document) {
    return new Promise((resolve) => {
      pubsubService.add(this.path, document);
      setTimeout(resolve, 100);
    });
  }
}
