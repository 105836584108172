import React, { PureComponent } from "react";
import PropTypes from "prop-types"
import Confetti from "react-dom-confetti";

const defaultConfig = {
  angle: 90,
  spread: 75,
  startVelocity: 45,
  elementCount: 100,
  dragFriction: 0.1,
  duration: 10000,
  delay: 20,
  width: "15px",
  height: "15px",
  colors: ["#833ed8", "#13acdb", "#55c928", "#ff718d", "#ffbd44", "#fdff6a", "#c9db3f", "#c45b2b", "#090c63"],
};

class Congratulations extends PureComponent {
  render() {
    const { isLoading, confettiConfig = defaultConfig } = this.props;
    return <Confetti className="quiz__confetti" active={!isLoading} config={confettiConfig} />;
  }
}

Congratulations.propTypes = {
  isLoading: PropTypes.bool.isRequired,
};
export default Congratulations;
