import React, { Component } from "react";

export default class InputQuiz extends Component {
  render() {
    const { state, question, answer, onUpdateAnswer } = this.props;
    const inputType = question.inputType || "text";
    return (
      <form>
        <label className="quiz__answer__input">
          {question.label}
          <input
            type={inputType}
            value={answer || ""}
            disabled={state !== "ANSWERING"}
            onChange={e => onUpdateAnswer(e.target.value)}
            autoFocus
            placeholder=""
          />
        </label>
      </form>
    );
  }
}
