import cx from "classnames";
import React from "react";
import WinnerResult from "../../components/WinnerResult";
import { Pin } from "../../screens/SlaveScreen";
import SlaveLogo from "./SlaveLogo";

const SlaveResult = (props) => {
  const { backgroundColor, title = "", logo, template = "default", question, strings, pin, questions } = props;
  const { showWinnerOnly, leaderboard = [] } = question;

  const form = questions.find((q) => q.type === "form") || {};
  const { fields = [] } = form;

  if (showWinnerOnly)
    return (
      <div
        className={cx("slave-container", "slave-container--" + template, "slave-container--" + question.type)}
        style={{ backgroundColor }}
      >
        <WinnerResult question={question} strings={strings} />
      </div>
    );

  return (
    <div
      className={cx("slave-container", "slave-container--" + template, "slave-container--" + question.type)}
      style={{ backgroundColor }}
    >
      <Pin pin={pin} />
      <SlaveLogo src={logo} style={{ position: "absolute", bottom: 10, right: 10, maxWidth: 200 }} />
      <div className="question-container">
        {template === "default" && (
          <div className="question-number-container">
            <p className="question-number" />
          </div>
        )}
        {title.split("\n").map((t, index) => (
          <h1 key={index} className="question-text">
            {t}
          </h1>
        ))}

        <table className="slave-leaderboard" cellpadding={20} cellSpacing={30}>
          <thead>
            <tr>
              <th>#</th>
              {fields.length === 0 && <th>Nom</th>}
              {fields.length === 0 && <th>Prénom</th>}
              {fields.length > 0 && fields.map((f) => <th key={f.text}>{f.text}</th>)}
              <th>Score</th>
            </tr>
          </thead>
          <tbody>
            {leaderboard.slice(0, 10).map((u) => {
              return (
                <tr>
                  <td>{u.rank}</td>
                  {fields.length === 0 && <td>{u.firstName}</td>}
                  {fields.length === 0 && <td>{u.lastName}</td>}
                  {fields.length > 0 && fields.map((f) => <td key={f.text}>{u[f.text]}</td>)}
                  <td>{u.score}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default SlaveResult;
