import React, { PureComponent } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import styled from "styled-components";
import cx from "classnames";
import keyBy from "lodash/keyBy";
import BlockOverscroll from "../components/BlockOverscroll";

const ItemContainer = styled.div`
  border: 1px solid lightgrey;
  border-radius: 20px;
  padding: 8px;
  margin: 8px;
  max-width: max-content;
  transition: background-color 0.2s ease;
  background-color: ${props => (props.isDragDisabled ? "lightgrey" : props.isDragging ? "#9750b1" : props.color)};
`;

const ItemList = styled.div`
  padding: 8px;
  transition: background-color 0.2s ease;
  background-color: ${props => (props.isDraggingOver ? "skyblue" : "transparent")};
  flex-grow: 1;
  min-height: 100px;
`;

class Column extends PureComponent {
  render() {
    const { columnItems = [], column, disabled, question = { question } } = this.props;
    const { id, title, showTitle = true } = column;
    return (
      <div style={{ flex: 1 }} className={cx("dnd--column__outerContainer", `columnId-${id}`)}>
        {showTitle && <h4 className="dnd--column__title">{title}</h4>}
        <div className="dnd--column__innerContainer">
          <Droppable droppableId={id} type="ITEM">
            {(provided, snapshot) => (
              <ItemList
                ref={provided.innerRef}
                {...provided.droppableProps}
                isDraggingOver={snapshot.isDraggingOver}
                className="dnd--column__items--list"
              >
                {columnItems.map((item, index) => (
                  <Item key={item.id} item={item} index={index} disabled={disabled} question={question} />
                ))}
                {provided.placeholder}
              </ItemList>
            )}
          </Droppable>
        </div>
      </div>
    );
  }
}

class Item extends React.Component {
  render() {
    const { item, index, disabled, question } = this.props;
    const { categories = [] } = question;
    const { id, content, category } = item;
    const itemCategory = categories.find(c => c.id === category);
    const color = itemCategory && itemCategory.color ? itemCategory.color : "white";
    return (
      <Draggable draggableId={id} index={index} isDragDisabled={disabled}>
        {(provided, snapshot) => (
          <ItemContainer
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            ref={provided.innerRef}
            isDragging={snapshot.isDragging}
            isDragDisabled={disabled}
            color={color}
            className="dnd--item__container"
          >
            {content}
          </ItemContainer>
        )}
      </Draggable>
    );
  }
}

const ColumnsContainer = ({ list = [], answers, disabled, question }) => {
  let { columns, items } = answers;
  if (!columns || !items) {
    items = keyBy(question.items || [], "id");
    columns = keyBy(question.columns || [], "id");
  }

  return (
    <div style={{ display: "flex" }}>
      {list.map(c => {
        const columnId = c.id;
        const column = columnId && columns[columnId];
        const { id, itemsIds = [] } = column;
        const columnItems = itemsIds.map(itemId => items[itemId]);
        return <Column key={id} column={column} columnItems={columnItems} disabled={disabled} question={question} />;
      })}
    </div>
  );
};

export default class DrapAndDropQuestion extends PureComponent {
  render() {
    const { question, state, answers, onDragEnd } = this.props;
    const { categories = [] } = question;
    const itemsColumns = (question.columns || []).filter(a => a.isItemsColumn);
    const answersColumns = (question.columns || []).filter(a => !a.isItemsColumn);
    const disabled = state !== "ANSWERING";
    return (
      <div className="dnd__question">
        <BlockOverscroll />
        <DragDropContext onDragEnd={onDragEnd}>
          <ColumnsContainer list={itemsColumns} answers={answers} disabled={disabled} question={question} />
          <ColumnsContainer list={answersColumns} answers={answers} disabled={disabled} question={question} />
        </DragDropContext>
        <div className="dnd__categories__legend">
          {categories.map((c, index) => (
            <div
              key={index}
              className="dnd__category__legend--container"
              style={{ display: "flex", margin: "5px 0px" }}
            >
              <div style={{ width: 20, height: 20, backgroundColor: c.color, marginRight: 10, alignItems: "center" }} />
              {c.title}
            </div>
          ))}
        </div>
      </div>
    );
  }
}
