import React, { Component } from "react";
import { checkCorrect } from "./utils";
import TextareaAutosize from "react-textarea-autosize";
import CdnImage from '../components/CdnImage'

export default class MultipleChoiceVoteQuiz extends Component {
  render() {
    const { question, answers, state, onClickAnswer, onCheckAnswer, onTextareaChange } = this.props;
    const { hasImage, image, template } = question;
    const isCorrect = checkCorrect(question, answers);
    return (
      <div className="quiz_multiple_choice_vote">
        {hasImage && image && (
          <div style={{ width: "100%", textAlign: "center", marginBottom: 10 }}>
            <CdnImage src={image.uri} style={{ width: "70vw", height: "auto" }} maxWidth={400} />
          </div>
        )}
        <div className="quiz__multiple__answers__container">
          {question.answers.map((ans, idx) => {
            const { text, type = "text" } = ans;
            let clazz = "quiz__answer";
            if (state === "SHOW_RESULTS") {
              if (question.type === "vote") clazz += " vote";
              else if (!isCorrect || question.answerType !== "multiple_good") {
                if (ans.checked) clazz += " correct";
                else clazz += " incorrect";
              }
            }
            if (answers[idx]) clazz += " active";
            if (template === "checkbox") {
              return (
                <div key={idx} id={`${question.uuid}-${idx}`}>
                  <label className="quiz__answer__text" style={{ position: "relative" }}>
                    <input
                      checked={answers[idx] || false}
                      value={answers[idx] || false}
                      className="checkbox-field"
                      type="checkbox"
                      style={{ marginRight: 12 }}
                      onChange={e => onCheckAnswer(idx, e.target.checked)}
                    />
                    <span className="checkmark" style={{ display: "none" }}></span>
                    {text}
                  </label>
                  {type === "textarea" && (
                    <div className="textarea-field" style={{ marginTop: 10 }}>
                      <TextareaAutosize
                        // disable textarea if user isn't answering or checkbox isn't checked
                        disabled={state !== "ANSWERING" || !answers[idx]}
                        minRows={3}
                        maxRows={6}
                        className="quiz__answer__text"
                        onChange={e => onTextareaChange(idx, e.target.value)}
                      />
                    </div>
                  )}
                </div>
              );
            }
            return (
              <div
                key={idx}
                id={`${question.uuid}-${idx}`}
                className={clazz}
                onClick={state === "ANSWERING" ? onClickAnswer(idx) : undefined}
              >
                <div className="quiz__answer__label">{idx + 1}</div>
                <div
                  className="quiz__answer__text"
                  style={{ fontSize: question.answers.length > 5 ? "0.9em" : undefined }}
                >
                  <div style={{ display: "flex" }}>
                    <div style={{ width: state === "SHOW_RESULTS" && "100%" }}>{ans.text}</div>
                    {/* <div style={{ width: state === "SHOW_RESULTS" && question.type === "vote" ? "75%" : "100%" }}>
                    {ans.text}
                  </div> */}
                    {/* <div
                    style={{ width: "25%", display: question.type === "vote" ? "block" : "none", textAlign: "right" }}
                  /> */}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}
