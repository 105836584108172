import React, { PureComponent } from "react";
import InputQuiz from "./InputQuiz";
import cx from "classnames";
import { MultipleTexts } from ".";

export default class WordCloudQuiz extends PureComponent {
  render() {
    const { question, state, answers, answer, onToggleAnswer, onUpdateAnswer, onUpdateMultipleAnswers } = this.props;
    const { answerType } = question;
    if (answerType === "whitelist") {
      const { wordList = "" } = question;
      const list = wordList
        .split("\n")
        .map(s => s.trim())
        .filter(s => s);
      return (
        <div className="quiz__answers__tags">
          {list.map(word => (
            <div
              key={word}
              className={cx("quiz__answers__tag", { active: answers[word] })}
              onClick={() => state === "ANSWERING" && onToggleAnswer(word)}
            >
              {word}
            </div>
          ))}
        </div>
      );
    } else if (answerType === "multiple") {
      return (
        <MultipleTexts question={question} answers={answers} state={state} onUpdateAnswer={onUpdateMultipleAnswers} />
      );
    } else {
      return (
        <InputQuiz question={question} state={state} answer={answer} onUpdateAnswer={onUpdateAnswer} />
      );
    }
  }
}
