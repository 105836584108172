import React from "react";
import { BarChart, Bar, XAxis, Tooltip, LabelList } from "recharts";
import sortBy from "lodash/sortBy";
import reverse from "lodash/reverse";
import find from "lodash/find";
import PropTypes from "prop-types";

const getDataSet = (dataList, maxItem) => {
  const podiumDataList = [];
  const sortedDataList = sortBy(dataList, ["percent", "text"]).slice(dataList.length - maxItem, dataList.length);
  const dataWithPosition = sortedDataList.map((data, index, array) => {
    let position = array.length - index;
    const objectWithSamePercent = find(
      array,
      someVar => someVar.text !== data.text && someVar.percent === data.percent
    );

    if (objectWithSamePercent) {
      position = objectWithSamePercent.position || array.length - index;
    }
    return Object.assign({}, data, { position: position });
  });
  const lastItem = dataWithPosition.pop();
  podiumDataList.push(lastItem);

  reverse(dataWithPosition).forEach((d, index) => {
    if (index % 2) {
      podiumDataList.push(d);
    } else {
      podiumDataList.unshift(d);
    }
  });
  return podiumDataList;
};

const colors = ["#F0C300", "#D6D6D6", "#C49C48"];

const renderCustomizedLabel = (props, dataSet) => {
  const color = colors[dataSet[props.index].position - 1] || "white";
  const { x, y, width, value } = props;
  const radius = width / 7;

  return (
    <g>
      <circle cx={x + width / 2} cy={y + radius - 20} r={radius} fill={color} stroke="black" strokeWidth="1" />
      <text
        className="text-chart"
        x={x + width / 2}
        y={y + radius - 20}
        fill="#000"
        textAnchor="middle"
        dominantBaseline="middle"
        style={{ fontSize: "2.5vh" }}
      >
        {Math.round(value * 10) / 10}
      </text>
    </g>
  );
};
const Podium = props => {
  const { color, dataList, height, maxItem, width } = props;
  const dataSet = getDataSet(dataList, maxItem);
  console.log("LabelList", LabelList);
  return (
    <BarChart width={width} height={height} data={dataSet} margin={{ top: 20, right: 30, left: 20, bottom: 5 }}>
      <XAxis className="Poduim-X-avis-text" dataKey="text" style={{ fontSize: "3vh" }} />
      <Tooltip />
      <Bar dataKey="percent" fill={color}>
        <LabelList content={data => renderCustomizedLabel(data, dataSet)} />
      </Bar>
    </BarChart>
  );
};

Podium.defaultProps = {
  dataList: [],
  height: 350,
  maxItem: 3,
  width: 900
};

Podium.propTypes = {
  dataList: PropTypes.array,
  height: PropTypes.number,
  maxItem: PropTypes.number,
  width: PropTypes.number
};

export default Podium;
