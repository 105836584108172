import React, { useState } from "react";
import orderBy from "lodash/orderBy";
import Congratulations from "./Congratulations";

import "./WinnerResult.scss";

const WinnerResult = (props) => {
  const { question, strings } = props;
  const [loading, setLoading] = useState(true);
  const { scores } = question;
  const orderedScores = orderBy(scores, ["score"], ["desc"]);
  // const orderedScores = orderBy(scores, ["rank"], ["asc"]); //Autre possibilité ?
  const winner = orderedScores[0];
  setInterval(() => {
    setLoading(false);
  }, 700);
  return (
    <div className="winner-results">
      <div className="box">
        <span className="title">{strings.winnerIs}</span>
        <br />
        <div>
          <span className="name">
            <span role="img" aria-label="Trophee">
              🏆
            </span>{" "}
            {winner.firstName} {winner.lastName}{" "}
            <span role="img" aria-label="Trophee">
              🏆
            </span>
          </span>

          <div className="congrats-message">
            {strings.congrats}
            <br />
            <span role="img" aria-label="Congratulations">
              🎊🎉
            </span>
            <Congratulations isLoading={loading} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default WinnerResult;
