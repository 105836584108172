import React from "react";
import PropTypes from "prop-types";
import Images, { cloudimg,  extractFormat } from "../utils/Images";

function double(n) {
  if (typeof n === "number") return n * 2;
  return n;
}

export const cdnImagePropTypes = {
  imageType: PropTypes.oneOf(["auto", "original", "jpg"]),
  func: PropTypes.oneOf(["bound", "crop", "fit"]),
};

const CdnImage = (allProps) => {
  const {
    as: Component,
    alt,
    className,
    src,
    srcSet,
    maxWidth,
    maxHeight,
    openOnClick,
    func,
    imageType,
    cdnOptions,
    aspectRatio,
    style,
    ...props
  } = allProps;

  function computeAspectRatio() {
    if (!aspectRatio) return undefined;
    if (typeof aspectRatio === "number") return aspectRatio;

    if (maxWidth && maxHeight && func === "crop") {
      return maxWidth / maxHeight;
    }

    // Compute from current image
    if (typeof src === "object") {
      const { width, height } = src;
      return `(${width} / ${height})`;
    }
    return undefined;
  }

  const computedAspectRatio = computeAspectRatio();
  let finalStyle = style;
  if (computedAspectRatio) {
    finalStyle = { ...finalStyle, aspectRatio: computedAspectRatio };
  }

  if (!maxWidth && !maxHeight) {
    // Turn into CDN...
    const url = Images.cdn(src);
    if (!url) return null;
    return <Component src={url} className={className} style={finalStyle} {...props} />;
  }

  const imageSrc = cloudimg(src, {
    w: maxWidth,
    h: maxHeight,
    func,
    org_if_sml: 1,
    force_format: extractFormat(imageType),
    ...cdnOptions,
  });
  if (!imageSrc) return null;

  function computeSrcSet() {
    if (srcSet === "auto" && func !== "crop" && !maxHeight) {
      if (src.width && maxWidth && src.width <= maxWidth) return undefined;
      return `${cloudimg(src, {
        w: Math.min(src.width, maxWidth * 2),
        func,
        org_if_sml: 1,
        force_format: extractFormat(imageType),
        ...cdnOptions,
      })} 2x`;
    }
    if (typeof srcSet === "string" && srcSet !== "auto") return srcSet;
    if (srcSet === true && (maxWidth || maxHeight)) {
      // Check if image big enough
      if (src.width && maxWidth && src.width <= maxWidth * 1.685) return undefined;
      if (src.height && maxHeight && src.height <= maxHeight * 1.685) return undefined;

      return `${cloudimg(src, {
        w: double(maxWidth),
        h: double(maxHeight),
        func,
        org_if_sml: 1,
        force_format: extractFormat(imageType),
        ...cdnOptions,
      })} 2x`;
    }
    return undefined;
  }

  return <img alt={alt} src={imageSrc} srcSet={computeSrcSet()} className={className} style={finalStyle} {...props} />;
};

const Image = ({ alt, ...props }) => <img alt={alt} {...props} />;

CdnImage.defaultProps = {
  as: Image,
  aspectRatio: undefined,
  cdnOptions: undefined,
  className: "",
  imageType: "auto",
  func: "bound",
  maxHeight: undefined,
  maxWidth: undefined,
  openOnClick: false,
  src: undefined,
  srcSet: true,
};

CdnImage.propTypes = {
  as: PropTypes.oneOfType([PropTypes.string, PropTypes.elementType]),
  aspectRatio: PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.number]),
  cdnOptions: PropTypes.object,
  className: PropTypes.string,
  imageType: cdnImagePropTypes.imageType,
  func: cdnImagePropTypes.func,
  maxHeight: PropTypes.number,
  maxWidth: PropTypes.number,
  openOnClick: PropTypes.bool,
  src: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  srcSet: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
};

export const ImageWithAspectRatio = (props) => {
  const { src } = props;
  if (!src) return null;

  const aspectRatio = Images.aspectRatio(src);
  if (!aspectRatio) return <CdnImage {...props} />;
  return (
    <div className="image-ratio-wrapper" style={{ paddingTop: `${100 / aspectRatio}%` }}>
      <CdnImage {...props} />
    </div>
  );
};

ImageWithAspectRatio.propTypes = CdnImage.propTypes;

export default CdnImage;
