import React, { Component } from "react";

class Navbar extends Component {
  render() {
    const { i18n, title, pin, subtitle, onRefresh, onSignOut } = this.props;
    return (
      <nav className="navbar navbar-dark fixed-top bg-dark flex-md-nowrap p-0 shadow">
        <a className="navbar-brand col-md-3 mr-0" href="#">
          {title}
        </a>

        <div className="w-100" style={{ padding: 12, color: "#fff" }}>
          {subtitle}
        </div>
        {/* <input className="form-control form-control-dark w-100" type="text" placeholder="Search" aria-label="Search" /> */}
        <ul className="navbar-nav px-3">
          <li className="nav-item text-nowrap" style={{ color: "white", cursor: "pointer" }} onClick={onRefresh}>
            <i style={{ color: "white" }} className="fa fa-refresh" />
          </li>
        </ul>
        <ul className="navbar-nav px-3">
          <li className="nav-item text-nowrap" style={{ color: "white" }}>
            {i18n.t("pin")} <code>{pin}</code>
            {/* <a className="nav-link" href="#">
            Sign out
          </a> */}
          </li>
        </ul>
        <ul className="navbar-nav px-3">
          <li className="nav-item text-nowrap" style={{ color: "white", cursor: "pointer" }} onClick={onSignOut}>
            <i style={{ color: "white" }} className="fa fa-sign-out" />
          </li>
        </ul>
      </nav>
    );
  }
}

export default Navbar;
