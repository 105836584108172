import trim from "lodash/trim";

export function checkCorrect(question, answers) {
  const correctAnswers = [];
  for (var i in question.answers) {
    if (question.answers[i].checked) {
      correctAnswers.push("" + i);
    }
  }

  if (question.answerType === "multiple_good") {
    const finalAnswers = Object.keys(answers).filter((key) => answers[key] === true);
    console.log("is", question.answerType, finalAnswers, "for", correctAnswers);
    return finalAnswers.length === 1 && correctAnswers.indexOf("" + finalAnswers[0]) !== -1;
  }

  const correctValue = correctAnswers.join("-");
  return (
    Object.keys(answers)
      .filter((key) => answers[key] === true)
      .join("-") === correctValue
  );
}

function normalizeInputAnswer(value) {
  if (!value) return "";
  return trim(value).toLowerCase();
}

export function isValidInput(question, answer) {
  const possibleAnswers = [question.answer, ...(question.otherAnswers || [])]
    .map(normalizeInputAnswer)
    .filter((v) => v);
  const userAnswer = normalizeInputAnswer(answer);
  return possibleAnswers.indexOf(userAnswer) !== -1;
}
// export function checkIfInputAnswerIsCorrect(answer, answers) {
//   for (const a of answers) {
//     if (answer && answer.trim().toLowerCase() === a.trim().toLowerCase()) return true;
//   }
//   return false;
// }

export function groupByCount(array, count) {
  if (!array) return array;

  const grouped = [];
  for (var i = 0; i < array.length; i += count) {
    const group = [];
    for (var j = i; j < array.length && j < i + count; j++) {
      group.push(array[j]);
    }
    grouped.push(group);
  }
  return grouped;
}
