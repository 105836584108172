import React, { PureComponent } from "react";
import cx from "classnames";
import SlaveLogo from "./SlaveLogo";

export default class SlaveInformation extends PureComponent {
  render() {
    const { title = "", text = "", template = "default", logo, question, pin } = this.props;

    return (
      <div
        className={cx("slave-container", "slave-container--" + template, "slave-container--" + question.type)}
        style={{ textAlign: "left" }}
      >
        <SlaveLogo src={logo} style={{ maxWidth: 200, position: "absolute", bottom: 10, right: 10 }} />
        <div className="question-container">
          {template !== "stand" && (
            <div className="question-number-container">
              <p className="question-number" />
            </div>
          )}
          <div style={{ display: "flex" }}>
            {template === "stand" && <i className="fa fa-chevron-right" />}
            <div>
              {title.split("\n").map((t, index) => (
                <h1 key={index} className="question-text">
                  {t}
                </h1>
              ))}
              {text.split("\n").map((t, index) => (
                <p key={index} className="question-text question-text--small">
                  {t}
                </p>
              ))}
              {pin}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
