import omitBy from "lodash/omitBy";
import mapValues from "lodash/mapValues";

export function translateArray(array, language) {
  if (!array) return array;
  return array.map((obj) => translateValue(obj, language));
}

export function translateValue(v, language) {
  if (!v) return v;
  if (Array.isArray(v)) {
    return translateArray(v, language);
  }
  if (typeof v === "object") {
    return translateObject(v, language);
  }
  return v;
}

function isEmptyValue(v) {
  return v === undefined || v === null || v === "";
}

export function translateObject(object, language, keysToOmit) {
  if (!object) return object;

  const { translations = {}, ...rest } = object;

  const translatedRest = mapValues(rest, (v, k) => {
    if (keysToOmit && keysToOmit.indexOf(k) !== -1) return v;
    return translateValue(v, language);
  });

  return {
    ...translatedRest,
    ...omitBy(translations[language] || {}, isEmptyValue),
  };
}

export function getLanguage(object, lang) {
  if (!lang || !object) return {};
  if (lang in object) return object[lang];
  if (lang.indexOf("-") !== -1) {
    // Search for parent language (ex : FR-fr)
    const [parent] = lang.split("-");
    return object[parent] || {};
  }
  return {};
}
