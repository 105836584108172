import React, { Component } from "react";
import { checkCorrect, checkIfInputAnswerIsCorrect, isValidInput } from "./utils";
import orderBy from "lodash/orderBy";
import sumBy from "lodash/sumBy";
import "./QuizResponse.scss";
import Strings from "../data/Strings";

const defaultStrings = Strings.client;

const InputAnswersTable = ({ answers = [], showResults = true }) => {
  const totalAnswers = sumBy(answers, "count");
  return (
    <table className="table">
      <tbody>
        {answers.map((ans, idx) => {
          const percentage =
            ans.percent || (totalAnswers ? Math.round(((ans.count || 0) * 1000) / totalAnswers) / 10 + "%" : "0%");
          return (
            <tr key={idx}>
              <td className="table__index">
                <div className={"masteranswer-label "}>{idx + 1}</div>
              </td>
              <td className="table__value" style={{ position: "relative", paddingBottom: 22, textAlign: "left" }}>
                <div className="table__count" style={{ float: "right" }}>
                  {percentage}
                </div>
                <span className="table__text">{ans.text}</span>
                <div className={"answer answer-" + (idx + 1)} style={{ minWidth: showResults ? percentage : 0 }} />
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default class QuizResponse extends Component {
  renderCorrectAnswer() {
    const { quiz } = this.props;
    const { strings } = quiz;
    return (
      <div className="quiz__answers__text" style={{ fontSize: "1.2em", color: "#16ab39", fontWeight: "bold" }}>
        <i className="fa fa-check" style={{ marginRight: 5, color: "#16ab39" }} />{" "}
        {strings?.correct || defaultStrings.correct}
      </div>
    );
  }

  renderWrongAnswer() {
    const { quiz } = this.props;
    const { strings } = quiz;
    return (
      <div className="quiz__answers__text" style={{ fontSize: "1.2em", color: "#F44336", fontWeight: "bold" }}>
        <i className="fa fa-times" style={{ marginRight: 5, color: "#F44336" }} />{" "}
        {strings?.error || defaultStrings.error}
      </div>
    );
  }
  render() {
    const { state, question, answers, answer, quiz } = this.props;
    const { type, explication } = question;
    const { strings = {} } = quiz;
    switch (type) {
      case "multiple_choice":
      case "image_quiz": {
        const isCorrect = checkCorrect(question, answers);
        return (
          <div>
            {state === "SHOW_RESULTS" && (
              <div>
                {isCorrect ? this.renderCorrectAnswer() : this.renderWrongAnswer()}
                {!!explication && <p>{explication}</p>}
                {question.showAnswers && question.stats?.answers?.length > 0 && (
                  <div className="client-given-answers">
                    <h3>{strings?.givenAnswers || defaultStrings.givenAnswers}</h3>
                    <div>
                      <InputAnswersTable answers={question.stats?.answers} />
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        );
      }
      case "input": {
        const isCorrect = isValidInput(question, answer);
        const hasAnswer = !!question.answer.trim();
        return (
          <div>
            {state === "SHOW_RESULTS" && (
              <>
                {question.showAnswers && question.stats?.answers?.length > 0 && (
                  <div className="client-given-answers">
                    <h3>{strings?.givenAnswers || defaultStrings.givenAnswers}</h3>
                    <div>
                      <InputAnswersTable
                        answers={orderBy(question.stats?.answers, ["count", "text"], ["desc", "asc"])}
                      />
                    </div>
                  </div>
                )}
                <div>
                  {hasAnswer && (
                    <>
                      {isCorrect ? this.renderCorrectAnswer() : this.renderWrongAnswer()}
                      {!isCorrect && (
                        <div style={{ fontSize: "1.2em", color: "black", fontWeight: "bold" }}>
                          {strings?.correctAnswers || defaultStrings.correctAnswerIs} :<br />
                          <br />
                          <span style={{ color: "#16ab39", textTransform: "uppercase" }}>{question.answer}</span>
                        </div>
                      )}
                    </>
                  )}
                  {!!explication && <p>{explication}</p>}
                </div>
              </>
            )}
          </div>
        );
      }
      case "vote":
      default:
        return (
          <div>
            {state === "SHOW_RESULTS" && (
              <>
                {question.showAnswers && question.stats?.answers?.length > 0 && (
                  <div className="client-given-answers">
                    <h3>{strings?.givenAnswers || defaultStrings.givenAnswers}</h3>
                    <div>
                      <InputAnswersTable answers={question.stats?.answers} />
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
        );
    }
  }
}
