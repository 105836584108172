export async function fetchJson(url) {
  const res = await fetch(url);
  return await res.json();
}

class QuizService {
  quizService = process.env.REACT_APP_QUIZ_SERVICE;

  async list(eventId) {
    return await fetchJson(`${this.quizService}/${eventId}/quiz?withDesign=true`);
  }

  async get(eventId, quizId) {
    return await fetchJson(`${this.quizService}/${eventId}/quiz/${quizId}?withDesign=true`);
  }
}

export default new QuizService();
