import React, { Component, PureComponent } from "react";
import firebase from "../Store";
import { eventId } from "../Store";
import Stats from "../Stats";
import { getParameterByName, uuid, hasGoodAnswers } from "../utils";

import keyBy from "lodash/keyBy";
import throttle from "lodash/throttle";
import findIndex from "lodash/findIndex";
import QuizList from "./QuizList";
import Sidebar from "./Sidebar";
import Navbar from "./Navbar";
import partition from "lodash/partition";
import { computeResults, computeUserResults, MODE } from "./utils";
import moment from "moment";

import i18n from "i18next";
import QuizService from "../services/QuizService";
import BlockExit from "../components/BlockExit";

const AUTOPLAY_QUESTION_TIME = getParameterNumber("questionTime", 30);
const AUTOPLAY_ANSWER_TIME = getParameterNumber("answerTime", 15);
const LEGACY_MULTIRESULTS = getParameterByName("legacyMultiResults");

const resources = {
  en: {
    translation: {
      "select-quiz": "Select a quiz",
      pin: "PIN:",
      "start-quiz": "Start the quiz",
      "restore-quiz": "Restore started quiz",
      "join-quiz": "Join quiz",
      question: "{{count}} question",
      question_plural: "{{count}} questions",
      autoplay: "Autoplay",
      resultViewer: "Result viewer",
      user: "{{count}} user",
      user_plural: "{{count}} users",
      "select-question": "Select a question",
      answer: "answer",
      average: "average",
      answer_plural: "answers",
      "good-answers": "Good answers",
      "wrong-answers": "Wrong answers",
      "the-fastest": "The fastest",
      "fastest-participant": "Fastest participant:",
      "show-results": "Show results",
      "hide-results": "hide results",
      Score: "Score",
    },
  },
  fr: {
    translation: {
      "select-quiz": "Sélectionnez un quiz",
      pin: "PIN :",
      "start-quiz": "Lancer le quiz",
      "restore-quiz": "Reprendre le quiz démarré",
      "join-quiz": "Rejoindre le quiz",
      question: "{{count}} question",
      question_plural: "{{count}} questions",
      autoplay: "Lecture automatique",
      resultViewer: "Resultats",
      user: "{{count}} utilisateur",
      user_plural: "{{count}} utilisateurs",
      "select-question": "Sélectionnez une question",
      answer: "réponse",
      average: "Moyenne",
      answer_plural: "réponses",
      "good-answers": "Bonne réponses",
      "wrong-answers": "Mauvaises réponses",
      "the-fastest": "Les plus rapides",
      "fastest-participant": "Participant le plus rapide :",
      "show-results": "Afficher les résultats",
      "hide-results": "hCacher les résultats",
      Score: "Score",
    },
  },
};
// initialize i18next with catalog and language to use
i18n.init({
  resources,
  fallbackLng: "en",
});

function checkNaN(number) {
  return number !== number;
}

function getParameterNumber(key, defaultValue) {
  const value = getParameterByName(key);
  if (!value) return defaultValue;
  try {
    const v = parseInt(value, 10);
    return checkNaN(v) ? defaultValue : v;
  } catch (e) {
    return defaultValue;
  }
}

// const letters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
let nextAutoplayId = 1;

function generatePin() {
  let p = "" + Math.floor(Math.random() * 1000000);
  while (p.length < 6) p = "0" + p;
  return p;
}

function getSize(results, key) {
  return results[key] ? results[key].percent : 0;
}

function isQuestion(question) {
  const { type } = question;
  return (
    type === "multiple_choice" ||
    type === "image_quiz" ||
    type === "image_vote" ||
    type === "multiple_texts" ||
    type === "scale" ||
    type === "vote" ||
    type === "input" ||
    type === "word_cloud" ||
    type === "drag_and_drop"
  );
}

class Header extends Component {
  render() {
    return (
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
        <h1 className="h2">{this.props.children}</h1>
      </div>
    );
  }
}

export class StatValue extends PureComponent {
  render() {
    const { label, value, ...props } = this.props;
    return (
      <div className="c-stat" {...props}>
        <div className="c-stat__label">{label}</div>
        <div className="c-stat__value">{value}</div>
      </div>
    );
  }
}

class ActionBar extends Component {
  render() {
    const {
      mode,
      question,
      answerCount = 0,
      fastestAnswer,
      onShowResults,
      onHideResults,
      stats = {},
      hasAnswer = true,
    } = this.props;
    const { correct, average } = stats;
    return (
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center mb-3">
        <div className="btn-toolbar mb-md-0 justify-content-between" style={{ flex: 1 }}>
          <div className="btn-group">
            <StatValue label={i18n.t("answer", { count: answerCount })} value={answerCount} />
            {question.type === "scale" && <StatValue label={i18n.t("average")} value={average} />}
            {question && hasGoodAnswers(question.type) && answerCount > 0 && (
              <StatValue
                label={i18n.t("good-answers")}
                value={`${correct} / ${Math.round((correct * 100) / answerCount) + "%"}`}
                style={{ color: "#28a745" }}
              />
            )}
            {fastestAnswer && (
              <button disabled className="btn btn-sm">
                {i18n.t("fastest-participant")} {fastestAnswer}
              </button>
            )}
          </div>
          {hasAnswer && mode === MODE.MASTER && (
            <div className="btn-group">
              {onShowResults && (
                <button className="btn btn-primary" onClick={onShowResults}>
                  {i18n.t("show-results")}
                </button>
              )}
              {onHideResults && (
                <button className="btn btn-primary" onClick={onHideResults}>
                  {i18n.t("hide-results")}
                </button>
              )}
            </div>
          )}
        </div>
      </div>
    );
  }
}

class CloudWords extends Component {
  render() {
    const { question, answers, wordsToHide, onToggleWord } = this.props;
    const stats = computeResults(question, answers);

    // Check in list
    const [validWords, hiddenWords] = partition(stats.answers, (answer) => wordsToHide.indexOf(answer.text) === -1);

    return (
      <div>
        {validWords.map((ans, index) => (
          <button disabled key={index} style={{ margin: 5 }} type="button" className="btn btn-primary">
            {ans.text} <span className="badge badge-light">{ans.count}</span>&nbsp;&nbsp;
            <span className="fa fa-trash" onClick={() => onToggleWord(ans.text)} style={{ cursor: "pointer" }} />
          </button>
        ))}
        {hiddenWords.map((ans, index) => (
          <button key={index} style={{ margin: 5 }} type="button" className="btn btn-light" style={{ opacity: 0.5 }}>
            {ans.text} <span className="badge badge-light">{ans.count}</span>&nbsp;&nbsp;
            <span className="fa fa-recycle" onClick={() => onToggleWord(ans.text)} style={{ cursor: "pointer" }} />
          </button>
        ))}
      </div>
    );
  }
}

class ScaleResults extends Component {
  render() {
    const { question, answers } = this.props;
    const { answerType = "single" } = question;
    const stats = computeResults(question, answers);
    const isSingle = answerType === "single";
    return (
      <div className="table-responsive">
        <table className="table" style={{ marginBottom: 0 }}>
          <thead>
            <tr style={{ fontWeight: "bold", textTransform: "uppercase", fontSize: "1.5em", color: "#127bff" }}>
              <th>Valeur</th>
              {isSingle && <th>Nombre de réponses</th>}
              <th>Moyenne</th>
            </tr>
          </thead>
          <tbody>
            {stats.answers.map((ans, index) => (
              <tr key={index} style={{ fontSize: "1.2em" }}>
                <td>{ans.text}</td>
                {isSingle && <td>{ans.count}</td>}
                <td>{Math.round(ans.percent * 100) / 100} </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  }
}

class Question extends Component {
  render() {
    const { question, answers, results, showResults, stats } = this.props;
    const questionAnswers = question.type === "input" ? [question.answer] : question.answers;
    if (question.type === "scale") return null;
    return (
      <div>
        <div className="table-responsive">
          <table className="table" style={{ marginBottom: 0 }}>
            <tbody>
              {questionAnswers.map((ans, idx) => (
                <tr key={idx}>
                  <td style={{ width: 64, padding: 0, verticalAlign: "middle", paddingBottom: 12 }}>
                    <div className={"master-answer-label " + (showResults && ans.checked ? "active" : "")}>
                      {idx + 1}
                    </div>
                  </td>
                  <td style={{ position: "relative", paddingBottom: 22, fontSize: "1.5em" }}>
                    {typeof ans === "string" ? ans : ans.text}
                    <div
                      className={"answer answer-" + (idx + 1)}
                      style={{
                        minWidth: showResults && question.type !== "multiple-results" ? getSize(results, "" + idx) : 0,
                      }}
                    />
                  </td>
                  <td
                    style={{ textAlign: "right", width: "5%", verticalAlign: "bottom", paddingBottom: 0, fontSize: 25 }}
                  >
                    {answers.length > 0 ? stats.answers[idx].percent : ""}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div>
          {question.type === "input" &&
            answers &&
            answers.map((ans, index) => (
              <div
                key={index}
                style={{
                  display: "inline-block",
                  background: "#EEE",
                  border: "#DDD",
                  borderRadius: 4,
                  padding: "4px 8px",
                  fontSize: "1.5em",
                  margin: 4,
                }}
              >
                {ans.answer[0]}
              </div>
            ))}
        </div>
      </div>
    );
  }
}

export default class Master extends Component {
  constructor(props) {
    super(props);

    this.state = {
      pin: getParameterByName("pin") || generatePin(),
      eventId: getParameterByName("eventId") || eventId,
      sessionId: null,
      quizList: null,
      quiz: null,
      currentQuestion: -1,
      currentAnswers: [],
      showResults: false,
      answers: [],
      users: {},
      quizAnswers: {},
      wordsToHide: [],
      autoplay: false,
    };
  }

  async componentDidMount() {
    const { eventId, pin } = this.state;
    // init database
    this.database = firebase.firestore();
    // this.db = this.database.collection("quiz").collection("events").doc(eventId);

    // fetch quiz data from BO
    const quizList = await QuizService.list(eventId);
    const participants = []; //await fetchJson(`https://app.appcraft.events/data/${eventId}/participants.json`)
    const staff = []; //await fetchJson(`https://app.appcraft.events/data/${eventId}/staff.json`)

    this.setState({
      quizList,
      users: {},
      // users: keyBy([...participants, ...staff], "_id"),
    });

    this.checkForActiveSession();

    // const self = this;
  }

  checkForActiveSession = () => {
    const { pin } = this.state;
    if (pin) {
      this.database
        .collection("quiz-pin")
        .doc(pin)
        .onSnapshot(async (doc) => {
          if (doc.exists) {
            const { sessionId, eventId } = doc.data();
            if (eventId === this.state.eventId) {
              this.setState({
                restorableSession: await this.getRestorableSession(eventId, sessionId),
              });
              return;
            }
          }
          this.setState({ restorableSession: null });
        });
    }
    // this.setState({ sessionId, eventId, users: {}, quizAnswers: {} });
  };

  getRestorableSession = async (eventId, sessionId) => {
    const sessionInfo = await this.database.collection("quiz").doc(eventId).collection("sessions").doc(sessionId).get();
    if (sessionInfo && sessionInfo.exists) {
      const { quiz, currentQuestion, answers = {} } = sessionInfo.data();
      if (quiz) {
        return { sessionId, quizId: quiz.uuid, currentQuestion, answers };
      }
    }
    return null;
  };

  dbSession = () => {
    const { sessionId, eventId } = this.state;
    return this.database.collection("quiz").doc(eventId).collection("sessions").doc(sessionId);
  };

  initQuestionNumbers = (quiz) => {
    quiz.questions.forEach((question, index) => {
      question.number = this.computeQuestionIndex(quiz, question, index);
    });
  };

  refreshQuiz = async () => {
    const { eventId, sessionId, quiz } = this.state;

    const newQuiz = await QuizService.get(eventId, quiz.uuid);
    this.initQuestionNumbers(newQuiz);
    // Doesn't refresh current question :-)
    await this.database.collection("quiz").doc(eventId).collection("sessions").doc(sessionId).update({ quiz: newQuiz });
    this.setState({ quiz: newQuiz });
  };

  handleRestoreSession = async (e, quiz, restorableSession) => {
    const { mode } = this.props;
    const { eventId } = this.state;
    const { sessionId } = restorableSession;
    const latestRestorableSession = await this.getRestorableSession(eventId, sessionId);

    if (!latestRestorableSession) return; // WTF ?

    this.onRestoreSessionQuiz(quiz, sessionId, latestRestorableSession);

    if (mode === MODE.VIEWER) {
      // Listen to current session
      this.database
        .collection("quiz")
        .doc(eventId)
        .collection("sessions")
        .doc(sessionId)
        .onSnapshot(async (sessionInfo) => {
          if (sessionInfo && sessionInfo.exists) {
            const { quiz, currentQuestion, answers = {} } = sessionInfo.data();
            if (quiz) {
              this.initQuestionNumbers(quiz);
              const questionIndex = findIndex(quiz.questions, (q) => q.uuid === currentQuestion.uuid);
              this.answers = [];
              const prevIndex = this.state.currentQuestion;
              const prevQuestion = prevIndex >= 0 ? quiz.questions[prevIndex] : undefined;
              if (prevQuestion?.uuid !== currentQuestion?.uuid) {
                this.setState({
                  answers: [],
                  quiz,
                  showResults: currentQuestion.state === "SHOW_RESULTS",
                  currentQuestion: questionIndex,
                  autoplay: false,
                });
                this.listenToAnswers(questionIndex, currentQuestion);
              } else {
                // Same question, keep results
                const showResults = currentQuestion.state === "SHOW_RESULTS";
                if (showResults) {
                  const { stats } = await this.generateResults();
                  this.setState({ quiz, showResults: true, currentQuestion: questionIndex, autoplay: false, stats });
                }
              }
            }
          }
        });
      return;
    }
  };

  onRestoreSessionQuiz = (quiz, sessionId, sessionQuiz) => {
    const { eventId, currentQuestion: prevIndex } = this.state;

    const { currentQuestion = {} } = sessionQuiz;

    this.initQuestionNumbers(quiz);
    const questionIndex = findIndex(quiz.questions, (q) => q.uuid === currentQuestion.uuid);
    console.log("onRestoreSessionQuiz", currentQuestion, questionIndex);
    this.answers = [];
    const prevQuestion = prevIndex >= 0 ? quiz.questions[prevIndex] : undefined;
    this.setState({
      sessionId,
      eventId,
      users: {},
      quizAnswers: {},
      answers: [],
      quiz,
      showResults: currentQuestion.state === "SHOW_RESULTS",
      currentQuestion: questionIndex,
      autoplay: false,
    });
    if (currentQuestion?.uuid) {
      this.listenToAnswers(questionIndex, currentQuestion);
    }
  };

  selectQuiz = async (e, selectedQuiz) => {
    e.preventDefault();
    e.stopPropagation();

    const { eventId, pin } = this.state;

    // Refresh quiz before starting...
    const quiz = await QuizService.get(eventId, selectedQuiz.uuid);

    // Entering quiz, create new session...
    const sessionId = uuid();
    console.log("current session", sessionId);
    this.setState({ sessionId, eventId, users: {}, quizAnswers: {} });

    quiz.questions.forEach((question, index) => {
      question.number = this.computeQuestionIndex(quiz, question, index);
    });

    // Initialize quiz
    this.database
      .collection("quiz")
      .doc(eventId)
      .collection("sessions")
      .doc(sessionId)
      .set({
        quiz,
        dateTime: new Date(),
      })
      .then(() => {
        this.database.collection("quiz-pin").doc(pin).set({
          pin,
          eventId,
          sessionId,
          connected: 0,
        }); // TODO : when should this be flushed ?
        this.setState({
          quiz,
          currentQuestion: -1,
          answers: {},
          autoplay: false,
        });
      });

    // Set pin info

    // let questions = {};
    // quiz.questions.forEach(question => {
    //   questions[question.uuid] = { question, answers: [], results: {} };
    // });
    // this.db.set({ sessionId });
    // this.db
    //   .collection(pin)
    //   .doc(sessionId)
    //   .set({
    //     currentQuestion: -1,
    //     questionNumber: -1,
    //     questions: questions // TODO : attention, ça dump les réponses aussi
    //   });
    // // this.socket.emit("setQuizId", { quizId: quiz.uuid });
  };

  updateAutoPlay = (autoplayId) => {
    const { autoplay, currentQuestion, showResults, quiz } = this.state;

    // Stopped or session number changed ?
    if (!autoplay || autoplay !== autoplayId) return; // Finished !!

    if (showResults || currentQuestion === -1) {
      // Time to go to the next question
      if (currentQuestion === quiz.questions.length - 1) {
        this.exit(); // Last question, finished !
      } else {
        const idx = currentQuestion + 1;
        this.selectQuestion(quiz.questions[idx], idx);
        setTimeout(() => this.updateAutoPlay(autoplayId), AUTOPLAY_QUESTION_TIME * 1000);
      }
    } else {
      // Time to show results
      this.handleShowResults();
      setTimeout(() => this.updateAutoPlay(autoplayId), AUTOPLAY_ANSWER_TIME * 1000);
    }
  };

  toggleAutoPlay = () => {
    if (this.state.autoplay) {
      // Stop
      this.setState({
        autoplay: false,
      });
    } else {
      const autoplay = nextAutoplayId++; // ++ to change id and force refresh
      this.setState({ autoplay }, () => this.updateAutoPlay(autoplay));
    }
  };

  computeQuestionIndex = (quiz, question, idx) => {
    let index = 0;
    if (isQuestion(question)) {
      for (let i = 0; i < idx; i++) {
        if (isQuestion(quiz.questions[i])) {
          index++;
        }
      }
      index++;
    }
    return index;
  };

  updateAnswers = throttle(
    (answers) => {
      this.setState({ answers });
    },
    500,
    { leading: true, trailing: true }
  );

  updateQuizAnswers = throttle(
    (quizAnswers) => {
      this.setState({ quizAnswers });
    },
    500,
    { leading: true, trailing: true }
  );

  selectQuestion = async (question, idx) => {
    if (this.props.mode === MODE.VIEWER) return; // Lock interaction in viewer mode

    const { sessionId, users } = this.state;
    const answers = [];
    this.answers = answers;
    this.setState({
      currentQuestion: idx,
      answers,
      showResults: false,
      fastestAnswer: null,
      wordsToHide: [],
    });

    const index = this.computeQuestionIndex(this.state.quiz, question, idx);

    if (question.type === "results") {
      const users = this.state.users;
      if (Object.keys(users).length === 0) {
        const ranking = await this.getRankingResults(sessionId);
        ranking.forEach((r) => (users[r.userId] = r));
      }
      const { scores, leaderboard } = computeUserResults(this.state.quiz, users, this.state.quizAnswers);

      this.setState({ scores, leaderboard });

      this.dbSession().update({
        currentQuestion: {
          index,
          ...question,
          scores,
          leaderboard,
          state: "ANSWERING",
        },
      });
    } else {
      this.dbSession().update({
        currentQuestion: {
          index,
          ...question,
          state: "ANSWERING",
        },
      });
    }

    this.listenToAnswers(idx, question);
  };

  listenToAnswers = (idx, question) => {
    const { mode } = this.props;

    if (this.unsubscribeAnswers) {
      this.unsubscribeAnswers();
      this.unsubscribeAnswers = null;
    }

    const updateWordCloud = throttle((stats) => {
      console.log("UPDATE FIRESTORE");
      this.dbSession().update({
        currentQuestion: {
          // index,
          ...question,
          stats,
          state: "ANSWERING",
          // state: "SHOW_RESULTS"
        },
      });
    }, 1500);

    // Listen to answer changes...
    this.unsubscribeAnswers = this.dbSession()
      .collection("answers")
      .doc(question.uuid)
      .collection("users")
      .onSnapshot((snapshot) => {
        if (idx !== this.state.currentQuestion) {
          console.warn("Ignoring answer, different question index");
          return;
        }
        snapshot.docChanges().forEach((change) => {
          // TODO : check performance...
          if (change.type === "added") {
            const answer = change.doc.data();
            if (!answer.userId) {
              answer.userId = change.doc.id; // Auto-inject userId
            }
            this.answers = [...this.answers, answer];

            // No real-time update, can be mutated
            // this.userAnswers.push({ userId: change.doc.id, answer: answer.answer });
            this.updateAnswers(this.answers);
            if (question.type === "word_cloud") {
              // TODO: throttle stats ? Move to other route ?
              const stats = computeResults(question, this.answers);

              if (mode === MODE.MASTER) {
                updateWordCloud(stats);
              }

              // TODO: throttle ?
              // this.dbSession().update({
              //   currentQuestion: {
              //     // index,
              //     ...question,
              //     stats,
              //     state: "ANSWERING"
              //     // state: "SHOW_RESULTS"
              //   }
              // });
            } else if (question.type === "form") {
              // Add to users
              const user = answer.answer[0] || {};
              // const { userId, id } = user;
              let userId = user.userId || user.id; // Fix client bug
              if (userId) {
                this.setState({ users: { ...this.state.users, [userId]: user } });
              } else {
                console.error("Missing user id for", answer);
              }
            } else {
              this.updateQuizAnswers({
                ...this.state.quizAnswers,
                [question.uuid]: this.answers,
              });
            }
          }
          if (mode === MODE.MASTER) {
            this.saveResults(question.uuid);
          }
        });
      });
  };

  autonomousSession = (day) => {
    const { eventId, quiz } = this.state;
    const quizId = quiz.uuid;
    return this.database
      .collection("autonomous-quiz")
      .doc(eventId)
      .collection("quiz")
      .doc(quizId)
      .collection("days")
      .doc(day);
  };

  updateData = (questions) => {
    this.dbSession().update({ questions });
  };

  getAutonomousUsers = async () => {
    // Look for question with user info...
    const { quiz } = this.state;
    for (const question of quiz.questions) {
      if (question.type === "form") {
        return new Promise((resolve) => {
          this.autonomousAnswersDb()
            .doc(question.uuid)
            .collection("users")
            .get()
            .then((snapshot) => {
              const users = snapshot.docs.map((d) => d.data().answer[0]);
              resolve(users);
            });
        });
      }
    }
    return [];
  };

  getRankingResults = async (sessionId) => {
    const snapshot = await this.dbSession(sessionId).collection("users").get();
    return snapshot.docs.map((d) => d.data());
  };

  getAutonomousAnswers = async (question) => {
    const snapshot = await this.autonomousAnswersDb().doc(question.uuid).collection("users").get();
    return snapshot.docs.map((d) => d.data());
  };

  getPinAnswers = async (question) => {
    // console.time("Question " + question.uuid);
    const snapshot = await this.pinAnswersDb().doc(question.uuid).collection("users").get();
    // console.timeEnd("Question " + question.uuid);
    return snapshot.docs.map((d) => d.data());
  };

  pinAnswersDb = () => {
    return this.dbSession().collection("answers");
  };

  autonomousAnswersDb = () => {
    const day = moment().format("YYYY-MM-DD");
    return this.autonomousSession(day).collection("answers");
  };

  /**
   * Save stats to firebase for restoring later on...
   */
  generateResults = async () => {
    const { quiz, currentQuestion, answers, wordsToHide } = this.state;
    const { isAutonomous } = quiz;
    const question = quiz.questions[currentQuestion];
    const index = this.computeQuestionIndex(quiz, question, currentQuestion);

    if (isAutonomous) {
      // TODO : check code
      const userQuery = this.getAutonomousUsers();
      const answersQuery = this.getAutonomousAnswers(question);
      const users = keyBy(await userQuery, "id");
      const answers = await answersQuery;

      const stats = computeResults(question, answers, users);
      return {
        currentQuestion: {
          index,
          ...question,
          stats,
          wordsToHide,
          state: "SHOW_RESULTS",
        },
        answers,
        users,
        stats,
      };
    } else {
      let stats = [];
      if (question.type === "multiple-results") {
        const questions = question.questions || [];
        // console.time("multiple-results");
        stats = await Promise.all(
          questions
            .map((q) => quiz.questions.find((s) => s.uuid === q.questionId))
            .filter((v) => v)
            .map(async (a) => {
              const answersQuery = await this.getPinAnswers(a);
              const questionStats = computeResults(a, answersQuery, {}, wordsToHide);
              return { ...questionStats, question: a, key: a.key || "" };
            })
        );
        // console.timeEnd("multiple-results");
      } else {
        stats = computeResults(question, answers, {}, wordsToHide);
      }

      return {
        currentQuestion: {
          index,
          ...question,
          stats,
          wordsToHide,
          state: "SHOW_RESULTS",
        },
        stats,
      };
    }
  };

  /**
   * Generate results every 2 seconds and save for later usage
   */
  saveResults = throttle(
    async (questionId) => {
      const { quiz, currentQuestion: index } = this.state;
      const question = quiz.questions[index];
      if (questionId !== question.uuid) {
        console.warn("Question has changed, don't save !", questionId, "vs", question.uuid);
        return;
      }

      const { currentQuestion } = await this.generateResults();
      this.dbSession().collection("results").doc(currentQuestion.uuid).set({ currentQuestion, quiz }, { merge: true });
    },
    2000,
    { leading: true, trailing: true }
  );

  handleShowResults = async (e) => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }
    const { isAutonomous } = this.state.quiz;
    const { currentQuestion, answers, users, stats } = await this.generateResults();

    this.dbSession().update({ currentQuestion });
    this.saveResults(currentQuestion.uuid);

    if (isAutonomous) {
      this.setState({
        showResults: true,
        answers,
        users,
        stats,
      });
    } else {
      this.setState({
        showResults: true,
        stats,
      });
    }
  };

  handleHideResults = (e) => {
    e.preventDefault();
    e.stopPropagation();
    this.setState({
      showResults: false,
    });
    // this.socket.emit("hideResults");
    const { quiz, currentQuestion } = this.state;
    const question = quiz.questions[currentQuestion];
    this.dbSession()
      .get()
      .then((doc) => {
        // TODO : utiliser directement le chemin vers "results" ?
        if (doc.exists) {
          const questions = doc.data().questions;
          questions[question.uuid].results = null;
          this.updateData(questions);
        }
      })
      .catch((error) => console.log("error getting document", error));
    this.setState({
      showResults: false,
    });
  };

  signOut = () => {
    if (window.confirm("Do you want to close this quiz ?")) {
      this.exit();
    }
  };

  exit = async (e) => {
    const { pin, eventId } = this.state;
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }
    console.warn("TODO: exit quiz...");
    await this.database
      .collection("quiz-pin")
      .doc(pin)
      .delete()
      .then(function () {
        console.log("Document successfully deleted!");
      })
      .catch(function (error) {
        console.error("Error removing document: ", error);
      });
    // this.socket.emit("closeQuestion");
    this.setState({ quiz: null });
  };

  getFields = () => {
    const { quiz } = this.state;
    for (const question of quiz.questions) {
      if (question.type === "form") return question.fields;
    }
    return [];
  };

  handleToggleWordToHide = (word) => {
    const { wordsToHide, quiz, currentQuestion } = this.state;
    const question = quiz && currentQuestion > -1 && quiz.questions[currentQuestion];
    if (!question) return;

    // Add or remove ?
    if (wordsToHide.indexOf(word) === -1) {
      this.setState({ wordsToHide: [...wordsToHide, word] }, () => this.saveResults(question.uuid));
    } else {
      this.setState({ wordsToHide: wordsToHide.filter((w) => w !== word) }, () => this.saveResults(question.uuid));
    }
  };

  render() {
    const { mode } = this.props;

    const {
      pin,
      quizList,
      quiz,
      currentQuestion,
      showResults,
      stats,
      answers,
      fastestAnswer,
      users,
      leaderboard,
      autoplay,
      eventId,
      sessionId,
      wordsToHide,
      restorableSession,
    } = this.state;

    if (!quizList) return null;

    if (!quiz) {
      return (
        <QuizList
          mode={mode}
          i18n={i18n}
          pin={pin}
          quizList={quizList}
          onSelectQuiz={this.selectQuiz}
          onRestoreSession={this.handleRestoreSession}
          restorableSession={restorableSession}
        />
      );
    }

    const question = quiz && currentQuestion > -1 && quiz.questions[currentQuestion];

    const tmpStats = question && computeResults(question, answers, users);

    const userCount = Object.keys(users).length;
    const hasActionBar =
      question && question.type !== "results" && question.type !== "title" && question.type !== "informations";

    // const currentAnswers = answers[question.uuid];
    // subtitle={`${userCount} utilisateurs connectés`}
    return (
      <div>
        <BlockExit />
        {mode === MODE.MASTER && (
          <Navbar i18n={i18n} title={quiz.title} pin={pin} onRefresh={this.refreshQuiz} onSignOut={this.signOut} />
        )}

        <div className="container-fluid">
          <div className="row">
            <Sidebar>
              {mode === MODE.MASTER && (
                <>
                  <button
                    className={"btn " + (autoplay ? "btn-danger" : "btn-outline-danger")}
                    onClick={this.toggleAutoPlay}
                  >
                    <i className={`fa fa-${autoplay ? "pause" : "play"}`} />
                    &nbsp;&nbsp;{i18n.t("autoplay")}
                  </button>{" "}
                  <a
                    href={`/results/${eventId}/session/${sessionId}`}
                    className={"btn btn-outline-warning"}
                    target="_blank"
                  >
                    <i className="fa fa-link" />
                    &nbsp;&nbsp;{i18n.t("resultViewer")}
                  </a>
                </>
              )}
              <Sidebar.Header
                // title={`${quiz.questions.length} questions` + (userCount ? ` - ${userCount} utilisateurs` : "")}
                title={`${i18n.t("question", { count: quiz.questions.length })} 
                ${userCount ? `- ${i18n.t("user", { count: userCount })}` : ""}
                `}
              />
              <Sidebar.Menu>
                {quiz.questions.map((question, idx) => (
                  <Sidebar.Item
                    key={idx}
                    title={
                      <span>
                        <span style={{ display: "inline-block", width: 24, textAlign: "right" }}>{idx + 1}.</span>{" "}
                        {question.number ? `Q${question.number} ` : ""}
                        {question.question}
                      </span>
                    }
                    onClick={() => this.selectQuestion(question, idx)}
                    active={idx === currentQuestion}
                  />
                ))}
              </Sidebar.Menu>
            </Sidebar>

            <main role="main" className="col-md-9 ml-sm-auto px-4">
              {!question && <Header>{i18n.t("select-question")}</Header>}

              {question && (
                <div>
                  <Header>
                    {!!question.number && `Q${question.number} `}
                    {question.question}
                  </Header>
                  {hasActionBar && (
                    <ActionBar
                      mode={mode}
                      question={question}
                      answerCount={(answers || []).length}
                      fastestAnswer={users[fastestAnswer] ? users[fastestAnswer].name : fastestAnswer}
                      onShowResults={!showResults && this.handleShowResults}
                      onHideResults={showResults && this.handleHideResults}
                      hasAnswer={question && question.type !== "form"}
                      stats={tmpStats}
                    />
                  )}
                  {question.type === "scale" && <ScaleResults answers={answers} question={question} />}
                  {(question.type === "multiple_texts" || question.type === "word_cloud") && (
                    <CloudWords
                      answers={answers}
                      question={question}
                      wordsToHide={wordsToHide}
                      onToggleWord={this.handleToggleWordToHide}
                    />
                  )}
                  {question.type === "form" && <RegisteredUsers answers={answers} question={question} />}
                  {question.type === "results" && <Leaderboard leaderboard={leaderboard} fields={this.getFields()} />}
                  {question.type !== "word_cloud" && question.type !== "form" && question.type !== "results" && (
                    <Question
                      question={question}
                      showResults={showResults}
                      answers={answers}
                      stats={tmpStats}
                      results={stats ? stats.answers : []}
                    />
                  )}
                  {showResults && question.type !== "vote" && (
                    <Stats i18n={i18n} type={question.type} question={question} {...stats} />
                  )}
                </div>
              )}
              <div />
            </main>
          </div>
        </div>
      </div>
    );
  }
}

Master.defaultProps = {
  mode: MODE.MASTER,
};

class RegisteredUsers extends Component {
  render() {
    const { question, answers = [] } = this.props;
    const { fields } = question;
    return (
      <table className="table" style={{ width: "100%" }}>
        <thead>
          <tr>
            <td style={{ maxWidth: 64, width: 64 }}>#</td>
            {fields.map((field) => (
              <td key={field.text}>{field.text}</td>
            ))}
          </tr>
        </thead>
        <tbody>
          {answers.map(({ answer }, index) => (
            <tr key={index}>
              <td>{index + 1}</td>
              {fields.map((field) => (
                <td key={field.text}>{answer[0][field.text]}</td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    );
  }
}
class Leaderboard extends Component {
  render() {
    const { leaderboard = [], fields } = this.props;
    return (
      <table className="table" style={{ width: "100%" }}>
        <thead>
          <tr>
            <td style={{ maxWidth: 64, width: 64 }}>#</td>
            <td style={{ maxWidth: 64, width: 64 }}>{i18n.t("Score")}</td>
            <td style={{ maxWidth: 64, width: 64 }}>{i18n.t("Participant")}</td>
            {fields.map((field) => (
              <td key={field.text}>{field.text}</td>
            ))}
          </tr>
        </thead>
        <tbody>
          {leaderboard.map((user, index) => {
            const { firstName, lastName, rank, score } = user;
            const participantName = firstName || lastName ? `${firstName} ${lastName}` : "Anonyme";
            return (
              <tr key={index}>
                <td>{user.rank}</td>
                <td>{user.score}</td>
                <td>{participantName}</td>
                {fields.map((field) => {
                  return <td key={field.text}>{user[field.text]}</td>;
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  }
}
