import React, { Component } from "react";
import chunk from "lodash/chunk";
import cx from "classnames";

const windowWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;

const Image = ({ type = "square", answer, imageSize, active, color }) => {
  const border = !active ? "3px solid #fff" : `3px solid ${color}`;
  return (
    <div
      style={{
        border,
        borderRadius: type === "circle" ? "50%" : "0%",
        boxShadow: "0 1px 5px rgba(0, 0, 0, 0.46)",
        maxWidth: "auto"
      }}
      className={cx("quiz__vote__image--border", { active })}
    >
      <div
        className="quiz__vote__image--image"
        style={{
          margin: "auto",
          height: imageSize,
          width: imageSize,
          backgroundImage: `url(${(answer.image || {}).uri})`,
          backgroundPosition: "center",
          backgroundSize: "cover",
          borderRadius: type === "circle" ? "50%" : "0%"
        }}
      />
    </div>
  );
};

export default class ImageVote extends Component {
  render() {
    const { question, answers, onClickAnswer, primaryColor = "black" } = this.props;
    const { imageType, clientImagesCount = 3 } = question;
    const answers_group = chunk(question.answers, clientImagesCount);
    const imageSize = (windowWidth - 60 * clientImagesCount) / clientImagesCount;
    return (
      <div className="quiz__vote__image">
        {answers_group.map((group, id) => (
          <div key={id} className="quiz__vote__image--group__container">
            {group.map((ans, idx) => {
              const isActive = answers[id * clientImagesCount + idx];
              const { disabled = false } = ans;
              return (
                <div
                  key={idx}
                  className="quiz__vote__image--container"
                  onClick={disabled ? undefined : onClickAnswer(id * clientImagesCount + idx)}
                >
                  <Image answer={ans} type={imageType} imageSize={imageSize} active={isActive} color={primaryColor} />
                  <p style={{ marginBottom: 0, marginTop: 15, fontWeight: "bold" }} className="quiz__vote__image--text">
                    {ans.text}
                  </p>
                </div>
              );
            })}
          </div>
        ))}
      </div>
    );
  }
}
