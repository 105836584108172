import React, { PureComponent } from "react";
import cx from "classnames";
import SlaveLogo from "./SlaveLogo";
import { Pin } from "../../screens/SlaveScreen";

export default class SlaveSlide extends PureComponent {
  render() {
    const { className, title, logo, question, template = "default", number, children, pin } = this.props;
    const hasLongQuestion = question.question.length > 50;
    return (
      <div
        className={cx(
          "slave-container",
          "slave-container--" + template,
          "slave-container--" + question.type,
          className
        )}
      >
        <Pin pin={pin} />
        {logo && <SlaveLogo src={logo} style={{ maxWidth: 200, position: "absolute", bottom: 10, right: 10 }} />}
        <h1 style={{ color: "black" }}>{pin}</h1>
        {template === "stand" && <h1 className="quiz-title">{title}</h1>}
        <div className="question-container">
          {template === "default" && (
            <div className="question-number-container">
              <p className="question-number">{number ? number : ""}</p>
            </div>
          )}

          <div style={{ display: "flex" }}>
            {template === "stand" && <i className="fa fa-chevron-right" />}
            <h1 className="question-text" style={{ fontSize: hasLongQuestion ? "5.5vh" : "6.5vh" }}>
              {question.question}
            </h1>
            {question.subtitle && <div style={{ fontSize: "4vh", color: "#999" }}>{question.subtitle}</div>}
          </div>
          {children}
        </div>
      </div>
    );
  }
}
