import { ScalableBloomFilter } from "bloom-filters";

export function getParameterByName(name, url) {
  if (!url) url = window.location.href;
  name = name.replace(/[[\]]/g, "\\$&");
  var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
    results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return "";
  return decodeURIComponent(results[2].replace(/\+/g, " "));
}

export function uuidv4() {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c === "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

const characters = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";

export function uuid() {
  var str = "";
  for (var i = 0; i < 10; i++) {
    str += characters[(Math.random() * characters.length) | 0];
  }
  return str;
}

export function hasGoodAnswers(type) {
  return (
    type !== "vote" &&
    type !== "form" &&
    type !== "results" &&
    type !== "word_cloud" &&
    type !== "scale" &&
    type !== "multiple-results" &&
    type !== "image_vote" &&
    type !== "multiple_texts"
  );
}

// const filter = new ScalableBloomFilter(2000, 0.01);
// for (const id of ids) {
//   filter.add(id);
// }
const filter = ScalableBloomFilter.fromJSON({
  type: "ScalableBloomFilter",
  _initial_size: 2000,
  _error_rate: 0.01,
  _ratio: 0.5,
  _filters: [
    {
      type: "PartitionedBloomFilter",
      _size: 19171,
      _nbHashes: 7,
      _loadFactor: 0.5,
      _m: 2739,
      _filter: [
        {
          size: 2744,
          content:
            "1Zh3NalQEc7vH4B5hUbdzZLkrXCtKdReuGD5BLxFg/MSdtLNBVRxK4X1FysnRSZHQz0lFdH5iH9alnN5twSaGVQIaVHJB7dI2iivQp4cfuy4CYosWK4DyQDNAgYS/p75phvKU5FE0aAM2B2TQB3UqBiXjHlTbxTrBA7Gdi0ZzkTUXHSsbLMOBtpT+L8p3T+H/8sFM5xl1cnhDxe/aNZfMRjOKJ9UILa7/KefPC0ZEAsT1C3xgp5dFqbwiArqreVDwc843wnDfDfCa0Qj+gdTgLysrHqzrqjTTILrXpAMe9iYpYDEbBm/f/r1agkeZI9S3C1zp6JZJESX4l4vAic57v71zQf8lrsfiqyQCE8aguRy2wpXaHoHWVzA7mQQHjxGMwVL2NOmm/b1HUUTy4fewRQqQ6wHCEVEkGJWEbOFYiLEfNy0cu9J4apkMy+398urXOIQ2a7FBQ==",
        },
        {
          size: 2744,
          content:
            "/8PlEykuRjPoimqRkzMvwVhtG6yGbM8bC5aZxw4u3rCsrdxKS34f7Ol5upn/EILSxpuace8z6lTRaluaQTLvEt9SRlgLDBGZ1ow5bJ3yc/qeXHOamdOSpESg/47v1KpG/zBrYovgyyGQSQikQsCN18CY63QwX1xlX+YLEghCnUH1kPeDUtdPajmAX8q4B22JsQe4nrc92mYn2JROnWR4CymysFw/yM+cRLz4WlHnVxhLfKFl5wQLauj8aUKkcPn47MqEh78UehxKq7AuEgTnMB9qoOBLGsoDLsSfSdDZDTAMLgbB3OT/EtySRQgoc/A207wD39SF9vs8pFTm6+xXJp3HVFdRZdskIo87W2PkHRReR+5Sj1vSDv/wwG2ubyxg7DO+onSU1Sc66AhZSZWyRDl74DCPHxJqeE0wJ4EQ7PRmIXhf+m5dSVPUjB4a/OTtL9fLEb5pBA==",
        },
        {
          size: 2744,
          content:
            "yMkXyTzcwAIGBQRwVf2349YIQtXwtZcEfa88OXlNIkc5OZkThqv9HCp20KgZxHEJgfVBGOPCLpqKsMXIn8WeqZS9TolDjYtQ3sPT5VblHtcVRYO30XTsIcmWeO4xgu6a1/kap0+QpDyxkoWnPmmDGoZOt9CqQQjSNIRCWh5h/A7gXKJnrmpWRghgxshc7sTaR2Fx841leD5vh4jZsepSikEYZ05vss+kr7uyAOZD9e3x960YvouiBw7PMFYzr0d0wgOM5o0Y04rPWP2jGkNsVjGA6gBmXRWbvg6MNUxCYqhPlV4uyhwXYrcEm98A6aIfhaLpc2291WjCM7aOi5jmGg/ZBlfGLxW0YAW0WPJuAndqzO9U6PR2dHCe4zc1RzBZxmYa44VafhgjFGVvbvYbiedj12UpoDMjpEDohyGIQk2/AFuvd5kopmMpmOnsqN53G01JDyMZBA==",
        },
        {
          size: 2744,
          content:
            "p+aADHyLl30ABzE+hO1eNd4UjF75ZIzdDsnFyQYDkeICa7vcIkPgApWCcrk5gCZ7ALRMcqLAJTRuvfpl7HoEkH+H/pqPkoANx49TvlaY0AZRF0TccgQ67raMon1cJlcSMUyIeH7efR2mCBs9zejMAcpKGbO/h/49CjP5Ry/kEmXidQNwoa6O7BngjcMJ1pV2GbooLXZSRQy8sbHlwinUj/KZmiAxeZ+XN2231Y+WjQKhudqJ4lAWb16WuIdG/FBKBgZEtMR/dZOdnsUBaVIeXnLXgCw5fWINlvsNm7zFHSEkPxQJ5IYRxN/TpxO2/s/eyUYcGHDai7sbwRB/kwGPqra65zzSCOBeyxdUUlnBSmO6by8MdO+xDphkO3CdrgQplorIqA3T2HZArsJqMXJMwn58XlWknZGDHvO6W3aZxZ+JtM4tXgeDNHvgVIfMPsHrb/i7rFXfAg==",
        },
        {
          size: 2744,
          content:
            "8CsJmZzrUcwpKkeQmU1OUc8sVisQV/Zxslo8tqfXB4MNb6O3qVZ1/uxWes00qvF4YPJCVnH0G78vLJbk2YNnSiK+rLxdrZxRmimtTEt31Vg3RABUOKVMXipbIr0qckG2TACo7gx+h7/Y3Y4r7lw9o4wFSlvq8zlRuNxIeULxRcnmQ36ti/WDgKMmJruVkh+HXQub8yzDicbGk7p5dVu/AaLjtBoaNlMKToDKiyO4zzTZtd9nLtgb4IDXr2x0ijt3aP0T74kU9htN2sVj+96TeqtnaDoeuuVFuq3F+idgSBG9UR3hdJHRnvIxlQw2wJe/QYAc7apxAfTz4ImOXagoHNwEk8/pmTJ3fhYVVtxNPmv7OuaM1qPWgPtKQ+QOUD6pmVylcoBVgtSJy0cmBt48F1lv3fzzAg2OzgTO+fQOz+HClHZB5A2bYTJ7YcJmhsbKPGoprNMBBg==",
        },
        {
          size: 2744,
          content:
            "BIRZvMECsWw6w20GilQnPhvmcmLxzMd2JI1t8Ss2LRGHUfGAdVI5rcC7mOGMi5YEhhp1I0idL+Kq5E/1C9Kwh2gOBX9rff26ZIo3eu08W019+1pUK8Q84NEnbC6RExOXndbKABHfn3ixiT/ITa7YnQnpCgKszsBUm/bgPZ40/MfQdzjCQdNoeE8qDdqtXwI5kFwK1k9xT6GajkUKiYT3gP+eKuRvxCtwU7hcIPSf3z5KbNqcYX1UwPzCcemGNWiqgmuDcGlDLELXOs1HVIg8oiw1EzzAu3fKNtNCTIRtRnb+EHZg0kfaxTB70JaEQHRGR+07oMmK0eBaQP9gGbAHLvJ2ZIB3yAL/ppT2LU3qCYz1QzmU8ClPK4RzRHywUps1NVWL5HExyMUYzSZiYz11uFn87L2uf0H2K8JGQ14tK6Bq6udPd01gQ7Ra4p05N/IOjXfWy1QCBg==",
        },
        {
          size: 2744,
          content:
            "8U04SQtQnkRAbIqjw4JeC0nt6CjLBQaGbrOSzkjmKnlP6zqOepeHxNZ8SaEfKCYDSV++WshF5sK0uNKS+5LFHjrjBCyKh1cPUKBTkCQNVH+oS5D1+qnsKE/DdQN2Cb1aUkFBuGduHBBVh6B16wqSdD/O0ZrlsIPzC4qH98nRWTAq8BVdnHpxmnVZbOUwLWdVQ6nNoYTk2ANCr8JUsRNETcRrkkB5DTp5ZpPBJcqo+ONHCYTL8N9uHVG/9MFqURmKjYWNfJYRb013bvgWXy72/dkcda3iI8LjwVQu6jgkepz2g126jJJr6IBy/tfXQBoUJCJ/VPvUiiSJec98tCMeFzZd18HJg9WwAY6Y9lINpPALaGsRhowqPmNbbhpzuhztYOx/PmZlCh8Ay88TVc3vsQlSorr1Wc84qN/bgkNrnOak9tunXfLdSBkenc6XZ05autz+tyjaAg==",
        },
      ],
      _capacity: 2000,
      _seed: 78187493520,
    },
  ],
  _seed: 78187493520,
});

// for (const id of ids) {
//   console.log(filter.has(id));
// }
// const res = filter.saveAsJSON();
// console.log("res", res, JSON.stringify(res));

export function checkOrange(quiz) {
  const eventId = quiz?.eventId || getParameterByName("eventId");
  if (eventId === "6SGA9Jpy55H67Q" || quiz?.uuid === "zVABwEf2ZXdS") {
    // Validate userId...
    const userId = getParameterByName("userId") || getParameterByName("_id");
    if (!userId) {
      console.log("Missing userId");
      return false;
    }
    if (!filter.has(userId)) {
      console.log("Invalid userId");
      return false;
    }
    return true;
  }
  return true;
}
