import mapValues from "lodash/mapValues";
import merge from "lodash/merge";
import pickBy from "lodash/pickBy";
import memoizeOne from "memoize-one";
import de from "../translations/de.json";
import en from "../translations/en.json";
import es from "../translations/es.json";
import fr from "../translations/fr.json";
import it from "../translations/it.json";
import pt from "../translations/pt.json";
import { getParameterByName } from "../utils";

const defaultStrings = {
  fr,
  en,
  it,
  es,
  pt,
  de,
};

export { en, fr };

const language = getParameterByName("lang") || "fr";
export const lang = language in defaultStrings ? language : "en";
export const forcedLanguage = getParameterByName("lang") || "fr";

export default defaultStrings[lang];

function removeEmptyStrings(object) {
  if (!object) return null;

  return pickBy(
    mapValues(object, (v) => {
      if (typeof v === "object") return removeEmptyStrings(v);
      return v;
    }),
    (v) => v
  );
}

export const mergeStrings = memoizeOne((defaultStrings, overrideStrings) => {
  return merge({}, defaultStrings, removeEmptyStrings(overrideStrings));
});
