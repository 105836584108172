import React, { Component } from "react";
import { AutoSizer } from "react-virtualized/dist/commonjs/AutoSizer";
// import WordCloud from "react-d3-cloud";
import AnimatedWordCloud from "./AnimatedWordCloud";
import throttle from "lodash/throttle";
import max from "lodash/max";
import groupBy from "lodash/groupBy";
import countBy from "lodash/countBy";
import orderBy from "lodash/orderBy";

const MIN_SIZE = 50;
const MAX_SIZE = 150;

function autoSizeLimit(textLength) {
  if (textLength > 15) return 0.6;
  if (textLength > 11) return 0.8;
  return 1;
}

class WordCloudStats extends Component {
  render() {
    const { answers, answersWithExtra, words, question, activeColor } = this.props;
    const { extraQuestion, groupByExtra = false, limitWordCount } = question;

    let data = [];
    if (groupByExtra) {
      const groupedAnswers = groupBy(answersWithExtra, "extra");
      for (const g in groupedAnswers) {
        const answers = groupedAnswers[g];
        const textColor = (extraQuestion.answers.find((a) => a.key === g) || {}).color || "black";
        const answersCount = countBy(answers, "answer");
        for (const a in answersCount) {
          const color = textColor; //extra && extra !== "none" ? (extra === g ? textColor : "#ddd") : textColor;
          data.push({ text: a, value: answersCount[a], extra: g, color });
        }
      }
    } else {
      // console.log("words", words);
      // console.log("answers", answers);
      if (words) {
        data = Object.keys(words).map((word) => ({ text: word, value: words[word] }));
      } else {
        data = answers.map((a) => ({ text: a.text, value: a.count }));
      }
    }

    if (limitWordCount && data.length > limitWordCount) {
      data = orderBy(data, "value", "desc").slice(0, limitWordCount);
    }

    // console.log("data", data);
    // return null

    this.maxSize = max(data.map((w) => w.value)) || 1;
    const minSize = /*fontSizes?.min || */ MIN_SIZE;
    const maxSize = /*fontSizes?.max || */ MAX_SIZE;

    // console.log("maxSize", this.maxSize);
    const fontSizeMapper = (word) => {
      //console.log("size for", word, this.maxSize, 30 + 90 * (word.value / this.maxSize))
      const ratio = word.value / this.maxSize;
      const limit = autoSizeLimit(word.length);
      return limit * (minSize + (maxSize - minSize) * ratio ** 2);
    };
    // , marginBottom: '5vh'
    return (
      // <div style={{ width: "90vw", height: "80vh" }}>
      <div style={{ width: "100%", height: "100%" }}>
        <AutoSizer>
          {({ height, width }) => (
            <AnimatedWordCloud
              words={data}
              activeColor={activeColor}
              height={height}
              width={width}
              config={question.wordCloud}
              fontSizeMapper={fontSizeMapper}
            />
          )}
        </AutoSizer>
      </div>
    );
  }
}

function throttleRender(interval, ...throttleArgs) {
  if (typeof interval !== "number" && interval > 0) {
    throw new Error("[throttle] Interval (ms) parameter not received.");
  }

  return (Component) =>
    class extends React.Component {
      constructor(props) {
        super(props);

        this.state = props;
        this.updateStateWithProps = throttle(
          (props) => {
            // console.log("updateStateWithProps");
            this.setState(props || this.props);
            this.forceUpdate();
          },
          interval,
          ...throttleArgs
        );
      }

      componentWillReceiveProps(props) {
        this.updateStateWithProps(props);
      }
      shouldComponentUpdate() {
        return false;
      }
      render = () => {
        return <Component {...this.state} />;
      };
    };
}
export default throttleRender(500)(WordCloudStats);
