import { Component } from "react";

export default class BlockExit extends Component {
  componentDidMount() {
    window.addEventListener("beforeunload", this.handleUnload);
  }

  componentWillUnmount() {
    window.removeEventListener("beforeunload", this.handleUnload);
  }

  handleUnload(e) {
    e.preventDefault();
    return (e.returnValue = "Are you sure you want to close?");
  }

  render() {
    return null;
  }
}
