import React, { Component } from "react";
import SlaveScreen from "../screens/SlaveScreen";
import SlaveStyles from "../screens/SlaveStyles";
import { getParameterByName } from "../utils";
import sumBy from "lodash/sumBy";

async function fetchJson(url) {
  console.log("url", url);
  const res = await fetch(url);
  return await res.json();
}

const emptyStats = {
  answers: [],
};

const wordCloudStats = {
  answers: [
    { text: "Great !", count: 5 },
    { text: "Amazing", count: 4 },
    { text: "Good", count: 3 },
    { text: "Sooooooo good!", count: 2 },
    { text: "Diciamo bene", count: 1 },
    { text: "Awesome", count: 1 },
    { text: "Having a great time", count: 1 },
    { text: "Perfect !!!", count: 1 },
    { text: "Good job", count: 1 },
    { text: "😃", count: 4 },
    { text: "😊", count: 2 },
    { text: "🙂", count: 1 },
  ],
};

class PreviewQuiz extends Component {
  state = {
    quiz: null,
    state: "WAITING",
  };
  async componentDidMount() {
    const { appId, quizId, questionIndex } = this.props;

    const quiz = await fetchJson(`https://app.appcraft.events/quiz/${appId}/quiz/${quizId}?withDesign=true`);
    if (quiz) {
      const question = quiz.questions[questionIndex - 1];
      this.setState({ quiz, question, stats: emptyStats });
      if (question.type === "word_cloud") {
        setTimeout(() => this.setState({ stats: wordCloudStats }), 1000);
      } else if (getParameterByName("showResults") === "true") {
        const answers = question.answers.map((a, index) => ({
          text: a.text,
          count: 3 + index * 2,
        }));
        const totalAnswers = sumBy(answers, "count");
        this.setState({
          state: "SHOW_RESULTS",
          stats: {
            totalAnswers,
            correct: 0,
            wrong: 0,
            answers: answers.map((a) => ({
              ...a,
              percent: Math.round((a.count * 10000) / totalAnswers) / 100 + "%",
            })),
          },
        });
      }
    }
  }

  render() {
    const { questionIndex } = this.props;
    const { quiz, question, stats, state } = this.state;
    if (!quiz || !question) return null;
    console.log("quiz", quiz);
    return (
      <div>
        <SlaveStyles primaryColor={quiz.primaryColor} customCSS={quiz.customSlideCSS || quiz.customCSS} fonts={quiz.fonts}/>
        {question.type === "word_cloud" ? (
          <SlaveScreen {...quiz} question={question} state="SHOW_RESULTS" stats={stats} number={questionIndex} />
        ) : (
          <SlaveScreen {...quiz} question={question} state={state} stats={stats} number={questionIndex} />
        )}
      </div>
    );
  }
}

class Preview extends Component {
  render() {
    const { match } = this.props;
    console.log(match.params);
    const { appId, quizId, questionIndex } = match.params;

    return (
      <PreviewQuiz key={appId + "-" + quizId} appId={appId} quizId={quizId} questionIndex={parseInt(questionIndex)} />
    );
  }
}

export default Preview;
