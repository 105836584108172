import React from "react";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";

function renderMark(value, selected) {
  const isSelected = value === selected;
  return {
    style: {
      marginTop: -5,
      fontSize: 15,
      color: !isSelected ? "black" : "white",
      zIndex: isSelected ? 100 : 0,
      userSelect: "none"
    },
    label: <strong className="scale-mark-label">{value}</strong>
  };
}

function renderCustomMark(value, selected, mark) {
  const { style = {}, label = {} } = mark;
  const { topLabel, bottomLabel } = label;
  const isSelected = value === selected;
  return {
    style: {
      marginTop: -5,
      fontSize: 15,
      color: !isSelected ? "black" : "white",
      zIndex: isSelected ? 100 : 0,
      userSelect: "none",
      ...style
    },
    label: (
      <div className="scale-mark-label">
        <p
          style={{
            fontSize: 15,
            fontWeight: "bold",
            color: !isSelected ? "black" : "white",
            zIndex: isSelected ? 100 : 0
          }}
        >
          {topLabel}
        </p>
        <p className="bottom-label" style={{ fontWeight: "bold", color: "black" }}>
          {" "}
          {bottomLabel}
        </p>
      </div>
    )
  };
}

export default class RCSlider extends React.PureComponent {
  render() {
    const {
      disabled = false,
      min = 0,
      max = 10,
      step = 1,
      onChange,
      marks = {},
      trackStyle = {},
      railStyle = {},
      handleStyle = {},
      dotStyle = {},
      activeDotStyle = {},
      value
    } = this.props;
    const sliderMarks = {};
    for (let i = min; i <= max; i++) {
      if (marks[i]) {
        sliderMarks[i] = renderCustomMark(i, value, marks[i]);
      } else {
        sliderMarks[i] = renderMark(i, value);
      }
    }
    return (
      <div>
        <Slider
          min={min}
          max={max}
          step={step}
          value={value}
          marks={sliderMarks}
          disabled={disabled}
          onChange={onChange}
          trackStyle={trackStyle}
          railStyle={railStyle}
          handleStyle={handleStyle}
          dotStyle={dotStyle}
          activeDotStyle={activeDotStyle}
        />
      </div>
    );
  }
}
