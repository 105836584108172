import React from 'react';
import Font from '../components/Font/Font';

export default class SlaveStyles extends React.PureComponent {
  render() {
    const { primaryColor, customCSS = '', fonts } = this.props;
    // console.log("primaryColor", primaryColor);
    // console.log("customCSS", customCSS);
    return (
      <>
        {fonts && <Font type="body" font={fonts.body} />}

        <style
          dangerouslySetInnerHTML={{
            __html: `
/* Color styles */
.slave-container--default {
  background-color: ${primaryColor};
}

.slave-container--modern .topBorder {
  background-color: ${primaryColor};
}
.slave-container--modern .question-container__question--number {
  border-color: ${primaryColor};
  color: ${primaryColor};
}

.slave-container--modern.slave-container--multiple_choice .question-chevron,
.slave-container--modern.slave-container--multiple_choice .answer-text.correct-answer {
  color: ${primaryColor};
}


.slave-container--title,
.slave-container--form {
  background-color: ${primaryColor};
}

.slave-container--stand .fa.fa-chevron-right {
  color: ${primaryColor};
}

/* Custom CSS */
${customCSS}
`,
          }}
        />
      </>
    );
  }
}
