import React, { PureComponent } from "react";

export default class SlaveLogo extends PureComponent {
  render() {
    const { src, style = {} } = this.props;
    if (!src) return null;
    if (typeof src === "string") {
      return (
        <div className="slave-logo-container">
          <img src={src} style={style} alt="logo" className="slave-logo" />
        </div>
      );
    }
    if (src.uri)
      return (
        <div className="slave-logo-container">
          <img src={src.uri} style={style} alt="logo" className="slave-logo" />
        </div>
      );
    return null;
  }
}
