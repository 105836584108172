import firebase from "firebase/app";
import "firebase/firestore";
import { getParameterByName } from "./utils";
import WsFirebase from "./websockets/FirestoreWrapper/WsFirebase";
import startsWith from "lodash/startsWith";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
};

export const eventId = "HH9V9DF4nugBSp";

export const chinaCDN =
  getParameterByName("disableFirebase") === "true" || startsWith(window.location.pathname, "/quiz.appcraft.events");

let firebaseApp;
if (chinaCDN) {
  firebaseApp = new WsFirebase();
} else {
  firebaseApp = firebase.initializeApp(firebaseConfig);
}

export default firebaseApp;
