import WsFirestore from "./WsFirestore";

export default class WsFirebase {
  firestoreInstance = new WsFirestore();

  firestore() {
    console.log("Firebase via websockets !");
    return this.firestoreInstance;
  }
}
