import findIndex from "lodash/findIndex";
import orderBy from "lodash/orderBy";
import React, { Component } from "react";
import Slide from "./Slave";
import firebase from "./Store";

class SessionResultViewer extends Component {
  state = {
    questions: [],
    questionId: null,
  };

  componentDidMount() {
    const { appId, sessionId } = this.props;

    this.database = firebase.firestore();
    return this.database
      .collection("quiz")
      .doc(appId)
      .collection("sessions")
      .doc(sessionId)
      .collection("results")
      .onSnapshot((snapshot) => {
        this.setState({
          questions: orderBy(
            snapshot.docs.map((d) => {
              const { currentQuestion, quiz } = d.data();
              const index = findIndex(quiz.questions, (q) => q.uuid === currentQuestion.uuid);
              return {
                ...currentQuestion,
                index,
              };
            }),
            "index"
          ),
        });
      });
  }

  handleQuestionChange = (e) => {
    this.setState({ questionId: e.target.value });
  };

  getCurrentQuestion() {
    const { questions, questionId } = this.state;
    if (!questionId) return null;
    return questions.find((q) => q.uuid === questionId);
  }

  render() {
    const { appId, sessionId } = this.props;
    const { questionId } = this.state;
    const question = this.getCurrentQuestion();
    return (
      <React.Fragment>
        {questionId && !question && <div>Loading...</div>}
        {question && <Slide key={questionId} eventId={appId} sessionId={sessionId} forceQuestionId={questionId} />}
        {
          <div className={`result-viewer__card ${questionId ? "card--auto-hide" : ""}`}>
            Select question {this.renderSelect()}
          </div>
        }
      </React.Fragment>
    );
  }

  renderSelect() {
    const { questionId, questions } = this.state;
    return (
      <select onChange={this.handleQuestionChange} value={questionId}>
        <option value=""></option>
        {questions.map((q) => (
          <option value={q.uuid}>{`${q.index + 1}.${q.number ? " Q" + q.number : ""} ${q.question}`}</option>
        ))}
      </select>
    );
  }
}

class ResultViewer extends Component {
  render() {
    const { match } = this.props;
    const { appId, sessionId } = match.params;
    return <SessionResultViewer appId={appId} sessionId={sessionId} />;
  }
}

export default ResultViewer;
