import React, { Component } from "react";
import { MODE } from "./utils";

class QuizList extends Component {
  render() {
    const { i18n, pin, quizList, onSelectQuiz, onRestoreSession, restorableSession, mode } = this.props;

    const renderQuiz = (quiz, idx) => {
      if (mode === MODE.VIEWER) {
        return (
          <div key={idx} className="card" style={{ marginBottom: 8 }}>
            <div className="card-body">
              <h4 className="card-title">{quiz.title}</h4>
              <h6 className="card-subtitle mb-2 text-muted">{i18n.t("question", { count: quiz.questions.length })}</h6>
              <p className="card-text">{quiz.description}</p>

              {restorableSession && restorableSession.quizId === quiz.uuid && (
                <button
                  onClick={(e) => onRestoreSession(e, quiz, restorableSession)}
                  style={{ marginRight: 4, cursor: "pointer" }}
                  className="btn"
                >
                  {i18n.t("join-quiz")}
                </button>
              )}
            </div>
          </div>
        );
      }
      return (
        <div
          key={idx}
          className="card"
          style={{ cursor: "pointer", marginBottom: 8 }}
          onClick={() => this.setState({ quiz })}
        >
          <div className="card-body">
            <h4 className="card-title">{quiz.title}</h4>
            <h6 className="card-subtitle mb-2 text-muted">{i18n.t("question", { count: quiz.questions.length })}</h6>
            <p className="card-text">{quiz.description}</p>
            <button
              onClick={(e) => onSelectQuiz(e, quiz)}
              style={{ marginRight: 4, cursor: "pointer" }}
              className="btn btn-primary"
            >
              {i18n.t("start-quiz")}
            </button>
            {restorableSession && restorableSession.quizId === quiz.uuid && (
              <button
                onClick={(e) => onRestoreSession(e, quiz, restorableSession)}
                style={{ marginRight: 4, cursor: "pointer" }}
                className="btn"
              >
                {i18n.t("restore-quiz")}
              </button>
            )}
          </div>
        </div>
      );
    };

    return (
      <div className="container">
        <div style={{ position: "fixed", top: 8, right: 8, fontSize: 20 }}>
          {i18n.t("pin")} <code>{pin}</code>
        </div>
        <h1>{i18n.t("select-quiz")}</h1>
        {quizList.map(renderQuiz)}
      </div>
    );
  }
}

export default QuizList;
