import cx from "classnames";
import chunk from "lodash/chunk";
import chunck from "lodash/chunk";
import groupBy from "lodash/groupBy";
import keyBy from "lodash/keyBy";
import maxBy from "lodash/maxBy";
import orderBy from "lodash/orderBy";
import sumBy from "lodash/sumBy";
import React from "react";
import { CircleProgress } from "react-gradient-progress";
import {
  CartesianGrid,
  Label,
  Legend,
  PolarAngleAxis,
  PolarGrid,
  PolarRadiusAxis,
  Radar,
  RadarChart,
  Scatter,
  ScatterChart,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

import Podium from "../components/Podium";
import WordCloudStats from "../components/word-cloud/WordCloudStats";
import SlaveLogo from "../slave/components/SlaveLogo";
import SlaveQuestion from "../slave/components/SlaveQuestion";
import PieChart from "../slave/PieChart";
import Stats from "../Stats";
import BubbleChart from "./BubbleChart";

import "./slave.css";
import "./slave.scss";
import Strings from "../data/Strings";

const windowWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;

const defaultColors = ["#003f5c", "#2f4b7c", "#665191", "#a05195", "#d45087", "#f95d6a", "#ff7c43", "#ffa600"];

function getStat(stats, index, order) {
  if (!stats) return "";
  if (!stats.answers) return "";
  const stat = stats.answers[index];
  if (!stat) return "";
  return `(${stat.percent})`;
}

export const Pin = ({ pin }) =>
  pin ? (
    <div className="slave-pin">
      PIN: <code>{pin}</code>
    </div>
  ) : null;

export default class SlaveScreen extends React.Component {
  state = {
    selectedExtra: undefined,
  };
  filterByExtra = (e) => {
    this.setState({ selectedExtra: e.target.value });
  };

  getCloudActiveColor() {
    const { question } = this.props;
    const { selectedExtra } = this.state;
    if (!selectedExtra || !question.extraQuestion || !question.extraQuestion.answers) return undefined;

    const selected = question.extraQuestion.answers.find((a) => a.key === selectedExtra);
    return selected && selected.color;
  }

  render() {
    const {
      question,
      template = "default",
      showResults,
      primaryColor,
      logo,
      stats,
      state,
      number,
      words,
      pin,
      showPin,
      showFastest,
      totalAnswers,
      results = {},
      showUserCount = false,
    } = this.props;
    const total = stats ? stats.totalAnswers : totalAnswers;
    const { type, showQuestion, filterByExtra = false, extraQuestion } = question;
    const { showPieChart, pieChart = {} } = results;
    return (
      <div
        id={question.uuid}
        className={cx(
          "slave-container",
          "slave-container--" + template,
          "slave-container--" + question.type,
          "slave-container--" + state,
          {
            "slave-container--show-question": showQuestion,
          }
        )}
      >
        <div className="topBorder" />
        {logo && <SlaveLogo src={logo} style={{ maxWidth: 200, position: "absolute", bottom: 10, right: 10 }} />}
        {showPin && (
          <h1 style={{ color: "black" }}>
            PIN : <span style={{ color: primaryColor, fontWeight: "bold" }}>{pin}</span>
          </h1>
        )}
        {showUserCount && (
          <div
            className="responses-count"
            style={{
              border: `2px solid ${primaryColor}`,
              color: primaryColor,
              visibility: state === "SHOW_RESULTS" ? "visible" : "hidden",
            }}
          >
            <i className="fa fa-users" /> {total} {total === 1 ? Strings.answer : Strings.answers}
          </div>
        )}
        <div className={cx("question-container", `question-container--type-${type}`)}>
          {template === "default" && (
            <div className="question-number-container">
              <p className="question-number">{number ? number : ""}</p>
            </div>
          )}
          {<SlaveQuestion question={question} template={template} number={number} />}
          <div className="question-container__answers">
            {filterByExtra && extraQuestion && (
              <label style={{ display: "flex" }} className="extra--filter__label">
                <p style={{ margin: "0px 10px" }}>Trier par type de profil :</p>
                <select
                  name="extra-select"
                  id="extra--filter__select"
                  value={this.state.selectedExtra}
                  onChange={this.filterByExtra}
                >
                  <option value="none">Choix du profil</option>
                  {(extraQuestion.answers || []).map((a) => {
                    const { key, text, filterable = true } = a;
                    if (!filterable) return null;
                    return (
                      <option key={key} value={key}>
                        {text}
                      </option>
                    );
                  })}
                </select>
              </label>
            )}
            {(type === "image_vote" || type === "image_quiz") && <div>{this.renderImageVoteAnswers()}</div>}
            {type === "multiple_texts" && <div>{this.renderBubblesChart()}</div>}
            {type === "drag_and_drop" && <div>{this.renderScatterPlot()}</div>}
            {type === "scale" && this.renderScale()}
            {(type === "multiple_choice" || type === "vote") && this.renderMultipleChoiceAndVoteAnswers()}
            {type === "input" && !showResults && this.renderInputAnwser()}
            {type === "word_cloud" && (
              <div
                className="slave__wordcloud"
                style={{
                  display: "flex",
                  width: "100%",
                  height: "100%",
                  minWidth: "90vw",
                  minHeight: "70vh",
                }}
              >
                {(question.realTimeResults || state === "SHOW_RESULTS") && (
                  <WordCloudStats
                    answers={words}
                    {...stats}
                    question={question}
                    activeColor={this.getCloudActiveColor()}
                  />
                )}
                {extraQuestion && filterByExtra && (
                  <div className="slave__wordcloud__legend" style={{ width: 400, padding: 10, margin: 10 }}>
                    {question.extraQuestion.answers.map((q) => {
                      const { key, text, color } = q;
                      return (
                        <div key={key} style={{ display: "flex", alignItems: "center" }}>
                          <div
                            style={{
                              height: 25,
                              width: 25,
                              borderRadius: 4,
                              margin: "4px 10px",
                              backgroundColor: color,
                            }}
                          ></div>
                          <p className="legend-text" style={{ margin: 0 }}>
                            {text}
                          </p>
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
            )}
            <div className="result--container">
              {state === "SHOW_RESULTS" && showPieChart && stats && question && (
                <div className="result--container__pie--chart">
                  <PieChart stats={stats} question={question} primaryColor={primaryColor} {...pieChart} />
                </div>
              )}
            </div>
          </div>
        </div>
        {showResults === "true" && (
          <div style={{ margin: "20px auto" }}>
            {question && state === "SHOW_RESULTS" && question.answers.length <= 4 && (
              <Stats
                type={question.type}
                {...stats}
                question={question}
                isInline={false}
                resultStyle={{ margin: 0, fontSize: "1.2vw" }}
                showChart={showPieChart}
                showFastest={showFastest}
                primaryColor={primaryColor}
              />
            )}
          </div>
        )}
      </div>
    );
  }

  orderAnswers = () => {
    const { question, stats } = this.props;
    const { type, order } = question;

    if (stats && type === "vote" && order) {
      return orderBy(
        stats.answers.map((a, index) => ({ ...a, index })),
        "count",
        order
      );
    }
    return question.answers.map((a, index) => ({ ...a, index }));
  };

  renderOthersBlock = (stats) => {
    return (
      <React.Fragment>
        {stats && stats.others && stats.others.length > 0 && (
          <div className="other-answers-container" style={{ flex: 1 }}>
            <h3>Autres</h3>
            <div className="other-answers">
              {stats.others.map((text) => (
                <div key={text}>{text}</div>
              ))}
            </div>
          </div>
        )}
      </React.Fragment>
    );
  };

  renderAnswersWithCircularProgress = (stats, answers, isShowResults, circularProgressProps) => {
    const { responseAlign = "vertical", labelPosition = "bottom" } = circularProgressProps;
    const isVertical = responseAlign === "vertical";

    return (
      <React.Fragment>
        {answers.map((a) => {
          const { text, index, type } = a;
          if (type === "textarea") return null;
          return (
            <div
              key={index}
              className="circular-progress-container"
              style={{
                display: "flex",
                justifyContent: isVertical ? "flex-start" : "center",
                flexDirection: isVertical ? "row" : "column",
                alignItems: "center",
                flex: 1,
                margin: isVertical ? 0 : 15,
              }}
            >
              {!isVertical && labelPosition === "top" && <p className="circular-text">{text}</p>}
              {isShowResults && (
                <CircleProgress
                  secondaryColor="#e7e7e7"
                  {...circularProgressProps}
                  percentage={stats && stats.answers && Math.round(stats.answers[index].percent.split("%")[0])}
                />
              )}
              {(isVertical || (!isVertical && labelPosition === "bottom")) && (
                <p className="circular-text" style={{ flex: 1 }}>
                  {text}
                </p>
              )}
            </div>
          );
        })}
      </React.Fragment>
    );
  };
  renderMultipleChoiceAndVoteAnswers = () => {
    const { template, question, stats, state, strings = {}, results = {} } = this.props;
    const {
      answers,
      type,
      order,
      circularProgressResults = false,
      circularProgressProps = {},
      hasImage = false,
      image,
      imagePosition = "left",
    } = question;
    const { pieChart = {} } = results;
    const { colors = defaultColors } = pieChart;
    const hasLongAnswer = !!question.answers.find((ans) => ans.text.length > 55);
    const fontSize = hasLongAnswer ? "4vh" : "5vh";
    const answersOrdered = this.orderAnswers();
    const mostVotedCount = stats && type === "vote" ? maxBy(stats.answers, "count")?.count : -1;
    const withImage = hasImage && image && image.uri;
    const { slideGoodAnswer = " = Bonne réponse ! ", slideWrongAnswer = " = Mauvaise réponse" } = strings;
    const isShowResults = state === "SHOW_RESULTS";
    const { responseAlign = "vertical", answersOnXLines = 1 } = circularProgressProps;
    const isVertical = responseAlign === "vertical";
    if (circularProgressResults) {
      if (answersOnXLines > 1) {
        const groupedAnswers = chunck(
          answersOrdered.filter((a) => a.type !== "textarea"),
          Math.ceil(answers.filter((a) => a.type !== "textarea").length / answersOnXLines)
        );
        return (
          <div className="slave_multiple_choice_voice" style={{ display: "flex" }}>
            <div style={{ flex: 3 }}>
              {groupedAnswers.map((group) => {
                return (
                  <div style={{ display: "flex" }}>
                    {this.renderAnswersWithCircularProgress(stats, group, isShowResults, circularProgressProps)}
                  </div>
                );
              })}
            </div>
            {this.renderOthersBlock(stats)}
          </div>
        );
      }
      return (
        <div className="slave_multiple_choice_voice">
          <div style={{ display: isVertical ? "block" : "flex" }}>
            {this.renderAnswersWithCircularProgress(stats, answersOrdered, isShowResults, circularProgressProps)}
          </div>
          {this.renderOthersBlock(stats)}
        </div>
      );
    }
    return (
      <div style={{ display: withImage && imagePosition === "left" ? "flex" : "block" }}>
        {withImage && imagePosition === "top" && <img alt="quiz" src={image.uri} style={{ width: "100%" }} />}
        <ul className="answers-container" style={{ flex: 1, display: "table" }}>
          {answersOrdered.map((answer) => {
            const { text, checked, index, count } = answer;
            const isChecked = checked ? "correct-answer" : "wrong-answer";
            return (
              <li
                key={index}
                className={cx("answer-text", state === "SHOW_RESULTS" && type !== "vote" ? isChecked : "")}
                style={{ display: "table-row", fontSize }}
              >
                <div
                  className="number"
                  style={{
                    fontWeight: type === "vote" && count === mostVotedCount ? "bold" : "normal",
                  }}
                >
                  {index + 1}
                </div>
                <i
                  style={{ padding: "0px 10px", display: "table-cell" }}
                  className={cx(
                    "fa fa-chevron-right",
                    "question-chevron",
                    state === "SHOW_RESULTS" && type !== "vote" ? (checked ? "" : "chevron-wrong") : ""
                  )}
                />
                <div
                  className="text"
                  style={{
                    fontSize,
                    fontWeight: type === "vote" && count === mostVotedCount ? "bold" : "normal",
                  }}
                >
                  {text}
                  {template === "stand" && state === "SHOW_RESULTS" && type !== "vote" && (
                    <span className={checked ? "correct" : "wrong"}>
                      {checked ? slideGoodAnswer : slideWrongAnswer}
                    </span>
                  )}
                </div>
                {/* {(template !== "stand" || (template === "stand" && type === "vote")) && ( */}
                <div
                  className={cx(type === "vote" ? "correct-answer" : "", "question__percentage")}
                  style={{
                    opacity: stats ? 1 : 0,
                    fontWeight: type === "vote" && count === mostVotedCount ? "bold" : "normal",
                    color: type === "vote" ? colors[index] : undefined,
                  }}
                >
                  {getStat(stats, index, order)}
                </div>
                {/* )} */}
              </li>
            );
          })}
        </ul>
        {withImage && imagePosition === "left" && (
          <div style={{ flex: 1, marginLeft: 20 }}>
            <img alt="quiz" src={image.uri} style={{ width: "100%" }} />
          </div>
        )}
      </div>
    );
  };

  renderInputAnwser = () => {
    const { question, state, stats } = this.props;
    if (stats)
      return (
        <>
          {!!question.answer && (
            <div
              className="answer-text correct-answer"
              style={{
                display: state === "SHOW_RESULTS" ? "flex" : "none",
                fontSize: "5vh",
              }}
            >
              Réponse: <br /> {question.answer}
              {/* {getInputStat(stats, question.answer)} */}
            </div>
          )}
          {state === "SHOW_RESULTS" && !!stats?.answers.length && (
            <div className="answer-input__answers">
              {orderBy(stats?.answers, "count", "desc").map((ans) => (
                <div key={ans.text} className="answer-input__answer">
                  {ans.text} {ans.count > 1 && <span className="count">{ans.count}</span>}
                </div>
              ))}
            </div>
          )}
        </>
      );
  };

  renderScale = () => {
    const { question, stats, state, primaryColor } = this.props;
    if (state !== "SHOW_RESULTS") return null;
    const {
      min = 0,
      max = 10,
      answerType = "single",
      answers = [],
      groupByExtra = false,
      extraQuestion,
      showAverage = true,
      tickByAverage = false,
      strings = {},
    } = question;
    const { averageLabel = "Moyenne" } = strings;
    if (answerType === "single") {
      const { average } = stats;
      const maxAnswer = maxBy((stats && stats.answers) || [], "count");
      const maxCount = (maxAnswer && parseInt(maxAnswer.text, 10)) || min;
      const progressBars = [];
      const scales = [];
      for (let i = min; i <= max; i++) {
        const hasAnswer = stats && stats.answers.find((a) => parseInt(a.text, 10) === i);
        scales.push(i);
        if (hasAnswer) {
          progressBars.push({ label: i, percent: hasAnswer.percent });
        } else progressBars.push({ label: i, percent: 0 });
      }
      const position = average * (max - min);
      return (
        <div style={{ width: "100%" }} className="slave__scale__single">
          {showAverage && (
            <h2 className="slave__scale__single--average" style={{ color: primaryColor }}>
              {averageLabel}: {average}
            </h2>
          )}
          <div className="slave__scale__single--slider__container" style={{ position: "relative" }}>
            {tickByAverage && (
              <div
                className="slave__scale__single--slider__average"
                style={{
                  left: `calc(${position}% - 50px)`,
                  borderTop: `8px solid ${primaryColor}`,
                }}
              />
            )}
            {scales.map((index) => {
              const isMax = index === maxCount;

              return (
                <div
                  key={index}
                  className="slave__scale__single--slider__tick"
                  style={{ margin: !tickByAverage && isMax ? "-14px 0px" : 0 }}
                >
                  <div
                    className={!tickByAverage && isMax ? "active" : ""}
                    style={{
                      borderTop: !tickByAverage && isMax ? `8px solid ${primaryColor}` : "none",
                    }}
                  >
                    {index}
                  </div>
                </div>
              );
            })}
          </div>
          <div className="slave__scale__single--progressbars__container">
            {progressBars.map((p, index) => {
              return (
                <div key={index} style={{ flex: 1, textAlign: "center" }}>
                  <div className="slave__scale__single--progressbars__progressbar">
                    <div
                      className="slave__scale__single--progressbars__progressbar__percent"
                      style={{ height: p.percent * 2 }}
                    ></div>
                  </div>
                  <p className="slave__scale__single--progressbars__progressbar__text">{Math.round(p.percent)} %</p>
                </div>
              );
            })}
          </div>
        </div>
      );
    } else if (answerType === "multiple") {
      if (!groupByExtra) {
        return (
          <div>
            {answers.map((item, index) => {
              const { text, icon } = item;
              const percent = (stats && stats.answers && stats.answers[index].percent) || 0;
              return (
                <div style={{ display: "flex", margin: 30, alignItems: "center" }} className="scale__container">
                  {icon && (
                    <img
                      alt="icon"
                      src={icon.uri}
                      style={{ width: 40, height: 40, marginRight: 15 }}
                      className="scale__icon"
                    />
                  )}
                  <h3
                    style={{ width: "25vw", fontWeight: "bold", margin: 0, textAlign: "left" }}
                    className="scale__text"
                  >
                    {text}
                  </h3>
                  <div
                    style={{
                      position: "relative",
                      width: "60vw",
                      height: 20,
                      borderRadius: 4,
                      boxShadow: "0 1px 3px 0 #999 inset",
                      backgroundColor: "white",
                    }}
                  >
                    <div
                      style={{
                        width: `calc(100% * ${percent / 100})`,
                        position: "absolute",
                        top: 0,
                        bottom: 0,
                        left: 0,
                        background: "red",
                        borderRadius: 4,
                        color: "white",
                        textAlign: "right",
                        padding: "0px 10px",
                      }}
                    >
                      {percent}%
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        );
      } else if (groupByExtra) {
        const allResults =
          stats &&
          stats.answersWithExtra.length > 0 &&
          stats.answersWithExtra.map((a) => ({ ...a.answer, extra: a.extra }));

        const answersWithMultipleExtra = allResults.filter((a) => (a.extra || []).length > 1);
        const answersWithSingleExtra = allResults.filter((a) => (a.extra || []).length <= 1);
        const groupedAnswersByExtra = groupBy(answersWithSingleExtra, "extra");

        const data = extraQuestion.answers
          .filter((a) => a.key !== "orano")
          .map((q) => {
            const object = {};
            for (const a of question.answers) {
              const { key } = a;
              const answersSingle = (groupedAnswersByExtra[q.key] || []).filter((s) => s.index === key);
              const answersMultiple = answersWithMultipleExtra.filter(
                (s) => s.index === key && (s.extra || []).includes(q.key)
              );
              object[key] = sumBy(answersSingle, "value") / answersSingle.length || 0;
              object[`Orano - ${key}`] = sumBy(answersMultiple, "value") / answersMultiple.length || 0;
            }
            object["subject"] = q.text;
            return object;
          });

        return (
          <div>
            <RadarChart cx={550} outerRadius={250} width={1500} height={800} data={data}>
              <PolarGrid />
              <PolarAngleAxis dataKey="subject" />
              <PolarRadiusAxis domain={[0, 6]} ticks={[1, 2, 3, 4, 5]} />
              {this.state.selectedExtra !== "orano" &&
                answers.map((q) => {
                  return (
                    <Radar
                      dataKey={q.key}
                      stroke={q.color || "black"}
                      strokeWidth={5}
                      fill="transparent"
                      fillOpacity={0.6}
                    />
                  );
                })}
              {this.state.selectedExtra &&
                this.state.selectedExtra === "orano" &&
                answers.map((q) => {
                  return (
                    <Radar
                      dataKey={`Orano - ${q.key}`}
                      stroke={q.color || "black"}
                      strokeWidth={5}
                      fill="transparent"
                      fillOpacity={0.6}
                    />
                  );
                })}
              <Legend layout="vetical" verticalAlign="middle" align="right" />
            </RadarChart>
          </div>
        );
      }
    }
  };

  renderBubblesChart = () => {
    const { stats, question } = this.props;
    const { chartProps = {} } = question;
    const {
      padding = 10,
      hasGradient,
      showLegend,
      showValue,
      zoom = 0.8,
      offsetX = 0,
      offsetY = 0,
      width = 1000,
      height = 800,
      style = {},
    } = chartProps;
    const { legendStyle, valueStyle = {}, labelStyle = {} } = style;
    const data = ((stats && stats.answers) || []).map((s) => ({ label: s.text, value: s.count }));
    return (
      <div className="slave_bubble_chart">
        <BubbleChart
          graph={{ zoom, offsetX, offsetY }}
          width={width}
          height={height}
          hasGradient={hasGradient}
          padding={padding}
          showLegend={showLegend}
          showValue={showValue}
          legendFont={{
            family: "Arial",
            size: 12,
            color: "#000",
            weight: "bold",
            ...legendStyle,
          }}
          valueFont={{
            family: "Arial",
            size: 30,
            color: "#000",
            weight: "bold",
            ...valueStyle,
          }}
          labelFont={{
            family: "Arial",
            size: 20,
            color: "#000",
            weight: "bold",
            ...labelStyle,
          }}
          bubbleClickFunc={this.bubbleClick}
          legendClickFun={this.legendClick}
          data={data}
        />
      </div>
    );
  };

  calculateScore = (answers) => {
    if (answers.length === 0) return 0;
    return sumBy(answers, "value") / answers.length;
  };

  renderScatterPlot = () => {
    const { selectedExtra } = this.state;
    const { question, stats } = this.props;
    const { items = [] } = question;
    const itemsById = keyBy(items, "id");
    const allResults = [];
    const allAnswers = (stats && stats.answersWithExtra) || [];
    for (const a of allAnswers) {
      const { answers = [], extra, columnId, value } = a;
      for (const r of answers) {
        allResults.push({ answer: r, columnId, value, extra: extra });
      }
    }
    const groupByItems = groupBy(allResults, "answer");
    let scatters = {};
    for (const i in groupByItems) {
      const answers = groupByItems[i];
      const oranoAnswers = answers.filter((a) => a.extra.includes("orano"));
      const otherAnswers = answers.filter((a) => !a.extra.includes("orano"));
      const filteredOthers =
        selectedExtra && selectedExtra !== "orano" && selectedExtra !== "none"
          ? otherAnswers.filter((a) => a.extra.includes(selectedExtra))
          : otherAnswers;
      scatters[i] = [
        {
          x: selectedExtra === "orano" ? 0 : this.calculateScore(filteredOthers),
          y:
            selectedExtra && selectedExtra !== "orano" && selectedExtra !== "none"
              ? 0
              : this.calculateScore(oranoAnswers),
          z: answers.length,
          item: i,
          itemContent: itemsById[i].content || i,
        },
      ];
    }
    return (
      <ScatterChart width={1500} height={620} margin={{ top: 20, right: 20, bottom: 20, left: 20 }}>
        <XAxis type="number" dataKey={"y"} name="Orano" domain={[0, 6]} tickSize={20} ticks={[1, 2, 3, 4, 5]}>
          <Label value="Orano" position="bottom" dy={-0} />
        </XAxis>
        <YAxis type="number" dataKey={"x"} name="Others" domain={[0, 6]} tickSize={30} ticks={[1, 2, 3, 4, 5]}>
          <Label angle={-90} value="Parties prenantes" position="left" textAnchor="middle" />
        </YAxis>
        <CartesianGrid />
        <Tooltip cursor={{ strokeDasharray: "3 3" }} content={<CustomTooltip />} />
        {Object.keys(scatters).map((scatter) => {
          const item = itemsById[scatter] || {};
          const { content, category } = item;
          const { categories = [] } = question;
          const { color } = categories.find((cat) => cat.id === category) || {};
          return (
            <Scatter
              key={scatter}
              name={content}
              data={scatters[scatter]}
              fill={color || "black"}
              stroke={color || "black"}
              strokeWidth={18}
            ></Scatter>
          );
        })}
      </ScatterChart>
    );
  };

  renderImageVoteAnswers = () => {
    const { question, state, stats, primaryColor } = this.props;
    const { imageType, slideImagesCount = 4, slideMaxAnswersCount = 1 } = question;
    const answers_group = chunk(question.answers, parseInt(slideImagesCount, 10));
    const imageSize = windowWidth / parseInt(slideImagesCount, 10) - windowWidth * (0.58 / slideImagesCount);
    const orderAnswersByCount = (stats && orderBy(stats.answers, "count", "desc")) || [];
    const lastCount = orderAnswersByCount.length > 0 && orderAnswersByCount[slideMaxAnswersCount - 1].count;
    const activeAnswers = orderAnswersByCount.filter((s) => s.count >= lastCount);

    return (
      <div className="question-container__answers--images">
        {answers_group.map((group, id) => (
          <div
            key={id}
            className="answer-container"
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "flex-start",
              justifyContent: "center",
            }}
          >
            {group.map((ans, idx) => {
              const index = id * slideImagesCount + idx;
              const isActive = activeAnswers.find((s) => s.index === index);
              const extaClass = state === "SHOW_RESULTS" ? (ans.checked ? "correct" : "wrong") : "";

              return (
                <div
                  key={idx}
                  style={{
                    margin: 20,
                    padding: 10,
                    flex: 1,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    textAlign: "center",
                  }}
                  className={cx("answer-container", "image", `image-${index}`, extaClass)}
                >
                  <Image answer={ans} type={imageType} imageSize={imageSize} color={primaryColor} active={isActive} />
                  <p
                    style={{ marginBottom: 0, marginTop: 15, fontWeight: "bold" }}
                    className={cx("slave__question__vote--text", { active: isActive })}
                  >
                    {ans.text}
                  </p>
                  {state === "SHOW_RESULTS" && (
                    <span
                      className={cx("slave__question__vote--text", { active: isActive })}
                      style={{ fontWeight: "bold" }}
                    >
                      {stats.answers[index].percent}
                    </span>
                  )}
                </div>
              );
            })}
          </div>
        ))}
      </div>
    );
  };
}

const CustomTooltip = ({ payload }) => {
  if (!payload || !payload[0]) return null;

  return (
    <div
      className="scatter-text"
      style={{ backgroundColor: "white", padding: 6, borderRadius: 10, border: "1px solid #aaa" }}
    >
      {payload[0].payload.itemContent}
    </div>
  );
};

const Image = ({ type = "square", answer, imageSize, active, color }) => {
  const border = !active ? "3px solid #fff" : `3px solid ${color}`;
  return (
    <div
      style={{
        border,
        borderRadius: type === "circle" ? "50%" : "0%",
        boxShadow: "0 1px 5px rgba(0, 0, 0, 0.46)",
        maxWidth: "auto",
      }}
      className={cx("vote-image-border", { active })}
    >
      <div
        className="slave__question__vote--image"
        style={{
          margin: "auto",
          height: imageSize,
          width: imageSize,
          backgroundImage: `url(${(answer.image || {}).uri})`,
          backgroundPosition: "center",
          backgroundSize: "cover",
          borderRadius: type === "circle" ? "50%" : "0%",
        }}
      />
    </div>
  );
};

export class SlaveMultipleResults extends React.Component {
  render() {
    const { logo, question, stats } = this.props;
    const { podiumProps = {} } = question;
    const { maxItem, height, width } = podiumProps;
    let allResults = [];
    const questions = question.questions || [];
    for (const q of questions) {
      const { questionId, key, color } = q;
      const stat = (stats && stats.find((s) => s.question.uuid === questionId)) || {};
      allResults = [
        ...allResults,
        ...((stat || {}).answers || []).map((a) => ({
          ...a,
          text: (question.answers.find((ans) => ans.key === a.text) || {}).text || a.text || "",
          key,
          color,
        })),
      ];
    }
    const colorByKey = allResults.reduce((acc, value) => {
      if (!acc[value.key] !== value.color) {
        acc[value.key] = value.color;
      }
      return acc;
    }, {});
    const groupedByKey = groupBy(allResults, "key");
    return (
      <React.Fragment>
        {logo && <SlaveLogo src={logo} style={{ maxWidth: 200, position: "absolute", bottom: 10, right: 10 }} />}
        <div id={question.uuid} style={{ padding: 20 }}>
          <h1 className="question-text" style={{ fontSize: "5.5vh", color: "black" }}>
            {question.question}
          </h1>
          <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
            {Object.keys(groupedByKey).map((key) => {
              return (
                <div>
                  <span className="podium-text">{key}</span>
                  <Podium
                    color={colorByKey[key]}
                    dataList={groupedByKey[key]}
                    height={height}
                    maxItem={maxItem}
                    width={width}
                  />
                </div>
              );
            })}
          </div>
        </div>
      </React.Fragment>
    );
  }
}
