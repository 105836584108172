import cx from "classnames";
import React, { Component } from "react";

import { TextContainer } from "./Client";
import firebase, { eventId } from "./Store";
import Strings, { forcedLanguage, mergeStrings } from "./data/Strings";
import { computeResults } from "./master/utils";
import SlaveScreen, { SlaveMultipleResults } from "./screens/SlaveScreen";
import SlaveStyles from "./screens/SlaveStyles";
import { SlaveInformation, SlavePin, SlaveTitle } from "./slave/components";
import SlaveResult from "./slave/components/SlaveResult";
import { getParameterByName } from "./utils";
import { translateValue } from "./utils/translationUtils";

const hasDefaultPin = getParameterByName("pin");
const showResults = getParameterByName("showResults") || false;
const showChart = getParameterByName("showChart") || true;
const showFastest = getParameterByName("showFastest") || false;

// let deviceId = 0;
// if (window.localStorage) {
//   deviceId = localStorage.getItem("vote-uuid");
//   if (!deviceId) {
//     deviceId = uuid();
//     localStorage.setItem("vote-uuid", deviceId);
//   }
// }

// function computeAnswers(answers) {
//   const final = [];
//   for (var ans in answers) {
//     if (answers[ans]) {
//       final.push(ans);
//     }
//   }
//   final.sort();
//   return final;
// }

// function checkCorrect(question, answers) {
//   const correctAnswers = [];
//   for (var i in question.answers) {
//     if (question.answers[i].checked) {
//       correctAnswers.push("" + i);
//     }
//   }
//   answers = computeAnswers(answers);
//   const correctValue = correctAnswers.join("-");
//   answers.sort();
//   return answers.join("-") === correctValue;
// }

// Try to use current user's id ?
// const userId = deviceId; // getParameterByName('userId') || deviceId

const AUTO_CONNECT = getParameterByName("autoConnect") === "true";

const Logo = () => (
  <div
    style={{
      position: "absolute",
      left: "50%",
      right: 0,
      top: 0,
      padding: 8,
      textAlign: "center",
    }}
  >
    {/* <img src="https://app.appcraft.events/data/NBJFHDU8/assets/logo.png" style={{maxWidth: '100%', maxHeight: 32}}/> */}
  </div>
);

const Container = ({ children, pin, style }) => (
  <div className="sidebar-content-list" style={style}>
    {pin && !hasDefaultPin && (
      <div style={{ position: "fixed", top: 8, right: 48, fontSize: "1.5vw" }}>
        PIN: <code>{pin}</code>
      </div>
    )}
    <Logo />
    {children}
  </div>
);

function getImage(image) {
  if (!image) return null;
  if (typeof image === "string") return image;
  const uri = image.uri || image.mediumUri || image.largeUri;
  if (!uri) return undefined;
  return uri;
}

function generateBrickWall(template, perX, perY, images) {
  const isBricks = template === "bricks";
  const bricks = [];
  for (let y = 0; y < perY; y++) {
    for (let x = 0; x < perX; x++) {
      var n = isBricks ? (x + y) % 6 : (x + y) % images.length;
      bricks.push({
        id: x + "," + y,
        x: isBricks ? x + (y % 2 === 1 ? -0.5 : 0) : x,
        y,
        frame: 1 + n,
        src: isBricks
          ? "https://app.appcraft.events/images/Eaj6dUnT7TUrxk/assets/brick" + (1 + n) + ".png"
          : getImage(images[n]),
      });
    }
  }
  return bricks;
}

function computeFreeBricks(bricks) {
  return bricks.filter((b) => !b.broken);
}

function breakBrick(bricks) {
  const freeBricks = computeFreeBricks(bricks);
  if (freeBricks.length > 0) {
    var n = Math.floor(Math.random() * freeBricks.length);
    if (n >= 0 && n < freeBricks.length) {
      freeBricks[n].broken = true;
    }
    return bricks.slice();
  }
  return bricks;
}

export default class Slide extends Component {
  constructor(props) {
    super(props);

    console.log("Slide");

    this.state = {
      state: "DISCONNECTED",
      answers: {},
      stats: null,
      words: [],
      sessionId: "",
      pin: getParameterByName("pin") || "",
      eventId: getParameterByName("eventId") || this.props.eventId || eventId,
      savedState: null,
      savedQuestion: null,
      savedStats: null,
      totalAnswers: 0,
      connected: 0,
    };
  }

  disconnect = (e) => {
    // e.preventDefault();
    // e.stopPropagation();

    console.warn("TODO: disconnect");
    if (this.unsubscribe) {
      this.unsubscribe();
      this.unsubscribe = null;
    }
    if (!AUTO_CONNECT && this.unsubscribePin) {
      this.unsubscribePin();
      this.unsubscribePin = null;
    }
    this.setState({ state: "DISCONNECTED", sessionId: "", answers: {} });
    // if (this.socket) {
    //   this.socket.disconnect();
    // }
  };

  connect = (e) => {
    // const { eventId, sessionId, pin, answers } = this.state;
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }

    // Fetch info from pin
    const { pin } = this.state;
    if (this.unsubscribePin) this.unsubscribePin();
    this.unsubscribePin = this.database
      .collection("quiz-pin")
      .doc(pin)
      .onSnapshot((doc) => {
        if (doc.exists) {
          const { sessionId, eventId } = translateValue(doc.data(), forcedLanguage);
          this.setState({ sessionId, eventId });
          // console.log("doc", data);

          this.connectTo(eventId, sessionId);
        } else {
          console.warn("show error...");
          this.disconnect();
        }
      });
    // if (this.unsubscribeCount) this.unsubscribeCount();
    // this.unsubscribeCount = this.database
    //   .collection("quiz-pin")
    //   .doc(pin)
    //   .onSnapshot(doc => {
    //     if (doc.exists) {
    //       const { sessionId, eventId } = doc.data();
    //       this.setState({ sessionId, eventId });
    //       // console.log("doc", data);

    //       this.connectTo(eventId, sessionId);
    //     } else {
    //       console.warn("show error...");
    //       this.disconnect();
    //     }
    //   });
  };

  connectTo = (eventId, sessionId) => {
    const { forceQuestionId } = this.props;
    this.answers = [];
    this.bricks = [];
    if (this.unsubscribe) this.unsubscribe();
    if (this.unsubscribeCount) this.unsubscribeCount();
    // this.database
    // .collection("quiz-pin")
    // .doc(this.state.pin)
    // .get()
    // .then(doc => {
    //   const { connected = 0 } = doc.data();
    //   this.setState({ connected })
    // });
    const sessionDb = this.database.collection("quiz").doc(eventId).collection("sessions").doc(`${sessionId}`);
    this.unsubscribeCount = sessionDb
      .collection("users")
      .doc("__stats")
      .onSnapshot((doc) => {
        if (doc.exists) {
          const data = doc.data();
          if (data) {
            this.setState({
              connected: data.count,
            });
          }
        }
      });
    const questionDb = forceQuestionId ? sessionDb.collection("results").doc(forceQuestionId) : sessionDb;
    this.currentQuestionUuid = null;
    this.unsubscribe = questionDb.onSnapshot((currentSession) => {
      if (currentSession.exists) {
        const { currentQuestion, quiz } = translateValue(currentSession.data(), forcedLanguage);
        if (currentQuestion) {
          if (currentQuestion.uuid !== this.currentQuestionUuid) {
            this.currentQuestionUuid = currentQuestion.uuid;
            this.answers = []; // Flush
            this.bricks = [];
          }
          const { state, stats, type, wordsToHide = [] } = currentQuestion;
          if (state === "ANSWERING") {
            if (type === "brick_wall") {
              const { template, perX, perY, images = [] } = currentQuestion;
              const isBricks = template === "bricks";
              const x = isBricks ? 11 : parseInt(perX, 10);
              const y = isBricks ? 20 : parseInt(perY, 10);
              if (!this.bricks?.length) {
                this.bricks = generateBrickWall(template, x, y, images);
              }
              this.setState({
                bricks: this.bricks,
                startTime: new Date().getTime(),
              });
            }
            // TODO : check if already answered ?
            this.setState({
              state: "ANSWERING",
              question: currentQuestion,
              words: [],
              quiz,
              stats,
              answers: {},
              totalAnswers: 0,
            });

            // Only subscribe to answers once
            // TODO : only for currentQuestion.type === "word_cloud"
            if (this.unsubscribeAnswers) {
              this.unsubscribeAnswers();
              this.unsubscribeAnswers = null;
            }
            let totalAnswers = this.state.totalAnswers;
            this.unsubscribeAnswer = this.dbSession()
              .collection("answers")
              .doc(currentQuestion.uuid)
              .collection("users")
              .onSnapshot((snapshot) => {
                snapshot.docChanges().forEach((change) => {
                  if (change.type === "added") {
                    if (currentQuestion.type === "vote") {
                      totalAnswers = totalAnswers + 1;
                      this.setState({ totalAnswers });
                    } else if (currentQuestion.type === "word_cloud") {
                      this.answers = [...this.answers, change.doc.data()];
                      const words = computeResults(currentQuestion, this.answers, {}, wordsToHide);
                      this.setState({ words: words.answers });
                    } else if (currentQuestion.type === "brick_wall") {
                      this.breakOneBrick(this.state.startTime, 5000);
                    }
                  }
                });
              });
          } else if (state === "SHOW_RESULTS") {
            this.setState({
              state: "SHOW_RESULTS",
              question: currentQuestion,
              quiz,
              stats,
            });
          } else {
            this.setState({
              state: "WAITING_NEXT",
              question: currentQuestion,
              quiz,
              stats,
            });
          }
        } else {
          this.setState({ state: "WAITING", stats: null, quiz });
        }
      } else {
        this.setState({ state: "UNAVAILABLE", question: null, stats: null });
      }
    });
  };

  // updateData = questions => {
  //   this.dbSession().update({ questions });
  // };
  dbSession = () => {
    const { sessionId, eventId } = this.state;
    return this.database.collection("quiz").doc(eventId).collection("sessions").doc(sessionId);
  };

  breakOneBrick = (startTime, repeatTime) => {
    if (startTime !== this.state.startTime) return; // Question changed...

    // const time = new Date().getTime();
    const freeCnt = computeFreeBricks(this.bricks).length;
    if (freeCnt === 0) return; // finished !

    this.bricks = breakBrick(this.bricks);
    this.setState({ bricks: this.bricks });
    // const nearlyHalf = freeCnt < this.bricks.length * 0.66;
    // const tooLong = time - this.state.startTime > 20000;

    const delay = repeatTime > 500 ? repeatTime * 0.66 : 500;
    setTimeout(() => this.breakOneBrick(startTime, delay), delay);
  };

  componentDidMount() {
    const { forceQuestionId, eventId, sessionId } = this.props;

    this._mounted = true;
    this.database = firebase.firestore();

    if (forceQuestionId) {
      this.connectTo(eventId, sessionId); // Auto-connect to this question...
    } else if (this.state.pin && AUTO_CONNECT) {
      this.connect();
    }
  }

  componentWillUnmount() {
    this._mounted = false;
    if (this.unsubscribe) {
      this.unsubscribe();
      this.unsubscribe = null;
    }
    if (this.unsubscribeCount) {
      this.unsubscribeCount();
      this.unsubscribeCount = null;
    }
    if (this.unsubscribePin) {
      this.unsubscribePin();
      this.unsubscribePin = null;
    }
    if (this.unsubscribeAnswers) {
      this.unsubscribeAnswers();
      this.unsubscribeAnswers = null;
    }
  }

  renderMultipleVoteAnswers() {
    const { question, state, stats } = this.state;
    return (
      <div>
        {question.answers.map((ans, idx) => {
          let clazz = "slide__answer";
          if (state === "SHOW_RESULTS") {
            if (ans.checked) clazz += " correct";
            else clazz += " incorrect";
          }

          return (
            <div key={idx} className={clazz}>
              <div className="slide__answer__label">{idx + 1}</div>
              <div className="slide__answer__text">
                {ans.text}
                <div
                  className={"answer answer-" + (idx + 1)}
                  style={{
                    minWidth: state === "SHOW_RESULTS" ? stats.answers[idx].percent : 0,
                  }}
                />
              </div>
            </div>
          );
        })}
      </div>
    );
  }

  renderMultipleAnswers() {
    const { question, state, stats } = this.state;
    return (
      <div>
        {question.answers.map((ans, idx) => {
          let borderColor, backgroundColor, color;
          if (state === "SHOW_RESULTS") {
            borderColor = ans.checked ? "#2CA02C" : "red";
            backgroundColor = ans.checked ? "#2CA02C" : "transparent";
            color = ans.checked ? "white" : "red";
          } else {
            color = "black";
            borderColor = "black";
            backgroundColor = "transparent";
          }

          return (
            <div
              key={idx}
              style={{
                flexDirection: "row",
                display: "flex",
                marginBottom: 10,
                alignItems: "center",
              }}
            >
              <div
                style={{
                  fontFamily: "Montserrat",
                  fontWeight: "bold",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: 48,
                  height: 48,
                  borderRadius: 28,
                  border: `1px solid ${borderColor}`,
                  marginRight: 10,
                }}
              >
                <p style={{ margin: "auto", color: borderColor }}>{idx + 1}</p>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  fontWeight: "bold",
                  padding: 15,
                  backgroundColor,
                  justifyContent: "space-between",
                  flex: 1,
                  height: 60,
                  borderRadius: 4,
                  border: `1px solid ${borderColor}`,
                  marginRight: 10,
                  fontFamily: "Montserrat",
                }}
              >
                <div style={{ color: color }}>{ans.text}</div>
                <div style={{ color: color, fontWeight: "bold" }}>
                  {state === "SHOW_RESULTS" ? stats.answers[idx].percent : 0}
                </div>
              </div>
              {state === "SHOW_RESULTS" && (
                <div
                  style={{
                    position: "relative",
                    right: 24,
                    bottom: -20,
                    height: 22,
                    width: 22,
                    backgroundColor: borderColor,
                    borderRadius: 11,
                  }}
                >
                  {ans.checked ? (
                    <i className="fa fa-check" style={{ color: "white" }} />
                  ) : (
                    <i style={{ color: "white" }} className="fa fa-times" />
                  )}
                </div>
              )}
            </div>
          );
        })}
      </div>
    );
  }
  renderImageAnswers() {
    const { question, answers, state } = this.state;
    return (
      <div style={{ display: "flex", flexDirection: "row" }}>
        {question.answers.map((ans, idx) => {
          const isActive = answers[idx];
          let active = !isActive ? "inset 0 0 0 1px #DDD" : "inset 0 0 0 3px #00477E";
          let response = "";
          if (state === "SHOW_RESULTS") {
            response = ans.checked ? "inset 0 0 0 3px #2CA02C" : "inset 0 0 0 3px red";
          }
          const boxShadow = state === "SHOW_RESULTS" ? response : active;
          return (
            <div
              key={idx}
              style={{
                flex: 1,
                boxShadow,
                margin: 5,
                padding: 10,
                textAlign: "center",
              }}
            >
              {state === "SHOW_RESULTS" && (
                <div
                  style={{
                    position: "relative",
                    right: 15,
                    top: -20,
                    height: 22,
                    width: 22,
                    backgroundColor: ans.checked ? "#2CA02C" : "red",
                    borderRadius: 11,
                  }}
                >
                  {ans.checked ? (
                    <i className="fa fa-check" style={{ color: "white" }} />
                  ) : (
                    <i style={{ color: "white" }} className="fa fa-times" />
                  )}
                </div>
              )}
              <img src={ans.image.uri} width="100%" alt="answer" />
              <p
                style={{
                  marginBottom: 0,
                  marginTop: 10,
                  fontFamily: "Montserrat",
                  fontWeight: "bold",
                }}
              >
                {ans.text}
              </p>
            </div>
          );
        })}
      </div>
    );
  }

  getStrings() {
    const { quiz } = this.state;
    return mergeStrings(Strings, (quiz || {}).strings);
  }

  render() {
    const { quiz, pin, state, question, stats, words, totalAnswers } = this.state;
    const questionNumber = question ? question.index : 0;

    console.log("render", state);

    if (state === "DISCONNECTED" || state === "UNAVAILABLE") {
      return this.renderDisconnected();
    }

    if (state === "WAITING" || !question) {
      return (
        <Container pin={pin}>
          <SlaveStyles
            primaryColor={quiz.primaryColor}
            customCSS={quiz.customSlideCSS || quiz.customCSS}
            fonts={quiz.fonts}
          />
          <div
            className="slide__text--container"
            style={{
              display: "table",
              height: "100%",
              position: "absolute",
              width: "100%",
            }}
          >
            <div
              className="slide__answers--container"
              style={{
                display: "table-cell",
                verticalAlign: "middle",
                textAlign: "center",
              }}
            >
              <div className="slide__answers__text">{this.getStrings().waitingQuestion}</div>
            </div>
          </div>
          {this.renderDisconnectButton()}
        </Container>
      );
    }

    if (question.type === "brick_wall") {
      const { template, perX, perY, brickColor } = question;
      const background = question.hiddenImage && question.hiddenImage.uri;
      const ball = question.ball && question.ball.uri;
      const { bricks = [] } = this.state;
      const isBricks = template === "bricks";
      const w = isBricks ? 10 : 100 / perX;
      const h = isBricks ? 3.4 : 100 / perY;
      return (
        <div
          className="brick_wall"
          style={{
            overflow: "hidden",
            backgroundImage: 'url("' + background + '")',
            backgroundSize: "cover",
          }}
        >
          {bricks.map((b) => (
            <div
              key={b.id}
              className={cx("brick_container", b.broken ? "broken" : "")}
              style={{
                left: b.x * w + "vw",
                top: b.y * h + (isBricks ? "vw" : "vh"),
                width: w + "vw",
                height: h.toFixed(1) + (isBricks ? "vw" : "vh"),
                background: !isBricks ? brickColor : "transparent",
                padding: !isBricks ? "0px 16px" : undefined,
              }}
            >
              <div
                className={cx("brick", b.broken ? "broken" : "")}
                style={{
                  background: `url(${b.src}) center / contain no-repeat`,
                }}
              />
              {/* <img className={"brick " + (b.broken ? "broken" : "")} src={b.src} alt="brick" /> */}
            </div>
          ))}
          {bricks.map((b) => (
            <div
              key={b.id}
              className="brick_container"
              style={{
                left: b.x * w + "vw",
                top: b.y * h + (isBricks ? "vw" : "vh"),
                width: w + "vw",
                height: h + (isBricks ? "vw" : "vh"),
                zIndex: 1,
              }}
            >
              <img alt="ball" className={"ball ball-" + b.frame + " " + (b.broken ? "visible" : "")} src={ball} />
            </div>
          ))}
        </div>
      );
    }

    if (state === "ANSWERING" || state === "WAITING_NEXT" || state === "SHOW_RESULTS") {
      // const questionClass =
      //   question.type === "image_quiz" || question.type === "word_cloud" ? "slide__image" : "slide__multiple";
      // const responseClass =
      //   question.type === "image_quiz" || question.type === "word_cloud"
      //     ? "slide__image__answers"
      //     : "slide__multiple__answers";
      const pinToDisplay = quiz.showPin && pin;
      return (
        <div>
          <SlaveStyles
            primaryColor={quiz.primaryColor}
            customCSS={quiz.customSlideCSS || quiz.customCSS}
            fonts={quiz.fonts}
          />
          {(question.type === "title" || question.type === "form") && (
            <SlaveTitle {...quiz} title={question.question} pin={pinToDisplay} question={question} />
          )}
          {question.type === "results" && (
            <SlaveResult
              {...quiz}
              strings={this.getStrings()}
              title={question.slideText || question.question}
              pin={pinToDisplay}
              question={question}
            />
          )}
          {question.type === "multiple-results" && (
            <SlaveMultipleResults {...quiz} question={question} state={state} stats={stats} />
          )}
          {question.type === "informations" && (
            <SlaveInformation
              template={quiz.template}
              primaryColor={quiz.primaryColor}
              logo={quiz.logo}
              title={question.question}
              text={question.slideText}
              pin={pinToDisplay}
              question={question}
            />
          )}
          {question.type === "pin" && (
            <SlavePin
              template={quiz.template}
              primaryColor={quiz.primaryColor}
              logo={quiz.logo}
              title={question.question}
              pin={pin}
              question={question}
              connected={this.state.connected}
            />
          )}
          {(question.type === "multiple_choice" ||
            question.type === "vote" ||
            question.type === "image_quiz" ||
            question.type === "image_vote" ||
            question.type === "multiple_texts" ||
            question.type === "scale" ||
            question.type === "input" ||
            question.type === "drag_and_drop") && (
            <SlaveScreen
              {...quiz}
              question={question}
              state={state}
              stats={stats}
              pin={pin}
              showResults={showResults}
              number={questionNumber}
              showChart={showChart}
              showFastest={showFastest}
              totalAnswers={totalAnswers}
            />
          )}
          {
            question.type === "word_cloud" && (
              <SlaveScreen
                template={quiz.template}
                primaryColor={quiz.primaryColor}
                logo={quiz.logo}
                title={quiz.title}
                question={question}
                state={state}
                stats={stats}
                words={words}
                pin={pinToDisplay}
                number={questionNumber}
              />
            )
            // <WordCloudStats answers={words} {...stats} type={question.type} />
          }
        </div>
        //
        //      <Container pin={pin}>
        //      <div className="slide">
        //     <div
        //       className={cx("slide__question", questionClass)}
        //       style={{ fontFamily: "Montserrat", fontWeight: "bold" }}
        //     >
        //       {question.question}
        //     </div>
        //     <div className={cx("slide__answers", responseClass)}>
        //       {question.type === "image_quiz" && this.renderImageAnswers()}
        //       {question.type !== "image_quiz" && this.renderMultipleAnswers()}
        //       {state === "ANSWERING" && <div className="slide__answers__text">En attente des résultats...</div>}
        //       {state === "WAITING_NEXT" && <div className="slide__answers__text">En attente des résultats...</div>}
        //       {state === "SHOW_RESULTS" &&
        //         question.type !== "vote" &&
        //         question.type !== "word_cloud" && <Stats {...stats} type={question.type} />}
        //       {state === "SHOW_RESULTS" &&
        //         question.type === "word_cloud" && <WordCloudStats words={words} {...stats} type={question.type} />}
        //     </div>
        //   </div>
        //   {this.renderDisconnectButton()}
        // </Container>
      );
    }
    return (
      <Container pin={pin}>
        Merci pour votre réponse, une nouvelle question arrivera prochainement...
        {this.renderDisconnectButton()}
      </Container>
    );
  }

  renderDisconnectButton() {
    if (AUTO_CONNECT) {
      return null;
    }
    return (
      <button
        className="btn btn-danger btn-sm"
        style={{ display: "block", position: "fixed", right: 8, top: 8 }}
        onClick={this.disconnect}
      >
        X
      </button>
    );
  }

  renderDisconnected() {
    const { pin, state } = this.state;
    const strings = this.getStrings();
    const states = {
      UNAVAILABLE: {
        msg: strings.invalidPin,
        color: "#FF5A40",
      },
      DISCONNECTED: {
        msg: strings.pinWillBeGiven,
        color: undefined,
      },
    };

    if (AUTO_CONNECT) {
      return (
        <Container style={{ padding: 8 }}>
          <TextContainer style={{ height: "80vh", width: "calc(100% - 32px)" }}>{strings.waitingForQuiz}</TextContainer>
        </Container>
      );
    }
    return (
      <Container style={{ padding: 128 }}>
        <h5
          style={{
            textAlign: "center",
            fontSize: 16,
            color: states[state].color,
          }}
        >
          {states[state].msg}
        </h5>
        <input
          style={{
            display: "block",
            margin: "0 auto",
            padding: 12,
            fontSize: 16,
            marginBottom: 8,
          }}
          type="text"
          pattern="[0-9]*"
          onInput={(e) => this.setState({ pin: e.target.value })}
          placeholder="pin"
          value={pin}
        />
        <button
          className="btn btn-primary"
          style={{ display: "block", margin: "0 auto" }}
          disabled={!pin}
          onClick={this.connect}
        >
          {strings.submit}
        </button>
      </Container>
    );
  }
}
