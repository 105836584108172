import * as d3 from "d3";
import PropTypes from "prop-types";
import React, { Component } from "react";
import ReactDOM from "react-dom";

export default class BubbleChart extends Component {
  constructor(props) {
    super(props);

    this.renderChart = this.renderChart.bind(this);
    this.renderBubbles = this.renderBubbles.bind(this);
    this.renderLegend = this.renderLegend.bind(this);
  }

  componentDidMount() {
    this.svg = ReactDOM.findDOMNode(this);
    this.renderChart();
  }

  componentDidUpdate() {
    const { width, height } = this.props;
    if (width !== 0 && height !== 0) {
      this.renderChart();
    }
  }

  render() {
    const { width, height } = this.props;
    return <svg width={width} height={height} />;
  }

  renderChart() {
    const { overflow, graph, data, height, width, padding, showLegend, legendPercentage } = this.props;
    // Reset the svg element to a empty state.
    this.svg.innerHTML = "";

    // DEBUG DATA
    // data = [...data, ...times(100, idx => ({ label: "Test " + idx, value: 1 + Math.round(idx / 10) }))];

    // Allow bubbles overflowing its SVG container in visual aspect if props(overflow) is true.
    if (overflow) this.svg.style.overflow = "visible";

    const bubblesWidth = showLegend ? width * (1 - legendPercentage / 100) : width;
    const legendWidth = width - bubblesWidth;
    const color = d3.scaleOrdinal(d3.schemeCategory20c);

    const pack = d3
      .pack()
      .size([bubblesWidth * graph.zoom, bubblesWidth * graph.zoom])
      .padding(padding);

    // Process the data to have a hierarchy structure;
    const root = d3
      .hierarchy({ children: data })
      .sum(function (d) {
        return d.value;
      })
      .sort(function (a, b) {
        return b.value - a.value;
      })
      .each((d) => {
        if (d.data.label) {
          d.label = d.data.label;
          d.id = d.data.label.toLowerCase().replace(/ |\//g, "-");
        }
      });

    // Pass the data to the pack layout to calculate the distribution.
    const nodes = pack(root).leaves();

    // Call to the function that draw the bubbles.
    this.renderBubbles(bubblesWidth, nodes, color);
    // Call to the function that draw the legend.
    if (showLegend) {
      this.renderLegend(legendWidth, height, bubblesWidth, nodes, color);
    }
  }

  renderBubbles(width, nodes, color) {
    const { graph, bubbleClickFun, valueFont, labelFont, hasGradient, showValue } = this.props;

    var gradient = d3
      .select(this.svg)
      .append("svg:defs")
      .append("svg:linearGradient")
      .attr("id", "gradient")
      .attr("x1", "0%")
      .attr("y1", "0%")
      .attr("x2", "100%")
      .attr("y2", "100%")
      .attr("spreadMethod", "pad");

    // Define the gradient colors
    gradient
      .append("svg:stop")
      .attr("offset", "0%")
      .attr("class", "grandient-primary")
      .attr("stop-color", "#fff")
      .attr("stop-opacity", 1);

    gradient
      .append("svg:stop")
      .attr("offset", "100%")
      .attr("class", "gradient-secondary")
      .attr("stop-color", "#ddd")
      .attr("stop-opacity", 1);

    const bubbleChart = d3
      .select(this.svg)
      .append("g")
      .attr("class", "bubble-chart")
      .attr("transform", function (d) {
        return "translate(" + width * graph.offsetX + "," + width * graph.offsetY + ")";
      });

    const node = bubbleChart
      .selectAll(".node")
      .data(nodes)
      .enter()
      .append("g")
      .attr("class", "node")
      .attr("transform", (d) => "translate(" + d.x + "," + d.y + ")")
      .on("click", function (d) {
        bubbleClickFun(d.label);
      });

    node
      .append("circle")
      .attr("id", (d) => d.id)
      .attr("class", "bubble-cirle")
      .attr("r", (d) => d.r - d.r * 0.04)
      .style("fill", function (d) {
        return hasGradient && d.label !== "ORANO"
          ? "url(#gradient)"
          : d.label === "ORANO"
          ? "#f7f0db"
          : d.data.color
          ? d.data.color
          : color(nodes.indexOf(d));
      })
      .style("z-index", 1)
      .on("mouseover", function (d) {
        d3.select(this).attr("r", d.r * 1.04);
      })
      .on("mouseout", function (d) {
        const r = d.r - d.r * 0.04;
        d3.select(this).attr("r", r);
      });

    node
      .append("clipPath")
      .attr("id", (d) => "clip-" + d.id)
      .append("use")
      .attr("xlink:href", (d) => "#" + d.id);

    if (showValue) {
      node
        .append("text")
        .attr("class", "value-text")
        .style("font-size", `${valueFont.size}px`)
        .attr("clip-path", (d) => "url(#clip-" + d.id + ")")
        .style("font-weight", (d) => valueFont.weight || 600)
        .style("font-family", valueFont.family)
        .style("fill", () => valueFont.color || "#000")
        .style("stroke", () => valueFont.lineColor || "#000")
        .style("stroke-width", () => valueFont.lineWeight || 0)
        .text((d) => d.value);
    }
    node
      .append("text")
      .attr("class", "label-text")
      .style("font-size", `${labelFont.size}px`)
      .attr("clip-path", (d) => "url(#clip-" + d.id + ")")
      .style("font-weight", (d) => labelFont.weight || 600)
      .style("font-family", labelFont.family)
      .style("alignment-baseline", "middle")
      .style("fill", () => labelFont.color || "#000")
      .style("stroke", () => labelFont.lineColor || "#000")
      .style("stroke-width", () => labelFont.lineWeight || 0)
      .style("font-size", function (d) {
        const lengthLabel = ((d && d.label) || "").substring(0, d.r / 7).length;
        let size = d.r / 8;
        size *= 10 / lengthLabel;
        size += 1;
        return Math.round(size) + "px";
      })
      .text((d) => d.label);

    // Center the texts inside the circles.
    d3.selectAll(".label-text")
      .attr("x", function (d) {
        const self = d3.select(this);
        const width = self.node().getBBox().width;
        return -(width / 2);
      })
      .style("opacity", function (d) {
        const self = d3.select(this);
        const width = self.node().getBBox().width;
        d.hideLabel = width * 1.05 > d.r * 2;
        return d.hideLabel ? 0 : 1;
      })
      .attr("y", (d) => 0);

    // Center the texts inside the circles.
    d3.selectAll(".value-text")
      .attr("x", function (d) {
        const self = d3.select(this);
        const width = self.node().getBBox().width;
        return -(width / 2);
      })
      .attr("y", function (d) {
        if (d.hideLabel) {
          return valueFont.size / 3;
        } else {
          return -valueFont.size * 0.5;
        }
      });

    node.append("title").text(function (d) {
      return d.label;
    });
  }

  renderLegend(width, height, offset, nodes, color) {
    const { legendClickFun, legendFont } = this.props;
    const bubble = d3.select(".bubble-chart");
    const bubbleHeight = bubble.node().getBBox().height;

    const legend = d3
      .select(this.svg)
      .append("g")
      .attr("transform", function () {
        return `translate(${offset},${bubbleHeight * 0.05})`;
      })
      .attr("class", "legend");

    let textOffset = 0;
    const texts = legend
      .selectAll(".legend-text")
      .data(nodes)
      .enter()
      .append("g")
      .attr("transform", (d, i) => {
        const offset = textOffset;
        textOffset += legendFont.size + 10;
        return `translate(0,${offset})`;
      })
      .on("mouseover", function (d) {
        d3.select("#" + d.id).attr("r", d.r * 1.04);
      })
      .on("mouseout", function (d) {
        const r = d.r - d.r * 0.04;
        d3.select("#" + d.id).attr("r", r);
      })
      .on("click", function (d) {
        legendClickFun(d.label);
      });

    texts
      .append("rect")
      .attr("width", 30)
      .attr("height", legendFont.size)
      .attr("x", 0)
      .attr("y", -legendFont.size)
      .style("fill", "transparent");

    texts
      .append("rect")
      .attr("width", legendFont.size)
      .attr("height", legendFont.size)
      .attr("x", 0)
      .attr("y", -legendFont.size)
      .style("fill", function (d) {
        return d.data.color ? d.data.color : color(nodes.indexOf(d));
      });

    texts
      .append("text")
      .style("font-size", `${legendFont.size}px`)
      .style("font-weight", (d) => legendFont.weight || 600)
      .style("font-family", legendFont.family)
      .style("fill", () => legendFont.color || "#000")
      .style("stroke", () => legendFont.lineColor || "#000")
      .style("stroke-width", () => legendFont.lineWeight || 0)
      .attr("x", (d) => legendFont.size + 10)
      .attr("y", 0)
      .text((d) => d.label);
  }
}

BubbleChart.propTypes = {
  overflow: PropTypes.bool,
  graph: PropTypes.shape({
    zoom: PropTypes.number,
    offsetX: PropTypes.number,
    offsetY: PropTypes.number,
  }),
  width: PropTypes.number,
  height: PropTypes.number,
  padding: PropTypes.number,
  showLegend: PropTypes.bool,
  showValue: PropTypes.bool,
  hasGradient: PropTypes.bool,
  legendPercentage: PropTypes.number,
  legendFont: PropTypes.shape({
    family: PropTypes.string,
    size: PropTypes.number,
    color: PropTypes.string,
    weight: PropTypes.string,
  }),
  valueFont: PropTypes.shape({
    family: PropTypes.string,
    size: PropTypes.number,
    color: PropTypes.string,
    weight: PropTypes.string,
  }),
  labelFont: PropTypes.shape({
    family: PropTypes.string,
    size: PropTypes.number,
    color: PropTypes.string,
    weight: PropTypes.string,
  }),
};
BubbleChart.defaultProps = {
  overflow: false,
  graph: {
    zoom: 1.1,
    offsetX: -0.05,
    offsetY: -0.01,
  },
  width: 1000,
  height: 800,
  padding: 0,
  showLegend: true,
  showValue: false,
  hasGradient: false,
  legendPercentage: 20,
  legendFont: {
    family: "Arial",
    size: 12,
    color: "#000",
    weight: "bold",
  },
  valueFont: {
    family: "Arial",
    size: 16,
    color: "#fff",
    weight: "bold",
  },
  labelFont: {
    family: "Arial",
    size: 11,
    color: "#fff",
    weight: "normal",
  },
  bubbleClickFun: (label) => {
    console.log(`Bubble ${label} is clicked ...`);
  },
  legendClickFun: (label) => {
    console.log(`Legend ${label} is clicked ...`);
  },
};
