import React, { Component } from "react";

class BlockOverscroll extends Component {
  render() {
    // Specific iPad code for browsers, not needed in webviews !!
    // const iPadHeight = "95vh"
    // @media only screen and (device-width: 1024px) {
    //   /* For general iPad layouts */
    //   html, body {
    //     height: ${iPadHeight};
    //   }
    //   .quiz {
    //     height: ${iPadHeight};
    //     min-height: ${iPadHeight};
    //     max-height: ${iPadHeight};
    //   }
    // }
    return (
      <style
        dangerouslySetInnerHTML={{
          __html: `
html {
  overflow: hidden;
  height: 100vh;
}
body {
  overflow: hidden;
  height: 100vh;
}

`
        }}
      />
    );
  }
}

export default BlockOverscroll;
