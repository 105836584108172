import React, { Component } from "react";
import cx from "classnames";

export class SidebarItem extends Component {
  render() {
    const { title, active, ...props } = this.props;
    return (
      <li className="nav-item" {...props}>
        <a className={cx("nav-link", { active })} href="#">
          <span data-feather="home" />
          {title} {active && <span className="sr-only">(current)</span>}
        </a>
      </li>
    );
  }
}

export class SidebarMenu extends Component {
  render() {
    return <ul className="nav flex-column">{this.props.children}</ul>;
  }
}

export class SidebarHeader extends Component {
  render() {
    const { title } = this.props;
    return (
      <h6 className="sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1 text-muted">
        <span>{title}</span>
        <a className="d-flex align-items-center text-muted" href="#">
          <span data-feather="plus-circle" />
        </a>
      </h6>
    );
  }
}

class Sidebar extends Component {
  render() {
    return (
      <nav className="col-md-3 d-none d-md-block bg-light sidebar">
        <div className="sidebar-sticky">
          {this.props.children}
          {/* <SidebarMenu>
            <SidebarItem title="Dashboard" active />
            <SidebarItem title="Orders" />
            <SidebarItem title="Products" />
          </SidebarMenu>

          <SidebarHeader title="Saved reports" />
          <SidebarMenu>
            <SidebarItem title="Current month" />
            <SidebarItem title="Last quarter" />
            <SidebarItem title="Social engagement" />
          </SidebarMenu> */}
        </div>
      </nav>
    );
  }
}

Sidebar.Item = SidebarItem;
Sidebar.Menu = SidebarMenu;
Sidebar.Header = SidebarHeader;

export default Sidebar;
