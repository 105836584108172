import React, { PureComponent } from "react";
import cx from "classnames";
import SlaveLogo from "./SlaveLogo";

export default class SlavePin extends PureComponent {
  render() {
    const { title = "", template = "default", primaryColor, logo, question, pin, connected } = this.props;

    return (
      <div
        className={cx("slave-container", "slave-container--" + template, "slave-container--" + question.type)}
        style={{ textAlign: "center", alignItems: "center" }}
      >
        <SlaveLogo src={logo} style={{ maxWidth: 200, position: "absolute", bottom: 10, right: 10 }} />
        <div className="question-container" style={{ alignItems: "center" }}>
          {template !== "stand" && (
            <div className="question-number-container">
              <p className="question-number" />
            </div>
          )}
          <div style={{ display: "flex", alignItems: "center" }}>
            {template === "stand" && <i className="fa fa-chevron-right" />}
            <div>
              {title.split("\n").map((t, index) => (
                <h1 key={index} className="question-text" style={{ fontSize: "14vh", margin: 0 }}>
                  {t}
                </h1>
              ))}
            </div>
          </div>
          <h2 style={{ fontSize: "12vh", color: primaryColor, fontFamily: "Arial" }}>{pin}</h2>
          <div style={{ display: "flex", alignItems: "center", marginTop: 50 }}>
            <div
              className="responses-count"
              style={{ border: `4px solid ${primaryColor}`, color: primaryColor, fontSize: "8vh" }}
            >
              <i className="fa fa-users" /> {connected} {connected === 1 ? "Connecté" : "Connectés"}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
