import cx from "classnames";
import PropTypes from "prop-types";
import React, { PureComponent } from "react";

export default class SlaveQuestion extends PureComponent {
  render() {
    const { question, number } = this.props;
    const { question: title, subtitle } = question;
    const hasLongQuestion = title.length > 50;
    return (
      <div className="question-container__question">
        <h3 className="question-container__question--number">Question {number}</h3>
        <h1 className={cx("question-container__question--title", { long: hasLongQuestion })}>
          <i className="fa fa-chevron-right" />
          {title}
        </h1>
        {subtitle && (
          <div className="question-container__question--subtitle" style={{ fontSize: "3vh", color: "#999" }}>
            {subtitle}
          </div>
        )}
      </div>
    );
  }
}

SlaveQuestion.propTypes = {
  question: PropTypes.object.isRequired,
};
