import pubsubService from "../pubsub.service";
import WsFirestoreCollection from "./WsFirestoreCollection";

const noop = () => {};

export default class WsFirestoreDocument {
  // eslint-disable-next-line no-useless-constructor
  constructor(path) {
    this.path = path;
  }

  collection(col) {
    return new WsFirestoreCollection(`${this.path}/${col}`);
  }

  topic() {
    return this.path;
  }

  onSnapshot(callback) {
    if (!callback) return noop;

    const fun = (typeof callback === "object" && callback.next) || (typeof callback === "function" && callback);

    if (!fun) {
      console.error("Invalid callback function", callback);
      return noop;
    }

    // Subscribe :)
    return pubsubService.onDocument(this.topic(), (res) => {
      if (!res) {
        return fun({ exists: false });
      }
      return fun({
        exists: true,
        data: () => res,
      });
    });
  }

  get() {
    return new Promise((resolve) => {
      let unsubscribe;
      unsubscribe = this.onSnapshot((data) => {
        if (unsubscribe) {
          const toUnsubscribe = unsubscribe;
          unsubscribe = null; // Prevent double return
          resolve(data);
          toUnsubscribe();
        }
      });
    });
  }

  update(patch, options) {
    return new Promise((resolve) => {
      pubsubService.update(this.path, patch, options);
      setTimeout(resolve, 100);
    });
  }

  set(patch, options) {
    return new Promise((resolve) => {
      pubsubService.set(this.path, patch, options);
      setTimeout(resolve, 100);
    });
  }
}
