import * as d3 from "d3";
import cloud from "d3-cloud";
import cloneDeep from "lodash/cloneDeep";
import isEqual from "lodash/isEqual";
import omit from "lodash/omit";
import throttle from "lodash/throttle";
import React from "react";

const fontFamily = "Verdana";
// const fontFamily = "Helvetica"
// const fontFamily = "Arial"
// const fontFamily = "Impact"
// const fontFamily = "Mark W01 Regular"
// const fontFamily = "Calibri"

const defaultColors = [
  "#E5610D",
  "#6c40f3",
  "#07a7f7",
  "#F07E4E",
  "#878aff",
  "#3bd1ff",
  "#EF9B57",
  "#b1c4fd",
  "#87eaff",
];

//, "#F4E1B4"];

function initWordCloud(el, width, height, quizColors) {
  // var fill = d3.scaleOrdinal(d3.schemeCategory20);
  // var fill = d3.scale.category20();
  const colors = quizColors?.length > 0 ? quizColors : defaultColors;

  function fill(n) {
    return colors[n % colors.length];
  }

  let activeColor = null;

  //Construct the word cloud's SVG element
  var root = d3
    .select(el)
    .append("svg")
    .attr("width", width || 500)
    .attr("height", height || 500);

  var svgGroup = root.append("g");

  let lastDraw = {};

  //Draw the word cloud
  function draw(words, width, height) {
    lastDraw = { words, width, height };
    root.attr("width", width).attr("height", height);
    svgGroup
      .attr("transform", `translate(${width / 2},${height / 2})`)
      .attr("width", width)
      .attr("height", height);

    var cloud = svgGroup.selectAll("g text").data(words, (d) => d.id || d.text);

    //Entering words
    cloud
      .enter()
      .append("text")
      .style("font-family", fontFamily)
      // .style("font-weight", "bold")
      .style("fill", (d, i) => d.color || fill(i))
      .attr("text-anchor", "middle")
      // TODO: disable "animation" for first run, but keep for other runs ?
      // .attr("font-size", "1")
      .style("font-size", (d) => d.size + "px")
      .attr("transform", (d) => "translate(" + [d.x, d.y] + ")rotate(" + d.rotate + ")")
      .text((d) => d.text)
      .style("fill-opacity", (d) => {
        if (activeColor && activeColor !== d.color) return 0.15;
        return 1;
      });

    //Entering and existing words
    cloud
      .transition()
      .duration(600)
      .style("font-size", (d) => d.size + "px")
      .style("fill", (d, i) => d.color || fill(i))
      // .style("font-weight", "bold")
      .attr("transform", (d) => "translate(" + [d.x, d.y] + ")rotate(" + d.rotate + ")")
      .style("fill-opacity", (d) => {
        if (activeColor && activeColor !== d.color) return 0.15;
        return 1;
      });

    //Exiting words
    cloud.exit().transition().duration(200).style("fill-opacity", 1e-6).attr("font-size", 1).remove();
  }

  //Use the module pattern to encapsulate the visualisation code. We'll
  // expose only the parts that need to be public.

  function update(originalWords, width, height) {
    // console.time("wordCloud");
    // console.log("word_cloud svg", width, height, words);
    function computeCloud(fontRatio) {
      const words = cloneDeep(originalWords); // Clone deep, d3-cloud mutates the objects !
      // console.log("computeCloud with fontRatio", width + "x" + height, fontRatio);
      // console.log("words", cloneDeep(words));
      cloud()
        .size([width, height])
        .words(words)
        .padding(8 * fontRatio)
        .rotate(0)
        // .rotate(d => {
        //   if (d.text.length <= 2) return 0; // Emoticon ?
        //   return (Math.floor(Math.random() * 3) - 1) * 45;
        // })
        // .rotate(d => {
        //   if (d.text.length <= 2) return 0; // Emoticon ?
        //   return ~~(Math.random() * 2) * 90;
        // })
        .font(fontFamily)
        // .fontWeight("bold")
        .fontSize((d) => Math.max(6, d.size * fontRatio))
        .on("end", (output) => {
          console.log("Word count:", words.length, "vs", output.length);
          // Don't use recursion on crappy size
          if (fontRatio > 0.15 && output.length !== words.length && width > 100 && height > 100) {
            console.log("Mapping failed, try with a smaller size");
            computeCloud(fontRatio - 0.1);
          } else {
            // console.timeEnd("wordCloud");
            console.log("output", output);
            // Generate unique ids using extra info
            output.forEach((o, index) => (o.id = o.text + "-" + (words[index].color || "")));
            draw(output, width, height);
          }
        })
        .start();
    }
    computeCloud(1);
  }

  function setActiveColor(color) {
    activeColor = color;
  }
  function redraw() {
    draw(lastDraw.words, lastDraw.width, lastDraw.height);
  }

  return { update, redraw, setActiveColor };
}

class AnimatedWordCloud extends React.Component {
  static defaultProps = {
    words: [],
    config: undefined,
    width: 500,
    height: 500,
  };

  componentDidMount() {
    const { words, width, height, config } = this.props;
    const { colors } = config || {};
    const { update, redraw, setActiveColor } = initWordCloud(this.wordContainer, width, height, colors);
    this.update = update;
    this.redraw = redraw;
    this.setActiveColor = setActiveColor;
    this.update(this.mapWords(words), width, height);
  }

  mapWords(words) {
    // const maxSize = max(words.map(w => w.value));
    // function fontSizeMapper(value) {
    //   return 30 + 30 * (value / maxSize);
    // }

    return words.map((obj) => ({
      text: obj.text,
      color: obj.color,
      size: this.props.fontSizeMapper(obj),
    }));
  }

  componentWillReceiveProps(props) {
    const { words, width, height, activeColor } = props;
    console.log("activeColor", activeColor);

    if (activeColor !== this.props.activeColor) {
      this.setActiveColor(activeColor);
      this.redraw();
    }

    if (isEqual(words, this.props.words) && width === this.props.width && height === this.props.height) {
      return;
    }
    // console.log("update", this.wordContainer);
    this.update(this.mapWords(words), width, height);
  }

  shouldComponentUpdate() {
    return false;
  }

  render() {
    return <div ref={(el) => (this.wordContainer = el)} />;
  }
}

function throttleRender(interval, ...throttleArgs) {
  if (typeof interval !== "number" && interval > 0) {
    throw new Error("[throttle] Interval (ms) parameter not received.");
  }

  return (Component) =>
    class extends React.Component {
      constructor(props) {
        super(props);

        this.state = props;
        this.updateStateWithProps = throttle(
          (props) => {
            // console.log("updateStateWithProps");
            this.setState(props || this.props);
            this.forceUpdate();
          },
          interval,
          ...throttleArgs
        );
      }

      componentWillReceiveProps(props) {
        // console.log("componentWillReceiveProps", props);

        const propsToOmit = ["fontSizeMapper"]; //, "extra"];
        // Compare props...
        if (!isEqual(omit(props, propsToOmit), omit(this.state, propsToOmit))) {
          this.updateStateWithProps(props);
        }
      }
      shouldComponentUpdate() {
        return false;
      }
      render = () => {
        console.log("render", this.props.width, this.props.height, this.state);
        return <Component {...this.state} />;
      };
    };
}
export default throttleRender(2000, { leading: true, trailing: true })(AnimatedWordCloud);
