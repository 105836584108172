import countBy from "lodash/countBy";
import flatten from "lodash/flatten";
import groupBy from "lodash/groupBy";
import isUndefined from "lodash/isUndefined";
import omitBy from "lodash/omitBy";
import orderBy from "lodash/orderBy";
import sumBy from "lodash/sumBy";
import values from "lodash/values";
import { isValidInput } from "../mobile/utils";

export const MODE = {
  MASTER: "master",
  VIEWER: "viewer",
};

function getCorrectAnswer(question) {
  const correctAnswers = [];
  for (var i in question.answers) {
    if (question.answers[i].checked) {
      correctAnswers.push("" + i);
    }
  }
  return correctAnswers.join("-");
}

function roundValue(value) {
  return Math.round(value * 100) / 100;
}

function computeRank(users, order = "desc") {
  const grouped = groupBy(users, "score");
  // console.log("grouped", grouped);
  const ranks = orderBy(grouped, (v) => v[0].score, order);
  let rank = 1;
  for (const groups of ranks) {
    for (const group of groups) {
      group.rank = rank;
    }
    rank += groups.length;
  }
  return flatten(ranks);
}

function safeParseInt(str) {
  if (typeof str !== "string") return str;
  try {
    const v = parseInt(str, 10);
    console.log(str, "=>", v);
    if (isNaN(v)) return 0;
    return v;
  } catch (e) {
    console.error(e);
    return 0;
  }
}
/*
 * Default is 100 points per question and 10 seconds to answer (set in milliseconds)
 * */
function computeScoreWithTime(questionPoint = 100, questionTotalTime = 10000, userAnswerTime) {
  const scoreRatio = Math.max(questionTotalTime - userAnswerTime, 0) / questionTotalTime;
  const score = scoreRatio * questionPoint;
  const minusScore = Math.max(1, score);
  return Math.round(minusScore);
}

export function computeOneUserResults(quiz, userId, quizAnswers) {
  const { defaultPoints, questionTotalTime, includeTimeForScore = false } = quiz;
  let userScore = 0;

  for (const question of quiz.questions) {
    const { answers, answerTime } = quizAnswers[question.uuid] || {};
    if (answers) {
      if (question.type === "multiple_choice" || question.type === "image_quiz") {
        const correctAnswer = getCorrectAnswer(question);
        if (answers.join("-") == correctAnswer) {
          // weak equal to test string/number equality, just in case
          userScore += includeTimeForScore
            ? computeScoreWithTime(question.points || defaultPoints, questionTotalTime, answerTime)
            : question.points || defaultPoints || 10;
        }
      } else if (question.type === "vote") {
        userScore += 10;
      } else if (question.type === "input" && (!question.inputType || question.inputType === "text")) {
        if (isValidInput(question, answers[0])) {
          userScore += question.points || defaultPoints || 10;
          break;
        }
      } else if (question.type === "input" && question.inputType === "number") {
        const value = answers[0];
        const answerValue = safeParseInt(question.answer);
        userScore += Math.max(0, 10 - Math.abs(answerValue - value));
      }
    }
  }

  return userScore;
}

export function computeUserResults(quiz, users, quizAnswers) {
  console.log("quiz", quiz);
  console.log("users", users);
  console.log("quizAnswers", quizAnswers);
  const { defaultPoints, questionTotalTime, includeTimeForScore = false } = quiz;

  const scores = {};
  for (const id in users) {
    scores[id] = {
      id,
      ...users[id],
      score: 0,
    };
  }

  function addUserScore(userId, score) {
    if (scores[userId]) {
      scores[userId].score += score;
    }
  }

  for (const question of quiz.questions) {
    const answers = quizAnswers[question.uuid];
    if (answers) {
      if (question.type === "multiple_choice" || question.type === "image_quiz") {
        // TODO : multiple_good
        const correctAnswer = getCorrectAnswer(question);
        for (const answer of answers) {
          if (answer.answer.join("-") == correctAnswer) {
            const { answerTime } = answer;
            const score = includeTimeForScore
              ? computeScoreWithTime(question.points || defaultPoints, questionTotalTime, answerTime)
              : question.points || defaultPoints || 10;
            addUserScore(answer.userId, score);
          }
        }
      } else if (question.type === "input" && question.inputType === "number") {
        // Find closest users...
        const answerValue = safeParseInt(question.answer);
        // console.log("answerValue", question.answer, answerValue)
        const userAnswers = answers.map(({ userId, answer }) => {
          const value = answer && answer.length > 0 ? answer[0] : 0;
          // console.log(userId, value,  Math.abs(answerValue - value))
          return {
            id: userId,
            score: Math.abs(answerValue - value),
          };
        });
        const questionRank = computeRank(userAnswers, "asc");
        for (const qr of questionRank) {
          addUserScore(qr.id, Math.max(0, 10 - qr.rank)); // Add 9 to 0 points
        }
      }
    }
  }

  const leaderboard = computeRank(values(scores), "desc");
  // console.log("scores", scores);
  // console.log("leaderboard", leaderboard);

  return { scores, leaderboard };
}

export function computeResults(question, results, users = {}, wordsToHide = []) {
  const { type, answerType = "single", inputType, textFormat = "none" } = question;
  if (type === "drag_and_drop") {
    const responses = [];
    const answersWithExtra = [];
    results.forEach((res) => {
      for (const a of res.answer) {
        responses.push(a);
        answersWithExtra.push({ ...a, extra: res.others || "" });
      }
    });
    return {
      answersWithExtra,
    };
  }
  if (type === "form" || type === "results" || type === "title" || type === "informations") return {};
  if (type === "input" && inputType === "number") {
    const responses = [];
    let correct = 0,
      wrong = 0;
    results.forEach((res) => {
      if (res.answer[0] == question.answer) {
        correct++;
      } else {
        wrong++;
      }
      responses.push(res.answer[0]);
    });
    const count = countBy(responses);
    // console.log("count", count);
    return {
      totalAnswers: results.length,
      correct,
      wrong,
      answers: orderBy(
        Object.keys(count).map((key) => ({ text: key, count: count[key] })),
        "count",
        "desc"
      ),
    };
  }
  if (type === "input" || type === "word_cloud" || type === "multiple_texts") {
    let answersWithExtra = [];
    const responses = [];
    if (type === "multiple_texts") {
      for (const res of results) {
        for (const a of res.answer) {
          let answer = (a || "").trim();
          if (textFormat === "uppercase") {
            answer = answer.toUpperCase();
          }
          if (/\S/.test(answer)) {
            // check if answer doesn't contain only spaces
            responses.push(answer);
          }
        }
      }
    } else if (
      (type === "word_cloud" && answerType === "whitelist") ||
      (type === "word_cloud" && answerType === "multiple")
    ) {
      results.forEach((res) => {
        for (const a of res.answer) {
          if (wordsToHide.indexOf(a) === -1) {
            responses.push(a);
            answersWithExtra.push({ answer: a, extra: res.others || "" });
          }
        }
      });
    } else {
      results.forEach((res) => {
        if (wordsToHide.indexOf(res.answer[0]) === -1) {
          responses.push(res.answer[0]);
        }
      });
    }

    {
      const count = countBy(responses);
      return {
        totalAnswers: results.length,
        answersWithExtra,
        correct: 0,
        wrong: 0,
        answers: orderBy(
          Object.keys(count).map((key) => ({ text: key, count: count[key] })),
          "count",
          "desc"
        ),
      };
    }
  }
  if (type === "scale") {
    let responses = [];
    let answersWithExtra = [];
    results.forEach((res) => {
      for (const a of res.answer) {
        responses.push(a);
        answersWithExtra.push({ answer: a, extra: res.others || "" });
      }
    });

    const isSingle = answerType === "single";
    const count = isSingle ? countBy(responses, "value") : countBy(responses, "index");
    const average = responses.length > 0 ? roundValue(sumBy(responses, "value") / responses.length) : 0;
    const groupAnswers = groupBy(responses, "index");
    return {
      totalAnswers: results.length,
      average,
      answersWithExtra,
      answers: orderBy(
        Object.keys(count).map((c) => {
          const percent = isSingle
            ? Math.round((count[c] * 10000) / results.length) / 100
            : sumBy(groupAnswers[c], "value") / groupAnswers[c].length;
          return {
            text: c,
            count: count[c],
            percent,
          };
        }),
        "count",
        "desc"
      ),
    };
  }

  const correctAnswers = [];
  for (var i in question.answers) {
    if (question.answers[i].checked) {
      correctAnswers.push("" + i);
    }
  }
  const correctValue = correctAnswers.join("-");
  // console.log("correctValue", correctValue);
  function isValid(result) {
    if (answerType === "multiple_good") {
      return result.answer.length === 1 && correctAnswers.indexOf("" + result.answer[0]) !== -1;
    }
    // console.log("isValid", result);
    return result.answer.join("-") === correctValue;
    // return (
    //   Object.keys(result.answer)
    //     .filter(key => result[key] === true)
    //     .join("-") === correctValue
    // );
  }

  const stats = {
    totalAnswers: 0,
    correct: 0,
    wrong: 0,
    answers: question.answers.map((a) => omitBy({ text: a.text, image: a.image, count: 0, percent: "0" }, isUndefined)),
  };

  const correctUserResults = [];
  let otherAnswers = [];
  if (results) {
    for (var result of results) {
      stats.totalAnswers++;

      if (isValid(result)) {
        stats.correct++;
        correctUserResults.push(result);
      } else stats.wrong++;

      if (result && result.answer) {
        for (var r of result.answer) {
          const n = parseInt(r, 10);

          if (n < stats.answers.length) {
            stats.answers[n].count++;
            stats.answers[n].index = n;
          }
        }
      }
      if (result && result.others && result.others.length > 0) {
        otherAnswers.push(result.others);
      }
    }
    stats.others = otherAnswers;
  }

  // Compute leaderboard, map to users, limit to 10
  stats.leaderboard = orderBy(correctUserResults, "time", "asc")
    .map((res) => ({
      ...res,
      user: users[res.userId] || {},
    }))
    .slice(0, 10);
  // console.log("leaderboard", stats.leaderboard);

  if (stats.totalAnswers > 0) {
    for (var i = 0; i < stats.answers.length; i++) {
      stats.answers[i].percent = Math.round((stats.answers[i].count * 10000) / stats.totalAnswers) / 100 + "%";
    }
  }
  return stats;
}
