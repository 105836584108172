import React, { Component } from "react";
import cx from "classnames";

export default class MultipleTexts extends Component {
  constructor(props) {
    super(props);
    const { question } = props;
    const { inputsCount = 1 } = question;
    this.state = {
      inputs: Array(inputsCount).fill({}),
      focusedInputIndex: 0,
    };
  }

  nextInputRef = [];
  handleAddAnswer = () => {
    const inputs = [...this.state.inputs, {}];
    this.setState({ inputs, focusedInputIndex: inputs.length - 1 });
  };

  render() {
    const { inputs, focusedInputIndex } = this.state;
    const { state, question, answers, onUpdateAnswer } = this.props;
    const { textFormat = "none" } = question;
    const isAnswering = state === "ANSWERING";
    return (
      <div className="quiz__multiple__texts__answers">
        <form>
          {inputs.map((i, index) => {
            const isNew = index === inputs.length;
            const value = answers[index] || "";
            const hasAnswer = value.length > 0;
            const isFocused = focusedInputIndex === index;
            return (
              <div key={index}>
                {!isNew && (
                  <input
                    className={cx("input", { filled: hasAnswer })}
                    name={`input-${index}`}
                    style={{ textTransform: textFormat }}
                    key={index}
                    type="text"
                    value={value}
                    autoFocus={isFocused}
                    ref={(el) => (this.nextInputRef[index] = el)}
                    disabled={!isAnswering}
                    onChange={(e) => onUpdateAnswer(index, e.target.value, index)}
                  />
                )}
              </div>
            );
          })}
          {isAnswering && (
            <div className="add__button" onClick={this.handleAddAnswer}>
              <i className="fa fa-plus" style={{ color: "black", fontSize: "3vw" }} />
            </div>
          )}
        </form>
      </div>
    );
  }
}
