import React, { PureComponent } from "react";
import cx from "classnames";
import SlaveLogo from "./SlaveLogo";
import { Pin } from "../../screens/SlaveScreen";

export default class SlaveTitle extends PureComponent {
  render() {
    const { backgroundColor, title = "", logo, template = "default", question, pin, questions } = this.props;
    const form = questions.find((q) => q.type === "form") || {};
    return (
      <div
        className={cx("slave-container", "slave-container--" + template, "slave-container--" + question.type)}
        style={{ backgroundColor }}
      >
        <Pin pin={pin} />
        <SlaveLogo src={logo} style={{ position: "absolute", bottom: 10, right: 10, maxWidth: 200 }} />
        <div className="question-container">
          {template === "default" && (
            <div className="question-number-container">
              <p className="question-number" />
            </div>
          )}
          {title.split("\n").map((t, index) => (
            <h1 key={index} className="question-text">
              {t}
            </h1>
          ))}
        </div>
      </div>
    );
  }
}
