import "bootstrap/dist/css/bootstrap.min.css";
import "fontawesome-4.7/css/font-awesome.min.css";
import startsWith from "lodash/startsWith";
import React, { Component } from "react";
import { Redirect, Route, BrowserRouter as Router } from "react-router-dom";

import "./App.css";

import Client from "./Client";
import ResultViewer from "./ResultViewer";
import Slave from "./Slave";
import Speaker from "./Speaker";
import Master from "./master/Master";
import { MODE } from "./master/utils";
import Preview from "./preview/Preview";

const basename = startsWith(window.location.pathname, "/quiz.appcraft.events") ? "/quiz.appcraft.events" : undefined;

class App extends Component {
  render() {
    let path = window.location.pathname.replace(basename || "", "").replace("/", "");
    if (path.indexOf("preview") !== -1) path = "slide";

    return (
      <Router basename={basename}>
        <div className={`App App-${path}`}>
          <Route path="/client" exact component={Client} />
          <Route path="/master" exact component={Master} />
          <Route path="/viewer" exact render={(props) => <Master {...props} mode={MODE.VIEWER} />} />
          <Route path="/preview/:appId/quiz/:quizId/question/:questionIndex" exact component={Preview} />
          <Route path="/slave" exact component={Slave} />
          <Route path="/slide" exact component={Slave} />
          <Route path="/results/:appId/session/:sessionId" exact component={ResultViewer} />
          <Route path="/speaker" exact component={Speaker} />
          <Route path="/" exact component={() => <Redirect to="/client" />} />
        </div>
      </Router>
    );
  }
}

export default App;
