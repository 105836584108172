import React, { Component } from "react";

export default class QuizQuestion extends Component {
  render() {
    const { question, primaryColor } = this.props;
    const { type, number } = question;
    return (
      <div
        className="quiz__question__container"
        style={{
          minHeight: 120,
          margin: 10,
          marginTop: "5%",
          borderRadius: 5,
          textTransform: "none",
          backgroundColor: "white",
          color: "black",
          textAlign: "center",
          padding: 10,
          display: "flex",
          flexDirection: "column",
          fontSize: 14,
          boxShadow:
            "0 6px 10px 0 rgba(0, 0, 0, .14), 0 1px 18px 0 rgba(0, 0, 0, .12), 0 3px 5px -1px rgba(0, 0, 0, .2)"
        }}
      >
        {type !== "form" && type !== "results" && type !== "word_cloud" && !!number && (
          <div
            style={{
              fontWeight: "bold",
              color: primaryColor,
              width: "max-content",
              padding: "2px 5px",
              border: `1px solid ${primaryColor}`,
              borderRadius: 10
            }}
            className="quiz__question__index"
          >
            Question {number}
          </div>
        )}
        <div className="quiz__question__text">{question.question}</div>
        {question.subtitle && <div className="quiz__question__subtitle">{question.subtitle}</div>}
      </div>
    );
  }
}
