import React, { Component } from "react";
import RCSlider from "../components/RCSlider";
import toNumber from "lodash/toNumber";

function ensureNumber(value, defaultValue) {
  if (value === null || value === undefined) return defaultValue;
  return toNumber(value);
}
export default class ScaleQuestion extends Component {
  render() {
    const { question, answers, onSlideChange } = this.props;
    const { answerType = "single", scaleProps = {}, answersByProfile = false, profiles = {} } = question;
    const min = ensureNumber(question.min, 0);
    const max = ensureNumber(question.max, 10);
    const step = ensureNumber(question.step, 1);
    if (answerType === "multiple") {
      const extraValues = answers.others || [];
      let questionAnswers = question.answers || [];
      if (answersByProfile) {
        const isOrano = extraValues.includes("orano");
        if (isOrano) {
          questionAnswers = profiles["orano"].answers;
        }
      }
      return (
        <div className="quiz__scale__multiple">
          {questionAnswers.map((item, index) => {
            const { text, icon, subtitle } = item;
            return (
              <div key={index} className="quiz__scale__multiple--container">
                {icon && icon.uri && <img src={icon.uri} alt="icon" className="quiz__scale__multiple--icon" />}
                <div>
                  <h3 className="quiz__scale__multiple--text">{text}</h3>
                  {subtitle && <p className="quiz__scale__multiple--subtitle">{subtitle}</p>}
                </div>
                <div className="quiz__scale__multiple--slider" style={{ flex: 1 }}>
                  <RCSlider
                    min={min}
                    max={max}
                    step={step}
                    value={(answers && answers[index]) || min}
                    {...scaleProps}
                    onChange={(value) => onSlideChange(index, value)}
                  />
                </div>
              </div>
            );
          })}
        </div>
      );
    }
    return (
      <div className="quiz__scale__single">
        <RCSlider
          min={min}
          max={max}
          step={step}
          value={(answers && answers[0]) || min}
          {...scaleProps}
          onChange={(value) => onSlideChange(0, value)}
        />
      </div>
    );
  }
}
