import React, { Component } from "react";
import { getParameterByName, hasGoodAnswers } from "./utils";
import firebase from "./Store";
import { eventId } from "./Store";
import { computeResults } from "./master/utils";
import { StatValue } from "./master/Master";
import { SlaveTitle, SlaveInformation, SlaveSlide } from "./slave/components";

const hasDefaultPin = getParameterByName("pin");

const Logo = () => (
  <div style={{ position: "absolute", left: "50%", right: 0, top: 0, padding: 8, textAlign: "center" }}>
    {/* <img src="https://app.appcraft.events/data/NBJFHDU8/assets/logo.png" style={{maxWidth: '100%', maxHeight: 32}}/> */}
  </div>
);

const Container = ({ children, pin, style }) => (
  <div className="sidebar-content-list" style={style}>
    {pin && !hasDefaultPin && (
      <div style={{ position: "fixed", top: 8, right: 48, fontSize: "1.5vw" }}>
        PIN: <code>{pin}</code>
      </div>
    )}
    <Logo />
    {children}
  </div>
);

// function generateBrickWall() {
//   const bricks = [];
//   for (var y = 0; y < 20; y++) {
//     for (var x = 0; x < 11; x++) {
//       var n = (x + y) % 6;
//       bricks.push({
//         id: x + "," + y,
//         x: x + (y % 2 === 1 ? -0.5 : 0),
//         y,
//         frame: 1 + n,
//         src: "https://app.appcraft.events/images/Eaj6dUnT7TUrxk/assets/brick" + (1 + n) + ".png"
//       });
//     }
//   }
//   return bricks;
// }

function computeFreeBricks(bricks) {
  return bricks.filter((b) => !b.broken);
}

function breakBrick(bricks) {
  const freeBricks = computeFreeBricks(bricks);
  if (freeBricks.length > 0) {
    var n = Math.floor(Math.random() * freeBricks.length);
    if (n >= 0 && n < freeBricks.length) {
      freeBricks[n].broken = true;
    }
    return bricks.slice();
  }
  return bricks;
}

export default class Speaker extends Component {
  constructor(props) {
    super(props);

    this.state = {
      state: "DISCONNECTED",
      answers: [],
      stats: null,
      words: [],
      sessionId: "",
      pin: getParameterByName("pin") || "",
      eventId: getParameterByName("eventId") || eventId,
      savedState: null,
      savedQuestion: null,
      savedStats: null,
    };
  }

  disconnect = (e) => {
    // e.preventDefault();
    // e.stopPropagation();

    console.warn("TODO: disconnect");
    if (this.unsubscribe) {
      this.unsubscribe();
      this.unsubscribe = null;
    }
    if (this.unsubscribePin) {
      this.unsubscribePin();
      this.unsubscribePin = null;
    }
    this.setState({ state: "DISCONNECTED", sessionId: "", answers: [] });
    // if (this.socket) {
    //   this.socket.disconnect();
    // }
  };

  connect = (e) => {
    // const { eventId, sessionId, pin, answers } = this.state;
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }

    // Fetch info from pin
    const { pin } = this.state;
    if (this.unsubscribePin) this.unsubscribePin();
    this.unsubscribePin = this.database
      .collection("quiz-pin")
      .doc(pin)
      .onSnapshot((doc) => {
        if (doc.exists) {
          const { sessionId, eventId } = doc.data();
          this.setState({ sessionId, eventId });
          // console.log("doc", data);

          this.connectTo(eventId, sessionId);
        } else {
          console.warn("show error...");
          this.disconnect();
        }
      });
  };

  connectTo = (eventId, sessionId) => {
    this.answers = [];
    if (this.unsubscribe) this.unsubscribe();
    this.unsubscribe = this.database
      .collection("quiz")
      .doc(eventId)
      .collection("sessions")
      .doc(`${sessionId}`)
      .onSnapshot((currentSession) => {
        if (currentSession.exists) {
          const { currentQuestion, quiz } = currentSession.data();
          if (currentQuestion) {
            const { state, stats } = currentQuestion;
            if (state === "ANSWERING") {
              // TODO : check if already answered ?
              this.answers = [];
              this.setState({ state: "ANSWERING", question: currentQuestion, quiz, stats, answers: [] });

              // Only subscribe to answers once
              // TODO : only for currentQuestion.type === "word_cloud"
              if (this.unsubscribeAnswers) {
                this.unsubscribeAnswers();
                this.unsubscribeAnswers = null;
              }
              this.unsubscribeAnswer = this.dbSession()
                .collection("answers")
                .doc(currentQuestion.uuid)
                .collection("users")
                .onSnapshot((snapshot) => {
                  snapshot.docChanges().forEach((change) => {
                    // console.log("found change", change);
                    if (change.type === "added") {
                      const { question } = this.state;
                      if (!question) return;
                      // if (currentQuestion.type === "word_cloud") {
                      this.answers = [...this.answers, change.doc.data()];
                      // const words = currentQuestion.type === "word_cloud" ? computeResults(question, this.answers) : {};
                      // words: words.answers,
                      this.setState({ answers: this.answers });
                      // }
                    }
                  });
                });
            } else if (state === "SHOW_RESULTS") {
              this.setState({ state: "SHOW_RESULTS", question: currentQuestion, quiz, stats });
            } else {
              this.setState({ state: "WAITING_NEXT", question: currentQuestion, quiz, stats });
            }
          } else {
            this.setState({ state: "WAITING", stats: null, quiz });
          }
        } else {
          this.setState({ state: "UNAVAILABLE", question: null, stats: null });
        }
      });
  };

  // updateData = questions => {
  //   this.dbSession().update({ questions });
  // };
  dbSession = () => {
    const { sessionId, eventId } = this.state;
    return this.database.collection("quiz").doc(eventId).collection("sessions").doc(sessionId);
  };

  breakOneBrick = (startTime, repeatTime) => {
    if (startTime !== this.state.startTime) return; // Question changed...

    // const time = new Date().getTime();
    const freeCnt = computeFreeBricks(this.bricks).length;
    if (freeCnt === 0) return; // finished !

    this.bricks = breakBrick(this.bricks);
    this.setState({ bricks: this.bricks });
    // const nearlyHalf = freeCnt < this.bricks.length * 0.66;
    // const tooLong = time - this.state.startTime > 20000;

    const delay = repeatTime > 500 ? repeatTime * 0.66 : 500;
    setTimeout(() => this.breakOneBrick(startTime, delay), delay);
  };

  componentDidMount() {
    this._mounted = true;
    this.database = firebase.firestore();
  }

  componentWillUnmount() {
    this._mounted = false;
    if (this.unsubscribe) {
      this.unsubscribe();
      this.unsubscribe = null;
    }
    if (this.unsubscribePin) {
      this.unsubscribePin();
      this.unsubscribePin = null;
    }
    if (this.unsubscribeAnswers) {
      this.unsubscribeAnswers();
      this.unsubscribeAnswers = null;
    }
  }

  renderMultipleVoteAnswers() {
    const { question, state, stats } = this.state;
    return (
      <div>
        {question.answers.map((ans, idx) => {
          let clazz = "slide__answer";
          if (state === "SHOW_RESULTS") {
            if (ans.checked) clazz += " correct";
            else clazz += " incorrect";
          }

          return (
            <div key={idx} className={clazz}>
              <div className="slide__answer__label">{idx + 1}</div>
              <div className="slide__answer__text">
                {ans.text}
                <div
                  className={"answer answer-" + (idx + 1)}
                  style={{ minWidth: state === "SHOW_RESULTS" ? stats.answers[idx].percent : 0 }}
                />
              </div>
            </div>
          );
        })}
      </div>
    );
  }

  renderMultipleAnswers() {
    const { question, state, stats } = this.state;
    return (
      <div>
        {question.answers.map((ans, idx) => {
          let borderColor, backgroundColor, color;
          if (state === "SHOW_RESULTS") {
            borderColor = ans.checked ? "#2CA02C" : "red";
            backgroundColor = ans.checked ? "#2CA02C" : "transparent";
            color = ans.checked ? "white" : "red";
          } else {
            color = "black";
            borderColor = "black";
            backgroundColor = "transparent";
          }

          return (
            <div key={idx} style={{ flexDirection: "row", display: "flex", marginBottom: 10, alignItems: "center" }}>
              <div
                style={{
                  fontFamily: "Montserrat",
                  fontWeight: "bold",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: 48,
                  height: 48,
                  borderRadius: 28,
                  border: `1px solid ${borderColor}`,
                  marginRight: 10,
                }}
              >
                <p style={{ margin: "auto", color: borderColor }}>{idx + 1}</p>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  fontWeight: "bold",
                  padding: 15,
                  backgroundColor,
                  justifyContent: "space-between",
                  flex: 1,
                  height: 60,
                  borderRadius: 4,
                  border: `1px solid ${borderColor}`,
                  marginRight: 10,
                  fontFamily: "Montserrat",
                }}
              >
                <div style={{ color: color }}>{ans.text}</div>
                <div style={{ color: color, fontWeight: "bold" }}>
                  {state === "SHOW_RESULTS" ? stats.answers[idx].percent : 0}
                </div>
              </div>
              {state === "SHOW_RESULTS" && (
                <div
                  style={{
                    position: "relative",
                    right: 24,
                    bottom: -20,
                    height: 22,
                    width: 22,
                    backgroundColor: borderColor,
                    borderRadius: 11,
                  }}
                >
                  {ans.checked ? (
                    <i className="fa fa-check" style={{ color: "white" }} />
                  ) : (
                    <i style={{ color: "white" }} className="fa fa-times" />
                  )}
                </div>
              )}
            </div>
          );
        })}
      </div>
    );
  }
  renderImageAnswers() {
    const { question, answers, state } = this.state;
    return (
      <div style={{ display: "flex", flexDirection: "row" }}>
        {question.answers.map((ans, idx) => {
          const isActive = answers[idx];
          let active = !isActive ? "inset 0 0 0 1px #DDD" : "inset 0 0 0 3px #00477E";
          let response = "";
          if (state === "SHOW_RESULTS") {
            response = ans.checked ? "inset 0 0 0 3px #2CA02C" : "inset 0 0 0 3px red";
          }
          const boxShadow = state === "SHOW_RESULTS" ? response : active;
          return (
            <div key={idx} style={{ flex: 1, boxShadow, margin: 5, padding: 10, textAlign: "center" }}>
              {state === "SHOW_RESULTS" && (
                <div
                  style={{
                    position: "relative",
                    right: 15,
                    top: -20,
                    height: 22,
                    width: 22,
                    backgroundColor: ans.checked ? "#2CA02C" : "red",
                    borderRadius: 11,
                  }}
                >
                  {ans.checked ? (
                    <i className="fa fa-check" style={{ color: "white" }} />
                  ) : (
                    <i style={{ color: "white" }} className="fa fa-times" />
                  )}
                </div>
              )}
              <img src={ans.image.uri} width="100%" alt="answer" />
              <p style={{ marginBottom: 0, marginTop: 10, fontFamily: "Montserrat", fontWeight: "bold" }}>{ans.text}</p>
            </div>
          );
        })}
      </div>
    );
  }

  render() {
    const { quiz, pin, state, question, answers = [] } = this.state;
    const questionNumber = question ? question.index : 0;
    const stats = question ? computeResults(question, answers) : {};

    if (state === "DISCONNECTED" || state === "UNAVAILABLE") {
      return this.renderDisconnected();
    }

    if (state === "WAITING" || !question) {
      return (
        <Container pin={pin}>
          <div style={{ display: "table", height: "100%", position: "absolute", width: "100%" }}>
            <div style={{ display: "table-cell", verticalAlign: "middle", textAlign: "center" }}>
              <div className="slide__answers__text">En attente d'une question...</div>
            </div>
          </div>
          {this.renderDisconnectButton()}
        </Container>
      );
    }

    if (question.type === "brick_wall") {
      const background = question.hiddenImage && question.hiddenImage.uri;
      const ball = question.ball && question.ball.uri;
      const { bricks } = this.state;
      const w = 10,
        h = 3.4;
      return (
        <div className="brick_wall" style={{ backgroundImage: 'url("' + background + '")', backgroundSize: "cover" }}>
          {bricks.map((b) => (
            <div
              key={b.id}
              className="brick_container"
              style={{ left: b.x * w + "vw", top: b.y * h + "vw", width: w + "vw", height: h + "vw" }}
            >
              <img className={"brick " + (b.broken ? "broken" : "")} src={b.src} alt="brick" />
            </div>
          ))}
          {bricks.map((b) => (
            <div
              key={b.id}
              className="brick_container"
              style={{ left: b.x * w + "vw", top: b.y * h + "vw", width: w + "vw", height: h + "vw", zIndex: 1 }}
            >
              <img alt="ball" className={"ball ball-" + b.frame + " " + (b.broken ? "visible" : "")} src={ball} />
            </div>
          ))}
        </div>
      );
    }

    if (state === "ANSWERING" || state === "WAITING_NEXT" || state === "SHOW_RESULTS") {
      // const questionClass =
      //   question.type === "image_quiz" || question.type === "word_cloud" ? "slide__image" : "slide__multiple";
      // const responseClass =
      //   question.type === "image_quiz" || question.type === "word_cloud"
      //     ? "slide__image__answers"
      //     : "slide__multiple__answers";
      const { correct } = stats || {};
      const answerCount = answers.length;
      return (
        <div>
          {(question.type === "title" || question.type === "form") && (
            <SlaveTitle
              template={quiz.template}
              primaryColor={quiz.primaryColor}
              logo={quiz.logo}
              title={question.question}
              question={question}
            />
          )}
          {question.type === "results" && (
            <SlaveTitle
              template={quiz.template}
              primaryColor={quiz.primaryColor}
              logo={quiz.logo}
              title={question.slideText || question.question}
              question={question}
            />
          )}
          {question.type === "informations" && (
            <SlaveInformation
              template={quiz.template}
              primaryColor={quiz.primaryColor}
              logo={quiz.logo}
              title={question.question}
              text={question.slideText}
              question={question}
            />
          )}
          {(question.type === "multiple_choice" ||
            question.type === "vote" ||
            question.type === "input" ||
            question.type === "word_cloud") && (
            <SlaveSlide
              template={quiz.template}
              primaryColor={quiz.primaryColor}
              logo={quiz.logo}
              className="slave-container--mobile"
              title={quiz.title}
              question={question}
              state={state}
              stats={stats}
              number={questionNumber}
            >
              {stats && stats.answers && stats.answers.length > 0 && (
                <div style={{ fontSize: "1.15em" }}>
                  <StatValue
                    label={answerCount > 1 ? "réponses" : "réponse"}
                    value={answerCount}
                    style={{ color: "white" }}
                  />
                  {question && hasGoodAnswers(question.type) && answerCount > 0 && (
                    <StatValue
                      label="bonnes réponses"
                      value={`${correct} / ${Math.round((correct * 100) / answerCount) + "%"}`}
                      style={{ color: "#8dc63f" }}
                    />
                  )}
                </div>
              )}
            </SlaveSlide>
          )}
          {/* {question.type === "word_cloud" && <WordCloudStats answers={words} {...stats} type={question.type} />} */}
        </div>
        //
        //      <Container pin={pin}>
        //      <div className="slide">
        //     <div
        //       className={cx("slide__question", questionClass)}
        //       style={{ fontFamily: "Montserrat", fontWeight: "bold" }}
        //     >
        //       {question.question}
        //     </div>
        //     <div className={cx("slide__answers", responseClass)}>
        //       {question.type === "image_quiz" && this.renderImageAnswers()}
        //       {question.type !== "image_quiz" && this.renderMultipleAnswers()}
        //       {state === "ANSWERING" && <div className="slide__answers__text">En attente des résultats...</div>}
        //       {state === "WAITING_NEXT" && <div className="slide__answers__text">En attente des résultats...</div>}
        //       {state === "SHOW_RESULTS" &&
        //         question.type !== "vote" &&
        //         question.type !== "word_cloud" && <Stats {...stats} type={question.type} />}
        //       {state === "SHOW_RESULTS" &&
        //         question.type === "word_cloud" && <WordCloudStats words={words} {...stats} type={question.type} />}
        //     </div>
        //   </div>
        //   {this.renderDisconnectButton()}
        // </Container>
      );
    }
    return (
      <Container pin={pin}>
        Merci pour votre réponse, une nouvelle question arrivera prochainement...
        {this.renderDisconnectButton()}
      </Container>
    );
  }

  renderDisconnectButton() {
    return (
      <button
        className="btn btn-danger btn-sm"
        style={{ display: "block", position: "fixed", right: 8, top: 8 }}
        onClick={this.disconnect}
      >
        X
      </button>
    );
  }

  renderDisconnected() {
    const { pin, state } = this.state;
    const states = {
      UNAVAILABLE: {
        msg: "Mauvais code pin, veuillez réessayer ou demander à l'organisateur",
        color: "#FF5A40",
      },
      DISCONNECTED: {
        msg: "Entrez le code pin indiqué par les organisateurs",
        color: undefined,
      },
    };
    return (
      <Container style={{ padding: 16 }}>
        <h5 style={{ textAlign: "center", fontSize: 16, color: states[state].color }}>{states[state].msg}</h5>
        <input
          style={{
            display: "block",
            margin: "0 auto",
            padding: 12,
            fontSize: 16,
            border: "1px solid #DDD",
            marginBottom: 8,
          }}
          type="text"
          pattern="[0-9]*"
          onInput={(e) => this.setState({ pin: e.target.value })}
          placeholder="pin"
          value={pin}
        />
        <button
          className="btn btn-primary"
          style={{ display: "block", margin: "0 auto" }}
          disabled={!pin}
          onClick={this.connect}
        >
          VALIDER
        </button>
      </Container>
    );
  }
}
